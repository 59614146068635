import storage from "redux-persist/lib/storage";
import { SIGN_OUT } from "./action.type";
import { alertReducer } from "./Reducers/alert.reducer";
import {
  setCartDataReducer,
  setUserDataReducer,
  setUserForTestReducer,
  setCartTotalAmount,
  setRefNo,
  setMemberListData,
  setCartSelectedMemberId,
  setRelationData,
  setConsultationSlot,
  setConsultSelectedDoctor,
} from "./Reducers/set.reducer";
import { setHmoStepReducer } from "./Reducers/hmo.reducer";
import { setProfileTab } from "./Reducers/profile";
import { setFilterState } from "./Reducers/service.reducer";
import { setTestData } from "./Reducers/set.reducer";
import {
  setUploadModel,
  setAuthModel,
  setContactUsModel,
  setHomePageModel,
} from "./Reducers/model";
import { setOrderId } from "./Reducers/set.reducer";
import { setTestsList } from "./Reducers/set.reducer";
import { setPackagesList } from "./Reducers/set.reducer";
import { combineReducers } from "redux";

const appReducer = combineReducers({
  alertReducer,
  setUserDataReducer,
  setCartDataReducer,
  setOrderId,
  setUserForTestReducer,
  setHmoStepReducer,
  setCartTotalAmount,
  setRefNo,
  setProfileTab,
  setMemberListData,
  setFilterState,
  setTestData,
  setUploadModel,
  setCartSelectedMemberId,
  setTestsList,
  setPackagesList,
  setAuthModel,
  setContactUsModel,
  setRelationData,
  setHomePageModel,
  setConsultationSlot,
  setConsultSelectedDoctor,
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
    storage.removeItem("persist:root");
    sessionStorage.clear();
    localStorage.removeItem("access_token");
    window.location.href = "/";
  }

  return appReducer(state, action);
};

export default rootReducer;
