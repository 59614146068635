import httpPathName from "Global/Config/Config";

export const offlinePaymentApi = async (token) => {
  const promise = await httpPathName.get(`cart/offline-payment`, {
    headers: { "x-auth-token": token },
  });
  return promise;
};

export const fetchTheCartApi = async (token) => {
  const promise = await httpPathName.get(`cart/fetchCart`, {
    headers: { "x-auth-token": token },
  });
  return promise;
};

export const memberListApi = async (token) => {
  const promise = await httpPathName.get(`users/addMembers`, {
    headers: { "x-auth-token": token },
  });
  return promise;
};

export const fetchJobDetailApi = async (token, job) => {
  const promise = await httpPathName.get(`career/getJobDetails/${job}`, {
    headers: { "x-auth-token": token },
  });
  return promise;
};

export const fetchSlotApi = async (token, date) => {
  const promise = await httpPathName.get(
    `admin/get-slot-ByDate?selectedDate=${date}`,
    { headers: { "x-auth-token": token } }
  );
  return promise;
};

export const fetchSlotforDoctorAppointment = async (token, id, date) => {
  const promise = await httpPathName.get(
    `doctor/getSlots/${id}?selectedDate=${date}`,
    { headers: { "x-auth-token": token } }
  );
  return promise;
};

export const fetchServiceContent = async () => {
  const promise = await httpPathName.get(`admin/content/get-servicesContent`);
  return promise;
};
