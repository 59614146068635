import { RESET_ALERT, SET_ALERT } from "../../Redux/action.type";

const initial = {
  type: "",
  message: "",
};

export const alertReducer = (state = initial, action) => {
  switch (action.type) {
    case SET_ALERT:
      if (!action.payload) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    case RESET_ALERT:
      return initial;

    default:
      return state;
  }
};
