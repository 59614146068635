import {
  HMO_STEP1_DATA,
  HMO_STEP2_DATA,
  HMO_STEP3_DATA,
  HMO_STEP4_DATA,
} from "../../Redux/action.type";

export const setHmoStep1Action = (data) => {
  return {
    type: HMO_STEP1_DATA,
    payload: data,
  };
};

export const setHmoStep2Action = (data) => {
  return {
    type: HMO_STEP2_DATA,
    payload: data,
  };
};

export const setHmoStep3Action = (data) => {
  return {
    type: HMO_STEP3_DATA,
    payload: data,
  };
};

export const setHmoStep4Action = (data) => {
  return {
    type: HMO_STEP4_DATA,
    payload: data,
  };
};
