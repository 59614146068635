// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-user-card {
  width: 464px;
  height: 100px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;
}

.booking-user-card > svg {
  position: absolute;
  top: 5%;
  right: 2%;
  cursor: pointer;
}

.booking-user-card .logo {
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  background: #3aa1ed;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 32px;
  font-weight: normal;
}

.booking-user-card .content p {
  margin: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
}

.booking-user-card .content p.title {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 5px;
  justify-content: flex-start;
}

.booking-user-card .content p.title span {
    margin-left: 10px;
}

.booking-user-card .content {
  margin-left: 10px;
  width: 100%;
}

.booking-user-card .content p.sub-title span {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Cards/BookingUser/BookingUser.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,iDAAiD;EACjD,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".booking-user-card {\n  width: 464px;\n  height: 100px;\n  border-radius: 16px;\n  background: #fff;\n  box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);\n  display: flex;\n  align-items: center;\n  position: relative;\n  padding: 0 10px;\n}\n\n.booking-user-card > svg {\n  position: absolute;\n  top: 5%;\n  right: 2%;\n  cursor: pointer;\n}\n\n.booking-user-card .logo {\n  width: 68px;\n  height: 68px;\n  flex-shrink: 0;\n  background: #3aa1ed;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  font-size: 32px;\n  font-weight: normal;\n}\n\n.booking-user-card .content p {\n  margin: unset;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding-right: 15px;\n}\n\n.booking-user-card .content p.title {\n  font-size: 18px;\n  font-weight: normal;\n  margin-bottom: 5px;\n  justify-content: flex-start;\n}\n\n.booking-user-card .content p.title span {\n    margin-left: 10px;\n}\n\n.booking-user-card .content {\n  margin-left: 10px;\n  width: 100%;\n}\n\n.booking-user-card .content p.sub-title span {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
