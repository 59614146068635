import { PROFILE_ACTIVE_TAB } from "../../Redux/action.type";

const tab = "profile";

export const setProfileTab = (state = tab, action) => {
  switch (action.type) {
    case PROFILE_ACTIVE_TAB:
      if (!action.payload) {
        action.payload = tab;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
