import React from 'react'

const MyProfilePhone = () => {
  return (
    <div className='myProfilePhone'>
        <h1 className='upcomingAppointment'>Upcoming Appointment Details</h1>
        <div className='upComingContent'>
            <p>Date </p>
            <span>12/07/2023</span> 
        </div>
        <div className='upComingContent'>
            <p>Time </p>
            <span>02:00 pm</span> 
        </div>
        <div className='upComingContent'>
            <p>Package </p>
            <span>Chlamydia Trachomatis IgM</span> 
        </div>
        <div className='upComingContent'>
            <p>Profile</p>
            <span>7</span> 
        </div>
        <div className='upComingContent'>
            <p>Parameter</p>
            <span>78</span> 
        </div>
        <div className='upComingContent'>
            <p>Amount</p>
            <span>₱800</span> 
        </div>

        <h1 style={{marginTop:"15px"}} className='upcomingAppointment'>Upcoming Family Appointment Details</h1>
        <div className='upComingContent'>
            <p>Date </p>
            <span>12/07/2023</span> 
        </div>
        <div className='upComingContent'>
            <p>Time </p>
            <span>02:00 pm</span> 
        </div>
        <div className='upComingContent'>
            <p>Package </p>
            <span>Chlamydia Trachomatis IgM</span> 
        </div>
        <div className='upComingContent'>
            <p>Profile</p>
            <span>7</span> 
        </div>
        <div className='upComingContent'>
            <p>Parameter</p>
            <span>78</span> 
        </div>
        <div className='upComingContent'>
            <p>Amount</p>
            <span>₱800</span> 
        </div>
       </div>
  )
}

export default MyProfilePhone