import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import user from "Assests/Icons/user.svg";
import email from "Assests/Icons/emailCM.svg";
import phone from "Assests/Icons/phoneIcon.svg";
import location from "Assests/Icons/location-pin.svg";
import closeIcon from "Assests/Icons/closeIcon.svg";
import roundedRed from "Assests/Icons/rounded-red.svg";
import check from "Assests/Icons/check.svg";
import "./Career.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { jobApplyApi } from "API/post.api";

const Career = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");

  const [status, setStatus] = useState(false);
  const [formData, setFormData] = useState({});

  const [expression, setExpression] = useState(generateRandomKeyword());
  const [captcha, setCaptcha] = useState("");
  // const formRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");

      // Clear previous content
      context.clearRect(0, 0, canvas.width, canvas.height);
      // Set text properties
      context.font = "30px Arial";
      context.fillStyle = "blue";
      context.textAlign = "center";
      context.textBaseline = "middle";
      // Draw CAPTCHA text
      context.fillText(expression, canvas.width / 2, canvas.height / 2);
    }
  }, [expression, canvasRef.current, show]);

  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  const handleChange = (e) => {
    if (e.target.name === "images") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmt = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (formData.firstName === undefined) {
      dispatch(setAlert("error", "First Name is required!"));
    } else if (formData.lastName === undefined) {
      dispatch(setAlert("error", "Last Name is required!"));
    } else if (formData.emailId === undefined) {
      dispatch(setAlert("error", "Email Address is required!"));
    } else if (
      formData.mobileNumber &&
      !isValidPhoneNumber(`+${formData.mobileNumber}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (formData.state === undefined) {
      dispatch(setAlert("error", "State is required!"));
    } else if (formData.city === undefined) {
      dispatch(setAlert("error", "City is required!"));
    } else if (captcha !== expression) {
      dispatch(setAlert("error", "Please enter a valid Captcha Code"));
    } else {
      const apiStatus = jobApplyApi(token, formData);
      apiStatus
        .then((response) => {
          console.log("response apply", response);
          if (response.status == 200) {
            setStatus(true);
          } else {
            dispatch(setAlert("error", response?.response?.data?.message));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert("error", err?.response?.data?.error));
        });
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      backdrop="static"
      keyboard={false}
      className="career"
    >
      <Modal.Header>
        <img
          src={closeIcon}
          style={{ cursor: "pointer" }}
          alt="Close"
          onClick={handleClose}
        />
      </Modal.Header>

      <Modal.Body>
        <h1 className="title">Add Details</h1>
        {!status ? (
          <form className="carrerBody" onSubmit={(e) => handleSubmt(e)}>
            <div className="careerData">
              <div className="input-wrapper">
                <div>
                  <p>First Name *</p>
                  <div className="innerInputWrapper">
                    <img src={user} alt="First Name" />
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>Last Name *</p>
                  <div className="innerInputWrapper">
                    <img src={user} alt="Last Name" />
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>Email ID *</p>
                  <div className="innerInputWrapper">
                    <img src={email} alt="Email" />
                    <input
                      type="text"
                      placeholder="Email"
                      name="emailId"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>Mobile Number *</p>
                  <div className="innerInputWrapper">
                    <img src={phone} alt="Phone" />
                    <input
                      type="number"
                      placeholder="Phone Number"
                      name="mobileNumber"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>State *</p>
                  <div className="innerInputWrapper">
                    <img src={location} alt="Location" />
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>City *</p>
                  <div className="innerInputWrapper">
                    <img src={location} alt="Location" />
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div>
                  <p>Experience</p>
                  <input
                    type="text"
                    placeholder="Experience"
                    name="experience"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <p>Current Company</p>
                  <input
                    type="text"
                    placeholder="Current Company"
                    name="currentCompany"
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div>
                  <p>Current Salary</p>
                  <input
                    type="text"
                    placeholder="Current Salary"
                    name="currentSalary"
                    onChange={(e) => handleChange(e)}
                    s
                  />
                </div>
                <div>
                  <p>Expected Salary</p>
                  <input
                    type="text"
                    placeholder="Expected Salary"
                    name="expectedSalary"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div>
                  <p>Linkedln Link</p>
                  <input
                    type="text"
                    placeholder="LinkedIn link"
                    name="linkedInLink"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="uploadImageDoc">
                  <p>Upload Document</p>
                  <input
                    hidden
                    accept="application/pdf"
                    type="file"
                    placeholder="Image"
                    name="images"
                    id="career-image"
                    onChange={(e) => handleChange(e)}
                  />

                  <label className="outside">Document</label>
                  <span>Upload supported file (Max 15MB)</span>
                  <div className="uploadFile">
                    <label htmlFor="career-image">Upload</label>
                  </div>
                </div>

                <div>
                  <p>Enter The Captcha</p>
                  <input
                    type="text"
                    value={captcha}
                    onChange={(e) => setCaptcha(e.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "1%",
                      gap: "4%",
                    }}
                  >
                    <canvas ref={canvasRef} width={120} height={40}></canvas>
                    <svg
                      style={{ cursor: "pointer" }}
                      onClick={() => setExpression(generateRandomKeyword())}
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 8.5C5 5 8.51657 3.00122 11.9991 3.00122C16.9692 3.00122 20.9982 7.03039 20.9982 12.0006C20.9982 16.9708 16.9692 21 11.9991 21C8.51657 21 5.49605 19.0217 3.99995 16.1276M3 8.5V4.00006M3 8.5H7"
                        stroke="#131A29"
                        strokeWidth="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <button type="submit" className="proceedbtn">
                Proceed
              </button>
            </div>
          </form>
        ) : (
          <div className="thank">
            <div className="thankWrapper">
              <div className="thankYouWrapper">
                <img src={roundedRed} alt="roundedred" />
                <img className="checked" src={check} alt="check" />
              </div>
              <h1 className="thankyou">THANK YOU!</h1>
              <p className="thankContent">
                Your application has been submitted. We will get in touch with
                you soon!
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Career;
