// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay-back-btn {
  box-shadow: 6px 8px 16px 0px rgba(2, 2, 2, 0.08);
  border-radius: 16px;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}


@media screen and (max-width: 769px){
  .overlay-back-btn{
    margin-top: 17px;
    width: 40px;
    height: 40px;
  }
  .overlay-back-btn svg{
    width: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/BackButton/BackButton.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;;AAGA;EACE;IACE,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".overlay-back-btn {\n  box-shadow: 6px 8px 16px 0px rgba(2, 2, 2, 0.08);\n  border-radius: 16px;\n  cursor: pointer;\n  width: 60px;\n  height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 40px;\n}\n\n\n@media screen and (max-width: 769px){\n  .overlay-back-btn{\n    margin-top: 17px;\n    width: 40px;\n    height: 40px;\n  }\n  .overlay-back-btn svg{\n    width: 25px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
