import {
  CART_DATA,
  SELECTED_USERS_FOR_TEST,
  USER_DATA,
  CART_TOTAL_AMOUNT,
  CART_REF_NO,
  MEMBER_LIST_DATA,
  SET_ALL_TESTS,
  CART_SELECTED_MEMBER_ID,
  PACKAGES_LIST,
  TESTS_LIST,
  ORDER_ID,
  RELATION_DATA,
  CONSULTATION_SLOT_DATA,
  SELECTED_DOCTOR,
} from "../../Redux/action.type";

const initial = [];
const initalObj = {};
const amount = 0;
const refNo = "";
const memberList = [];
const testData = [];
const selectedId = [];
const orderId = "";
const testsList = [];
const packagesList = [];
const relData = [];
const consultationSlot = {};
const selectedDoctor = {};

export const setUserDataReducer = (state = initalObj, action) => {
  switch (action.type) {
    case USER_DATA:
      if (!action.payload) {
        action.payload = initalObj;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setCartDataReducer = (state = initial, action) => {
  switch (action.type) {
    case CART_DATA:
      if (!action.payload) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setOrderId = (state = orderId, action) => {
  switch (action.type) {
    case ORDER_ID:
      if (!action.payload) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setUserForTestReducer = (state = initial, action) => {
  switch (action.type) {
    case SELECTED_USERS_FOR_TEST:
      if (!action.payload) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setCartTotalAmount = (state = amount, action) => {
  switch (action.type) {
    case CART_TOTAL_AMOUNT:
      if (!action.payload) {
        action.payload = amount;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setRefNo = (state = refNo, action) => {
  switch (action.type) {
    case CART_REF_NO:
      if (!action.payload) {
        action.payload = refNo;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setMemberListData = (state = memberList, action) => {
  switch (action.type) {
    case MEMBER_LIST_DATA:
      if (!action.payload) {
        action.payload = memberList;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setTestData = (state = testData, action) => {
  switch (action.type) {
    case SET_ALL_TESTS:
      if (!action.payload) {
        action.payload = testData;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setCartSelectedMemberId = (state = selectedId, action) => {
  switch (action.type) {
    case CART_SELECTED_MEMBER_ID:
      if (!action.payload) {
        action.payload = selectedId;
      }
      let newdata = [...state];
      let index = newdata.findIndex((d) => d == action.payload);
      if (index == -1) {
        newdata.push(action.payload);
      }
      state = newdata;
      return state;

    default:
      return state;
  }
};

export const setTestsList = (state = testsList, action) => {
  switch (action.type) {
    case TESTS_LIST:
      if (!action.payload) {
        action.payload = testsList;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setPackagesList = (state = packagesList, action) => {
  switch (action.type) {
    case PACKAGES_LIST:
      if (!action.payload) {
        action.payload = packagesList;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setRelationData = (state = relData, action) => {
  switch (action.type) {
    case RELATION_DATA:
      if (!action.payload) {
        action.payload = relData;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setConsultationSlot = (state = consultationSlot, action) => {
  switch (action.type) {
    case CONSULTATION_SLOT_DATA:
      if (!action.payload) {
        action.payload = consultationSlot;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setConsultSelectedDoctor = (state = selectedDoctor, action) => {
  switch (action.type) {
    case SELECTED_DOCTOR:
      if (!action.payload) {
        action.payload = selectedDoctor;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
