import React from "react";
import "./VerticalIconText.css";

const VerticalIconText = ({ svg, title, image, onClick }) => {
  return (
    <div className="vertical-icon-text" onClick={onClick}>
      {/* {svg} */}
      {image ? <img src={image} alt={title} /> : svg}
      <span>{title || "No Title"}</span>
    </div>
  );
};

export default VerticalIconText;
