import React, { useState } from "react";
import consultDoctor from "Assests/Images/consultDoctor.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { doctorAppointmnetSubmit } from "API/post.api";
import { setAlert } from "../../../Redux/Actions/alert.action";
import {
  setConsultData,
  setSelectDoctor,
} from "../../../Redux/Actions/set.action";

const Consultation3 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const token = localStorage.getItem("access_token");
  const selectedConsultData = useSelector(
    (state) => state?.setConsultationSlot
  );
  const doctorDetails = useSelector((state) => state?.setConsultSelectedDoctor);

  const submitData = async () => {
    if (!selectedConsultData.slot) {
      dispatch(setAlert("error", "Please Select Slot"));
      return;
    } else if (!selectedConsultData.time) {
      dispatch(setAlert("error", "Please Select Time"));
      return;
    } else if (!selectedConsultData.userId) {
      dispatch(setAlert("error", "User Id Not Found"));
      return;
    } else if (!selectedConsultData?.relativeId) {
      dispatch(setAlert("error", "Please Select Relative"));
      return;
    } else if (!selectedConsultData?.modeOfConsultation) {
      dispatch(setAlert("error", "Please Select Consult Mode"));
      return;
    }
    const obj = {
      ...selectedConsultData,
    };
    delete obj.date;
    delete obj.timeStamp;

    try {
      const res = await doctorAppointmnetSubmit(token, id, obj);
      if (res?.status === 201) {
        dispatch(setAlert("success", res?.data?.message));
        setIsSubmit(true);
        dispatch(setConsultData({}));
        dispatch(setSelectDoctor({}));
        navigate(`/services/consultation/${id}/submit-request?submit=true`);
      } else {
        dispatch(setAlert("alert", res?.data?.message));
      }
    } catch (e) {
      console.log("err", e);
      dispatch(
        setAlert("alert", e?.response?.data?.message || "Not Submittted")
      );
    }
  };

  return (
    <div className="consultation-1 consultation-3">
      <div className="info-wrapper">
        {isSubmit ? (
          <div className="thank-wrapper">
            <h2>THANK YOU!</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle
                cx="28"
                cy="28"
                r="28"
                fill="#A71F23"
                stroke="none"
                strokeWidth="2"
              />

              <path
                d="M17 28l7.5 7.5L39 20"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <div className="patient-details-wrapper">
            <h3>Patient Details</h3>
            <div className="wrapper">
              <div className="left">
                <div className="single-line">
                  <label>Patient Name</label>
                  <p>{doctorDetails?.doctor_name || ""}</p>
                </div>
                <div className="single-line">
                  <label>Mobile Number</label>
                  <p>{doctorDetails?.mobile || doctorDetails?.phone || ""}</p>
                </div>
                <div className="single-line">
                  <label>Gender</label>
                  <p>{doctorDetails?.gender || ""}</p>
                </div>
                <div className="single-line">
                  <label>Age</label>
                  <p>{doctorDetails?.age || ""}</p>
                </div>
              </div>
              <div className="right">
                <div className="doctor-profile">
                  <img
                    src={doctorDetails?.doctor_image || consultDoctor}
                    alt="consultDoctor"
                  />
                  <div className="doc-description">
                    <h2>{doctorDetails?.doctor_name || ""}</h2>
                    <p>{doctorDetails?.designation}</p>
                  </div>
                </div>
                <div className="bottom">
                  <div className="one-line">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="20"
                        fill="#A71F23"
                        stroke="none"
                        strokeWidth="2"
                      />
                      <g transform="translate(10, 10)">
                        <path
                          d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                    <label>On {selectedConsultData?.date}</label>
                  </div>
                  <div className="one-line">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="20"
                        fill="#A71F23"
                        stroke="none"
                        strokeWidth="2"
                      />
                      <g transform="translate(10, 10)">
                        <path
                          d="M12 8V12H15M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>

                    <label> {selectedConsultData?.timeStamp}</label>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={submitData}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Consultation3;
