// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-stepper {
  display: flex;
  align-items: flex-start;
  max-width: 78%;
  margin: auto;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stepContent {
  margin-top: 8px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #d9d9d9;
  margin: 0 10px;
  margin-top: 1.5rem;
}

.completed .stepContent small,
.in-progress .stepContent small {
  color: #f1ba6a;
}

.pending .stepContent small {
  color: #d9d9d9;
}

@media screen and (max-width: 600px) {
  .custom-stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 78%;
    margin: auto;
  }
  .line {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Common/Stepper/stepper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,YAAY;EACd;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".custom-stepper {\n  display: flex;\n  align-items: flex-start;\n  max-width: 78%;\n  margin: auto;\n}\n\n.step {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.stepContent {\n  margin-top: 8px;\n}\n\n.line {\n  width: 100%;\n  height: 2px;\n  background-color: #d9d9d9;\n  margin: 0 10px;\n  margin-top: 1.5rem;\n}\n\n.completed .stepContent small,\n.in-progress .stepContent small {\n  color: #f1ba6a;\n}\n\n.pending .stepContent small {\n  color: #d9d9d9;\n}\n\n@media screen and (max-width: 600px) {\n  .custom-stepper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 78%;\n    margin: auto;\n  }\n  .line {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
