import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import "./VitalCheckSlider.css";
import OldManCheckup from "Assests/Images/OldManCheckup.png";
import GirlCheckup from "Assests/Images/GirlCheckup.png";
import maleImage from "Assests/Images/maleimage.png";
import femaleImage from "Assests/Images/femaleImage.png";
import { useDispatch, useSelector } from "react-redux";
import { bookNowApi } from "API/post.api";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { setAuthenticationModel } from "../../../Redux/Actions/model";

const VitalCheckSlider = ({ data }) => {
  const vitalData = useSelector((state) => state?.setPackagesList);

  // console.log(vitalData);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1051, min: 601 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 601, min: 0 },
      items: 1,
    },
  };

  const CardItem = ({
    id,
    img,
    alt,
    title,
    short_desc,
    price,
    original_price,
    include,
    parameter,
    long_desc,
    gender,
  }) => {
    const token = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const bookNow = (e) => {
      e.stopPropagation();
      if (!token) {
        // Replace False with true to Enable Auth Modal
        dispatch(setAuthenticationModel(true));
      } else {
        const apiStatus = bookNowApi(token, id);
        apiStatus
          .then((response) => {
            console.log(response);
            navigate("/cart");
          })
          .catch((err) => {
            console.log(err);
            dispatch(setAlert("error", err?.response?.data?.error));
          });
      }
    };

    return (
      <div className="card-item">
        {gender == "Male" ? (
          <img src={maleImage} alt={alt} />
        ) : gender == "Female" ? (
          <img src={femaleImage} alt={alt} />
        ) : (
          <img src={maleImage} alt={alt} />
        )}
        {/* <img src={img} alt={alt} /> */}
        <div className="main-content">
          <h6>{title || "No Title"}</h6>
          <div>
            <p className="price">
              ₱ {price || "No Price"}
              <span className="light">
                {/* € {original_price || "No Original Price"} */}
              </span>
            </p>
            <p className="stats">
              {parameter && (
                <span>
                  <span>Parameter</span> {parameter || "none"}
                </span>
              )}
            </p>
          </div>
        </div>

        {/* overlay on hover */}
        <div
          // onClick={() => navigate(`/services/${id}`)}
          className="card-overlay"
        >
          <div className="stats">
            {/* <span>
              <span>Include</span> {include || "none"}
            </span> */}
            <span>
              <span>Parameter</span> {parameter || "none"}
            </span>
          </div>
          <div className="content">
            <h6>{title || "No Title"}</h6>
            {/* <p>{long_desc || "No Description"}</p>  */}
            <div>
              <p className="price">
                ₱ {price || "No Price"}
                {/* <span className="light">
                  € {original_price || "No Original Price"}
                </span> */}
              </p>

              {/* <div className="percent">
                {Math.round(((original_price - price) / original_price) * 100)}%
                OFF
              </div> */}
            </div>

            <button onClick={bookNow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8 21.75C8.9665 21.75 9.75 20.9665 9.75 20C9.75 19.0335 8.9665 18.25 8 18.25C7.0335 18.25 6.25 19.0335 6.25 20C6.25 20.9665 7.0335 21.75 8 21.75Z"
                  fill="#2A364E"
                />
                <path
                  d="M16 21.75C16.9665 21.75 17.75 20.9665 17.75 20C17.75 19.0335 16.9665 18.25 16 18.25C15.0335 18.25 14.25 19.0335 14.25 20C14.25 20.9665 15.0335 21.75 16 21.75Z"
                  fill="#2A364E"
                />
                <path
                  d="M19.17 5.85023C18.84 5.47023 18.36 5.25023 17.85 5.25023H6C6 5.25023 5.95 5.26023 5.93 5.26023L5.89 4.78023C5.78 3.36023 4.58 2.24023 3.15 2.24023H3C2.59 2.24023 2.25 2.58023 2.25 2.99023C2.25 3.40023 2.59 3.74023 3 3.74023H3.15C3.8 3.74023 4.35 4.25023 4.4 4.89023L5.05 13.2702C5.2 15.2102 6.84 16.7302 8.79 16.7302H18.01C18.42 16.7302 18.76 16.3902 18.76 15.9802C18.76 15.5702 18.42 15.2302 18.01 15.2302H8.78C7.81 15.2302 6.98 14.6002 6.67 13.7302H15.4C17.25 13.7302 18.85 12.3502 19.11 10.5102L19.58 7.23023C19.65 6.73023 19.5 6.22023 19.17 5.84023V5.85023ZM17.63 10.3102C17.47 11.4102 16.51 12.2402 15.4 12.2402H6.46L6.04 6.74023H17.85C17.95 6.74023 18.01 6.79023 18.04 6.83023C18.07 6.86023 18.11 6.93023 18.1 7.03023L17.63 10.3102Z"
                  fill="#2A364E"
                />
              </svg>
              <p>Book now</p>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="vital-check-slider">
      <Carousel responsive={responsive} infinite={true}>
        {data?.length > 0 &&
          data.map((item, i) => (
            <div key={i}>
              <CardItem
                id={item?._id}
                img={item?.image}
                alt={item?.test_name}
                title={item?.test_name}
                short_desc={item?.intented_use}
                price={item?.mrp}
                gender={item?.test_by_gender}
                // original_price="3400.00"
                // include={7}
                // parameter={76}
              />
            </div>
          ))}

        {/* 
        <div>
          <CardItem
            img={GirlCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
            long_desc="Take charge of your health with our Youth Plus Bundle, offering a diverse range of specialized lab tests for young individuals. From comprehensive blood panels to in-depth hormone level assessments and nutritional analyses, gain valuable insights into your well-being. Empower yourself with knowledge "
          />
        </div>

        <div>
          <CardItem
            img={OldManCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
          />
        </div>

        <div>
          <CardItem
            img={GirlCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
          />
        </div>

        <div>
          <CardItem
            img={OldManCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
          />
        </div>

        <div>
          <CardItem
            img={OldManCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
          />
        </div>

        <div>
          <CardItem
            img={OldManCheckup}
            alt="OldManCheckup"
            title="Grand Checkup pro"
            short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors."
            price="2000.00"
            original_price="3400.00"
            include={7}
            parameter={76}
          />
        </div> */}
      </Carousel>
    </div>
  );
};

export default VitalCheckSlider;
