import AboutUs from "Pages/AboutUs/AboutUs";
import Career from "Pages/Career/Career";
import CareerApply from "Pages/Career/CareerApply";
import Cart from "Pages/Cart/Cart";
import CollectionLocation from "Pages/Cart/CollectionLocation";
import Payment from "Pages/Cart/Payment";
import ComingSoon from "Pages/ComingSoon/ComingSoon";
import Corporate from "Pages/Corporate/Corporate";
import Doctor from "Pages/Doctor/Doctor";
import HMOs from "Pages/HMOs/HMOs";
import Step1 from "Pages/HMOs/Step1";
import Step2 from "Pages/HMOs/Step2";
import Step3 from "Pages/HMOs/Step3";
import Step4 from "Pages/HMOs/Step4";
import Step5 from "Pages/HMOs/Step5";

import Diagnostics from "Pages/Services/Diagnostics";

import Home from "Pages/Home/Home";
import EditProfile from "Pages/Profile/EditProfile";
import Profile from "Pages/Profile/Profile";
import Services from "Pages/Services/Services";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Blog from "Pages/Blog/Blog";
import BlogDetail from "Pages/Blog/BlogDetail";
import Form from "Pages/Cart/Form";

import ThankYou from "Pages/Cart/ThankYou";
import Cancel from "Pages/Cart/Cancel";
import Consultation from "Pages/Services/Consultation";
import Labs from "Pages/Labs/Labs";
import Radiology from "Pages/Services/Radiology";
import Employment from "Pages/Services/Employment";
import Seafarer from "Pages/Services/Seafarer";
import LabHome from "Pages/Services/LabHome";
import Partners from "Pages/PartnerWithUs/Partners";
import Client from "Pages/Client/Client";
import Faq from "Pages/Faq/Faq";
import Privacy from "Pages/Privacy/Privacy";
import Disclaimers from "Pages/Disclaimers/Disclaimers";
import Terms from "Pages/Terms/Terms";
import BookAppointment from "Pages/Services/BookAppointMent/BookAppointment";
import ConsultationAppointment from "Pages/Services/BookAppointMent/ConsultationAppointment";
import Consultation1 from "Pages/Services/BookAppointMent/Consultation1";
import Consultation2 from "Pages/Services/BookAppointMent/Consultation2";
import Consultation3 from "Pages/Services/BookAppointMent/Consultation3";

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const _path = location.pathname;

  useEffect(() => {
    // First time automatic redirection to home page
    if (_path === "/" || _path === "") {
      navigate("/");
      // navigate("coming-soon");
    }
    // eslint-disable-next-line
  }, [_path]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />}>
        {/* <Route path="1" element={<About />} />
        <Route path="2" element={<Brand />} />
        <Route path="3" element={<Team />} />
        <Route path="4" element={<Service />} /> */}
      </Route>
      <Route path="/services/all-test-package" element={<Services />} />
      <Route path="/services/diagnostics" element={<Diagnostics />} />
      <Route path="/services/consultation" element={<Consultation />} />

      <Route path="/services/consultation/:id" element={<BookAppointment />} />
      <Route
        path="/services/consultation/:id"
        element={<ConsultationAppointment />}
      >
        <Route path="appointment-details" element={<Consultation1 />} />
        <Route path="patient-details" element={<Consultation2 />} />
        <Route path="submit-request" element={<Consultation3 />} />
      </Route>

      <Route path="/services/radiology" element={<Radiology />} />
      <Route path="/services/wellness-packages" element={<Employment />} />
      <Route path="/services/seafarer" element={<Seafarer />} />
      <Route path="/services/labhome" element={<LabHome />} />

      <Route path="/doctor" element={<Doctor />} />
      <Route path="/corporate" element={<Corporate />} />
      <Route path="/HMOs" element={<HMOs />}>
        <Route path="make-an-appointment" element={<Step1 />} />
        <Route path="fill-out-patient-details" element={<Step2 />} />
        <Route path="fill-out-hmo-details" element={<Step3 />} />
        <Route path="fill-out-doctors-details" element={<Step4 />} />
        <Route path="submit-the-request-for-approval" element={<Step5 />} />
      </Route>
      {/* <Route path="/partner-with-us" element={<PartnerWithUs />} /> */}
      <Route path="/partner-with-us" element={<Partners />} />
      <Route path="/career" element={<Career />} />
      <Route path="/career-apply/:id" element={<CareerApply />} />

      {/* Cart to Payment */}
      <Route path="/cart" element={<Cart />} />
      <Route path="/collect-location" element={<CollectionLocation />} />
      <Route path="/payment" element={<Payment />} />

      <Route path="/services/:id" element={<Services />} />

      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/edit" element={<EditProfile />} />

      {/* blog section */}
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="/form" element={<Form />} />

      <Route path="/thankyou" element={<ThankYou />} />

      <Route path="/cancel" element={<Cancel />} />

      {/* lab section */}
      <Route path="/labs" element={<Labs />} />

      <Route path="/client-portal" element={<Client />} />
      <Route path="/faqs" element={<Faq />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/disclaimers" element={<Disclaimers />} />
      <Route path="/terms" element={<Terms />} />
    </Routes>
  );
};

export default AppRoutes;
