import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useRef, useEffect } from "react";
import "./ContactUs.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { setContactModel } from "../../../Redux/Actions/model";

const ContactUs = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const canvasRef = useRef(null);
  const token = localStorage.getItem("access_token");
  const [expression, setExpression] = useState(generateRandomKeyword());
  const [captcha, setCaptcha] = useState("");
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Clear previous content
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Set text properties
    context.font = "30px Arial";
    context.fillStyle = "blue";
    context.textAlign = "center";
    context.textBaseline = "middle";
    // Draw CAPTCHA text
    context.fillText(expression, canvas.width / 2, canvas.height / 2);
  }, [expression]);

  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      formValues?.mobile_number &&
      !isValidPhoneNumber(`+${formValues?.mobile_number}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.keys(formValues).length < 5) {
      dispatch(setAlert("error", "All fields are required!"));
    } else if (captcha !== expression) {
      dispatch(setAlert("error", "Please enter a valid Captcha Code"));
    } else {
      const promise = await httpPathName
        .post(
          `home/contactUsDetails`,
          { ...formValues },
          { headers: { "x-auth-token": token } }
        )
        .then((response) => {
          dispatch(setAlert("success", response?.data?.message));
          setFormValues({});
          formRef.current.reset();
          setCaptcha("");
          dispatch(setContactModel(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert("error", err?.response?.data?.message));
        });
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <div className="contact-us">
      <p>Contact Us</p>
      <h6>Nederlands Diagnostics</h6>

      <form
        ref={formRef}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <label htmlFor="first-name">First Name</label>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
              stroke="#2A364E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
              stroke="#2A364E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            id="first-name"
            name="first_name"
            type="text"
            placeholder="Enter First Name"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <label htmlFor="last-name">Last Name</label>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
              stroke="#2A364E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
              stroke="#2A364E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            id="last-name"
            name="last_name"
            type="text"
            placeholder="Enter Last Name"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <label htmlFor="mobile">Mobile Number</label>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 69 65"
            fill="none"
          >
            <path
              d="M40.6579 57.4199L40.7846 47.4999C40.8054 45.5282 39.063 43.9038 36.8998 43.8812L31.6131 43.8261C31.5417 43.5802 31.4681 43.3252 31.3917 43.0561C30.6785 40.5962 29.704 37.2275 29.7634 32.6803C29.8211 28.1222 30.8831 24.7696 31.6572 22.3218C31.7392 22.0622 31.8192 21.8134 31.8918 21.5737L35.438 21.6129L37.1834 21.63C39.3503 21.6526 41.1312 20.0636 41.1568 18.0911L41.284 8.17029C41.309 6.19947 39.566 4.57424 37.3992 4.55164L34.4328 4.52862L34.4324 4.6052C33.3023 4.72278 32.1539 5.00872 31.0533 5.45005C30.0143 5.87839 29.1157 6.37749 28.3111 6.96936C21.8136 11.6771 19.0943 19.0926 18.9226 32.5598C18.6868 51.1738 27.2797 58.0623 27.6548 58.3551C28.4819 58.9851 29.3679 59.5009 30.371 59.9314C31.4505 60.3919 32.5896 60.7024 33.7145 60.844L33.718 60.9012L36.6828 60.9616C38.8472 60.9822 40.6293 59.3943 40.6579 57.4199ZM36.7118 58.7572L35.3924 58.7423L34.8768 58.7272C34.0626 58.7058 32.7853 58.5464 31.3368 57.9288C30.5182 57.5748 29.7907 57.1523 29.13 56.649C29.0487 56.5841 21.03 50.0465 21.2511 32.5841C21.4394 17.7361 24.7759 12.3025 29.7332 8.7092C30.3865 8.22927 31.1221 7.82202 31.9706 7.4735C33.444 6.88401 34.7238 6.7515 35.5361 6.74348L37.371 6.75406C38.252 6.76214 38.964 7.38632 38.9544 8.14662L38.8284 18.066C38.8176 18.8261 38.0919 19.4372 37.2088 19.4298L30.1251 19.3525L29.8912 20.1796C29.7603 20.6401 29.6023 21.1452 29.4271 21.6905C28.6111 24.2654 27.4935 27.7894 27.434 32.6562C27.371 37.5138 28.3965 41.0557 29.1441 43.6394C29.3063 44.1971 29.453 44.7078 29.5725 45.177L29.7865 46.0099L36.8703 46.0827C37.752 46.0936 38.4651 46.7212 38.4566 47.4804L38.3295 57.3964C38.3189 58.1554 37.593 58.7664 36.7118 58.7572Z"
              fill="#2A364E"
              stroke="#2A364E"
              strokeWidth="0.25"
            />
          </svg>
          <input
            id="mobile"
            name="mobile_number"
            autoComplete="off"
            type="tel"
            placeholder="Enter mobile number"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <label htmlFor="email">Email ID</label>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3.02878 8L8.48356 11.645C9.76421 12.4963 10.4045 12.9219 11.0964 13.0873C11.7078 13.2335 12.3451 13.2335 12.9565 13.0873C13.6483 12.9219 14.2887 12.4963 15.5693 11.645L20.9736 8.04005M7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V9.8C21 8.11984 21 7.27976 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z"
              stroke="#2A364E"
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            id="email"
            name="email_id"
            autoComplete="off"
            type="email"
            placeholder="Enter Email ID"
            onChange={(e) => handleChange(e)}
          />
        </div>

        <label htmlFor="message">Message</label>
        <div>
          <textarea
            id="message"
            name="message"
            autoComplete="off"
            placeholder="Message"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div
          style={{ border: "none", display: "flex", flexDirection: "column" }}
        >
          <p>Enter The Captcha</p>
          <input
            type="text"
            style={{
              border: "1px solid #5e5e5e",
              width: "50%",
              borderRadius: "40px",
              padding: "1rem",
            }}
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1%",
              gap: "4%",
              border: "unset",
            }}
          >
            <canvas ref={canvasRef} width={120} height={40}></canvas>
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => setExpression(generateRandomKeyword())}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 8.5C5 5 8.51657 3.00122 11.9991 3.00122C16.9692 3.00122 20.9982 7.03039 20.9982 12.0006C20.9982 16.9708 16.9692 21 11.9991 21C8.51657 21 5.49605 19.0217 3.99995 16.1276M3 8.5V4.00006M3 8.5H7"
                stroke="#131A29"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
