import BackButton from "Components/BackButton/BackButton";
import Footer from "Components/Footer/Footer";
import CareerModal from "Components/Popup/Career/Career";
import Header from "Components/Header/Header";
import React, { useState } from "react";
import "./CareerApply.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchJobDetailApi } from "API/get.api";
import { useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";

const CareerApply = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");

  const [show, setShow] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otherRelatedJobs, setOtherRelatedJobs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const apiStatus = fetchJobDetailApi(token, id);
    apiStatus
      .then((response) => {
        setJobData(response?.data?.data?.actualJob);
        setOtherRelatedJobs(response?.data?.data?.other_related_jobs);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const openModal = () => {
    return <CareerModal show={show} handleClose={() => setShow(false)} />;
  };

  const CareerCard = ({ data }) => {
    const { job_title, experience_required, location, type, job_id } = data
      ? data
      : "";

    return (
      <div
        className="career-card"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/career-apply/${job_id}`);
        }}
      >
        <h5>{job_title || "Job Title"}</h5>
        <p>
          Experience Required :<span>{experience_required || "none"}</span>
        </p>
        <p className="with-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M17.6733 5.81167C17.2483 4.99987 16.6627 4.283 15.952 3.70465C15.2413 3.12631 14.4203 2.69859 13.5391 2.44751C12.7133 2.20823 11.8595 2.07879 10.9999 2.06251C10.179 2.06156 9.36171 2.17258 8.57076 2.39251C7.67855 2.64069 6.84698 3.06989 6.12785 3.6534C5.40872 4.23691 4.81741 4.96222 4.39076 5.78417C3.92268 6.67396 3.64064 7.64972 3.56185 8.65202C3.48305 9.65432 3.60914 10.6622 3.93243 11.6142C4.89304 14.4346 6.53325 16.9752 8.70826 19.0117C9.32666 19.5991 10.147 19.9266 10.9999 19.9266C11.8528 19.9266 12.6732 19.5991 13.2916 19.0117C15.4794 16.9605 17.1263 14.4005 18.0858 11.5592C18.4087 10.6229 18.5386 9.63101 18.4677 8.64321C18.3968 7.6554 18.1265 6.69219 17.6733 5.81167ZM16.7566 11.1742C15.8663 13.7838 14.3492 16.1351 12.3383 18.0217C11.9762 18.3701 11.4932 18.5647 10.9908 18.5647C10.4883 18.5647 10.0053 18.3701 9.64326 18.0217C7.6167 16.1119 6.09275 13.7315 5.20659 11.0917C4.95068 10.3262 4.85443 9.51647 4.92381 8.71238C4.99318 7.90829 5.2267 7.12696 5.60993 6.41667C5.94512 5.75374 6.41373 5.16724 6.98635 4.69401C7.55896 4.22077 8.22322 3.87101 8.93743 3.66667C9.61169 3.49899 10.3053 3.42192 10.9999 3.43751C11.7328 3.4549 12.4602 3.56894 13.1633 3.77667C13.871 3.96377 14.5326 4.29477 15.1067 4.749C15.6808 5.20323 16.1551 5.77094 16.4999 6.41667C16.864 7.15038 17.0752 7.95037 17.1209 8.76815C17.1666 9.58593 17.0458 10.4045 16.7658 11.1742H16.7566Z"
              fill="#2A364E"
            />
            <path
              d="M11 5.72917C10.3201 5.72917 9.65552 5.93078 9.09023 6.30849C8.52494 6.68621 8.08434 7.22308 7.82417 7.8512C7.56399 8.47932 7.49592 9.17048 7.62855 9.83729C7.76119 10.5041 8.08858 11.1166 8.56932 11.5974C9.05007 12.0781 9.66257 12.4055 10.3294 12.5381C10.9962 12.6708 11.6874 12.6027 12.3155 12.3425C12.9436 12.0823 13.4805 11.6417 13.8582 11.0764C14.2359 10.5112 14.4375 9.84654 14.4375 9.16667C14.4375 8.25499 14.0753 7.38065 13.4307 6.73599C12.786 6.09134 11.9117 5.72917 11 5.72917ZM11 11.2292C10.5921 11.2292 10.1933 11.1082 9.85414 10.8816C9.51496 10.6549 9.25061 10.3328 9.0945 9.95596C8.93839 9.57908 8.89755 9.16438 8.97713 8.7643C9.05671 8.36421 9.25315 7.99671 9.54159 7.70826C9.83004 7.41982 10.1975 7.22338 10.5976 7.1438C10.9977 7.06422 11.4124 7.10506 11.7893 7.26117C12.1662 7.41728 12.4883 7.68163 12.7149 8.02081C12.9415 8.35998 13.0625 8.75875 13.0625 9.16667C13.0625 9.71368 12.8452 10.2383 12.4584 10.6251C12.0716 11.0119 11.547 11.2292 11 11.2292Z"
              fill="#2A364E"
            />
          </svg>
          {location || "none"}
        </p>
        <p className="with-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.7506 5.97V3.5C14.7506 2.81 14.1906 2.25 13.5006 2.25H10.5006C9.81058 2.25 9.25058 2.81 9.25058 3.5V5.97L8.28058 17.56C8.24058 17.99 8.37058 18.41 8.62058 18.76L10.5906 21.39C10.9206 21.83 11.4306 22.09 11.9906 22.09C12.5506 22.09 13.0606 21.83 13.3906 21.39L15.3606 18.76C15.6206 18.42 15.7406 17.99 15.7006 17.56L14.7306 5.97H14.7506ZM10.7506 3.75H13.2506V5.25H10.7506V3.75ZM14.1706 17.85L12.2006 20.48C12.0706 20.65 11.9306 20.65 11.8006 20.48L9.83058 17.85C9.83058 17.85 9.78058 17.74 9.78058 17.68L10.6906 6.75H13.3106L14.2206 17.68C14.2206 17.74 14.2106 17.8 14.1706 17.85Z"
              fill="#2A364E"
            />
          </svg>
          {type || "none"}
        </p>

        <p className="overlay">Posted: 1 day ago</p>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div>
        {loading ? (
          <div className="spin-wrapper">
            <Spinner />
          </div>
        ) : (
          <div className="career-apply-page">
            <BackButton />

            <div className="wrapper">
              <div className="left">
                <div className="top">
                  <div className="heading">
                    <p>
                      ID :<span>{jobData?.job_id || "none"}</span>
                    </p>

                    <p className="with-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19 5.25H18.75V4C18.75 3.59 18.41 3.25 18 3.25C17.59 3.25 17.25 3.59 17.25 4V5.25H12.75V4C12.75 3.59 12.41 3.25 12 3.25C11.59 3.25 11.25 3.59 11.25 4V5.25H6.75V4C6.75 3.59 6.41 3.25 6 3.25C5.59 3.25 5.25 3.59 5.25 4V5.25H5C3.48 5.25 2.25 6.48 2.25 8V18C2.25 19.52 3.48 20.75 5 20.75H19C20.52 20.75 21.75 19.52 21.75 18V8C21.75 6.48 20.52 5.25 19 5.25ZM20.25 18C20.25 18.69 19.69 19.25 19 19.25H5C4.31 19.25 3.75 18.69 3.75 18V8C3.75 7.31 4.31 6.75 5 6.75H19C19.69 6.75 20.25 7.31 20.25 8V18Z"
                          fill="#2A364E"
                        />
                        <path
                          d="M17 10.25H7C6.59 10.25 6.25 10.59 6.25 11C6.25 11.41 6.59 11.75 7 11.75H17C17.41 11.75 17.75 11.41 17.75 11C17.75 10.59 17.41 10.25 17 10.25ZM13 14.25H7C6.59 14.25 6.25 14.59 6.25 15C6.25 15.41 6.59 15.75 7 15.75H13C13.41 15.75 13.75 15.41 13.75 15C13.75 14.59 13.41 14.25 13 14.25Z"
                          fill="#2A364E"
                        />
                      </svg>
                      24 july, 2023
                    </p>
                  </div>

                  <CareerCard data={jobData} />

                  <div className="line-break"></div>

                  <div className="apply">
                    <p>Openings: {jobData?.openings || "none"}</p>
                    <button onClick={() => setShow(true)}>Apply</button>
                  </div>
                </div>
                <div className="bottom">
                  {/* <h6>Purpose</h6> */}
                  {jobData?.purpose && <p>{jobData?.purpose}</p>}

                  {/* <h6>Roles and Responsibilities</h6> */}
                  {jobData?.dynamic_fields?.length > 0 &&
                    jobData?.dynamic_fields.map((item, i) => (
                      <div key={i}>
                        <h3
                          style={{
                            marginTop: "1.2rem",
                            wordBreak: "break-word",
                          }}
                        >
                          <strong>{item?.title || "none"}</strong>
                        </h3>
                        <span>{item?.description}</span>
                      </div>
                    ))}

                  {jobData?.required_candidate_profile && (
                    <div>
                      <h6>Required Candidate Profile</h6>
                      <p>{jobData?.required_candidate_profile || "none"}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="right">
                <h6 style={{ marginBottom: "30px" }}>
                  <strong>Jobs you might be interested</strong>
                </h6>

                {otherRelatedJobs && otherRelatedJobs.length > 0
                  ? typeof otherRelatedJobs === "string"
                    ? otherRelatedJobs
                    : otherRelatedJobs.map((curr, idx) => (
                        <CareerCard data={curr} key={idx} />
                      ))
                  : null}

                <div className="line-break"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />

      {/* Modal */}
      {openModal()}
    </>
  );
};

export default CareerApply;
