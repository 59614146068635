import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./SampleCollection.css";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import moment from "moment";
import { setUploadModel } from "../../../Redux/Actions/model";

const SampleCollection = () => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const token = localStorage.getItem("access_token");
  const [formData, setFormData] = useState({});
  const [checked, setChecked] = useState(false);
  const uploadModelData = useSelector((state) => state.setUploadModel);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const uploadFile = () => {
    fileRef.current.click();
  };
  // const isValidFileUploaded = (file) => {
  //   const validExtensions = ["png", "jpeg", "jpg"];
  //   const fileExtension = file.type.split("/")[1];
  //   return validExtensions.includes(fileExtension);
  // };

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    console.log("filesss", file);
    if (file.size > 1.5e7) {
      dispatch(setAlert("error", "Please Upload File less than 15 Mb"));
      //file is invalid
    } else {
      setFormData({ ...formData, images: e.target.files[0] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!checked) {
      dispatch(setAlert("error", "Please Agree on Terms & Condition"));
      return;
    }
    if (
      formData?.mobile_number &&
      !isValidPhoneNumber(`+${formData?.mobile_number}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.keys(formData).length < 6) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      if (uploadModelData?.title == "Book Home Sample Collection") {
        formData.date_of_birth = moment(formData.date_of_birth).format(
          "DD-MM-YYYY"
        );
        const apiStatus = await httpPathName
          .post(
            "home/homeSampleCollection",
            { ...formData },
            { headers: { "x-auth-token": token } }
          )
          .then((response) => {
            dispatch(setAlert("success", response?.data?.message));
            dispatch(
              setUploadModel({
                show: false,
                title: "",
              })
            );
            //setShow(false);
          })
          .catch((err) => {
            console.log(err);
            dispatch(setAlert("error", err?.response?.data?.message));
          });
      } else {
        if (formData && !formData.images) {
          dispatch(setAlert("error", "Please Upload Document"));
          return;
        }
        delete formData.emailId;
        delete formData.dob;
        delete formData.mobile;
        delete formData.patient_name;
        delete formData.name;
        formData.date_of_birth = moment(formData.date_of_birth).format(
          "DD-MM-YYYY"
        );
        const apiStatus = await httpPathName
          .put(
            "home/uploadPrescription",
            { ...formData },
            {
              headers: {
                "x-auth-token": token,
                accept: "application/json",
                "Content-Type": `multipart/form-data`,
              },
            }
          )
          .then((response) => {
            dispatch(setAlert("success", response?.data?.message));
            dispatch(
              setUploadModel({
                show: false,
                title: "",
              })
            );
            //setShow(false);
          })
          .catch((err) => {
            dispatch(setAlert("error", err?.response?.data?.message));
          });
      }
    }

    // if (
    //   formData?.mobileNumber &&
    //   !isValidPhoneNumber(`+${formData?.mobileNumber}`)
    // ) {
    //   dispatch(setAlert("error", "Please enter a valid mobile number"));
    // } else if (Object.keys(formData).length < 6) {
    //   dispatch(setAlert("error", "All fields are required!"));
    // } else {
    //   console.log("formatat",formData)
    //   const apiStatus = addANewMemberApi(token, formData);
    //   apiStatus
    //     .then((response) => {
    //       dispatch(setAlert("success", response?.data?.message));
    //       parentSetShow(true);
    //       setShow(false);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       dispatch(setAlert("error", err?.response?.data?.message));
    //     });
    // }
  };

  return (
    <Modal
      show={uploadModelData?.show}
      onHide={() => {
        dispatch(
          setUploadModel({
            show: false,
            title: "",
          })
        );
        //setShow(false);
      }}
      centered
      className="sample-collection"
    >
      <Modal.Header closeButton>
        <Modal.Title>{uploadModelData?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <label htmlFor="full-name">Full Name</label>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                stroke="#2A364E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                stroke="#2A364E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              id="full-name"
              name={
                uploadModelData?.title == "Book Home Sample Collection"
                  ? "patient_name"
                  : "full_name"
              }
              type="text"
              placeholder="Enter Name"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <label htmlFor="mobile">Mobile Number</label>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.9214 4.08C17.3214 2.48 14.9814 1.87 12.8014 2.49C12.4014 2.6 12.1714 3.02 12.2814 3.42C12.3914 3.82 12.8114 4.05 13.2114 3.94C14.8614 3.47 16.6514 3.94 17.8614 5.15C19.0814 6.37 19.5414 8.15 19.0714 9.8C18.9614 10.2 19.1914 10.61 19.5914 10.73C19.6614 10.75 19.7314 10.76 19.7914 10.76C20.1214 10.76 20.4214 10.54 20.5114 10.21C21.1314 8.03 20.5114 5.69 18.9214 4.09V4.08Z"
                fill="#2A364E"
              />
              <path
                d="M16.0486 10.6C16.1786 10.7 16.3386 10.75 16.4986 10.75C16.7286 10.75 16.9486 10.65 17.0986 10.45C18.0686 9.17 17.9386 7.34 16.7986 6.2C15.6586 5.06 13.8386 4.93 12.5486 5.9C12.2186 6.15 12.1486 6.62 12.3986 6.95C12.6486 7.28 13.1186 7.35 13.4486 7.1C14.1386 6.58 15.1186 6.65 15.7386 7.26C16.3586 7.87 16.4186 8.86 15.8986 9.55C15.6486 9.88 15.7186 10.35 16.0486 10.6ZM15.3186 14.11C14.9386 13.81 14.4386 13.71 13.9686 13.84L12.4186 14.29C11.7486 13.64 11.1186 12.95 10.5486 12.22C9.89859 11.39 9.30859 10.5 8.79859 9.57L9.60859 8.16C9.85859 7.73 9.86859 7.2 9.64859 6.76L7.95859 3.36C7.60859 2.64 6.74859 2.34 6.01859 2.66C5.48859 2.89 4.97859 3.18 4.49859 3.51C4.02859 3.83 3.58859 4.2 3.18859 4.6C2.85859 4.93 2.70859 5.4 2.77859 5.87C3.12859 8.15 4.06859 11.56 6.56859 14.88C9.24859 18.45 12.5286 20.35 14.8086 21.31C14.9986 21.39 15.1986 21.43 15.3886 21.43C15.6986 21.43 16.0086 21.33 16.2786 21.13C16.8286 20.72 17.3386 20.23 17.7886 19.7C18.1286 19.29 18.4286 18.86 18.6986 18.4C19.0586 17.77 18.9086 16.97 18.3286 16.51L15.3186 14.1V14.11ZM16.6486 18.75C16.2786 19.19 15.8586 19.59 15.3986 19.94C13.2886 19.05 10.2486 17.29 7.76859 13.99C5.45859 10.92 4.58859 7.76 4.26859 5.65C4.59859 5.32 4.96859 5.02 5.34859 4.75C5.74859 4.47 6.17859 4.23 6.61859 4.03L8.30859 7.42L7.28859 9.19C7.15859 9.41 7.15859 9.68 7.27859 9.91C7.87859 11.05 8.57859 12.14 9.36859 13.15C10.0786 14.06 10.8686 14.91 11.7086 15.69C11.8986 15.87 12.1786 15.93 12.4286 15.86L14.3786 15.29L17.3986 17.67C17.1786 18.05 16.9286 18.42 16.6486 18.75Z"
                fill="#2A364E"
              />
            </svg>
            <input
              id="mobile"
              patient_name
              name={"mobile_number"}
              autoComplete="off"
              type="tel"
              placeholder="Enter Mobile No"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <label htmlFor="email">Email ID</label>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3.02878 8L8.48356 11.645C9.76421 12.4963 10.4045 12.9219 11.0964 13.0873C11.7078 13.2335 12.3451 13.2335 12.9565 13.0873C13.6483 12.9219 14.2887 12.4963 15.5693 11.645L20.9736 8.04005M7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V9.8C21 8.11984 21 7.27976 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z"
                stroke="#2A364E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input
              id="email"
              name={
                uploadModelData?.title == "Book Home Sample Collection"
                  ? "emailId"
                  : "email_id"
              }
              autoComplete="off"
              type="email"
              placeholder="Email"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <label htmlFor="gender">Gender</label>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                stroke="#2A364E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                stroke="#2A364E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <select
              id="gender"
              name="gender"
              type="text"
              onChange={(e) => handleChange(e)}
              defaultValue={"none"}
            >
              <option value="none">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Other</option>
            </select>
          </div>

          <label htmlFor="date_of_birth">Date of Birth</label>

          <div>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.9214 4.08C17.3214 2.48 14.9814 1.87 12.8014 2.49C12.4014 2.6 12.1714 3.02 12.2814 3.42C12.3914 3.82 12.8114 4.05 13.2114 3.94C14.8614 3.47 16.6514 3.94 17.8614 5.15C19.0814 6.37 19.5414 8.15 19.0714 9.8C18.9614 10.2 19.1914 10.61 19.5914 10.73C19.6614 10.75 19.7314 10.76 19.7914 10.76C20.1214 10.76 20.4214 10.54 20.5114 10.21C21.1314 8.03 20.5114 5.69 18.9214 4.09V4.08Z"
                fill="#2A364E"
              />
              <path
                d="M16.0486 10.6C16.1786 10.7 16.3386 10.75 16.4986 10.75C16.7286 10.75 16.9486 10.65 17.0986 10.45C18.0686 9.17 17.9386 7.34 16.7986 6.2C15.6586 5.06 13.8386 4.93 12.5486 5.9C12.2186 6.15 12.1486 6.62 12.3986 6.95C12.6486 7.28 13.1186 7.35 13.4486 7.1C14.1386 6.58 15.1186 6.65 15.7386 7.26C16.3586 7.87 16.4186 8.86 15.8986 9.55C15.6486 9.88 15.7186 10.35 16.0486 10.6ZM15.3186 14.11C14.9386 13.81 14.4386 13.71 13.9686 13.84L12.4186 14.29C11.7486 13.64 11.1186 12.95 10.5486 12.22C9.89859 11.39 9.30859 10.5 8.79859 9.57L9.60859 8.16C9.85859 7.73 9.86859 7.2 9.64859 6.76L7.95859 3.36C7.60859 2.64 6.74859 2.34 6.01859 2.66C5.48859 2.89 4.97859 3.18 4.49859 3.51C4.02859 3.83 3.58859 4.2 3.18859 4.6C2.85859 4.93 2.70859 5.4 2.77859 5.87C3.12859 8.15 4.06859 11.56 6.56859 14.88C9.24859 18.45 12.5286 20.35 14.8086 21.31C14.9986 21.39 15.1986 21.43 15.3886 21.43C15.6986 21.43 16.0086 21.33 16.2786 21.13C16.8286 20.72 17.3386 20.23 17.7886 19.7C18.1286 19.29 18.4286 18.86 18.6986 18.4C19.0586 17.77 18.9086 16.97 18.3286 16.51L15.3186 14.1V14.11ZM16.6486 18.75C16.2786 19.19 15.8586 19.59 15.3986 19.94C13.2886 19.05 10.2486 17.29 7.76859 13.99C5.45859 10.92 4.58859 7.76 4.26859 5.65C4.59859 5.32 4.96859 5.02 5.34859 4.75C5.74859 4.47 6.17859 4.23 6.61859 4.03L8.30859 7.42L7.28859 9.19C7.15859 9.41 7.15859 9.68 7.27859 9.91C7.87859 11.05 8.57859 12.14 9.36859 13.15C10.0786 14.06 10.8686 14.91 11.7086 15.69C11.8986 15.87 12.1786 15.93 12.4286 15.86L14.3786 15.29L17.3986 17.67C17.1786 18.05 16.9286 18.42 16.6486 18.75Z"
                fill="#2A364E"
              />
            </svg> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 9H21M7 3V5M17 3V5M6 13H8M6 17H8M11 13H13M11 17H13M16 13H18M16 17H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                stroke="#2A364E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              id="date_of_birth"
              name={"date_of_birth"}
              autoComplete="off"
              type="date"
              placeholder="Date Of Birth"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <label htmlFor="state">Select State</label>

          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                stroke="#2A364E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                stroke="#2A364E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <select
              id="state"
              name="state"
              type="text"
              onChange={(e) => handleChange(e)}
              defaultValue={"none"}
            >
              <option value="none">Select State</option>
              <option value="Quezon">Quezon City</option>
            </select>
          </div>
          {uploadModelData?.title != "Book Home Sample Collection" && (
            <>
              <label htmlFor="upload_document">Upload Document *</label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "none",
                }}
              >
                <p className="document">Document</p>
                <input
                  hidden
                  ref={fileRef}
                  onChange={fileChange}
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  name="file"
                  placeholder="Image"
                />
                <label>{formData?.images && formData?.images?.name}</label>
                <span onClick={uploadFile} className="uploadbtn">
                  Upload
                </span>
              </div>

              <label style={{ marginTop: "-10px" }}>
                Upload supported file (Max 15MB)
              </label>
            </>
          )}

          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <input
              type="checkbox"
              onChange={(e) => setChecked(e.target.checked)}
              style={{ marginBottom: "5px" }}
            />
            <label className="terms">
              I agree to the terms and conditions of the company.
            </label>
          </span>

          <button type="submit">Submit</button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SampleCollection;
