import React, { useEffect } from "react";
import "./Privacy.css";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import labs from "Assests/Images/labs.png";
import { useNavigate } from "react-router-dom";
import clientWanner from "Assests/Images/clientWanner.png";
import Wanner from "Components/Wnner/Wanner";

const Privacy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const scrollToSection = (typeId) => {
    const targetSectionContent = document.getElementById(typeId);
    let position = targetSectionContent.getBoundingClientRect();
    // scrolls to 20px above element
    window.scrollTo(position.left, position.top + window.scrollY - 200);

    // if (targetSectionContent) {
    //   targetSectionContent.scrollIntoView({ behavior: "smooth" });
    // }
  };

  return (
    <>
      <Header />
      <div className="privacy">
        <Wanner title="Privacy Policy" image={clientWanner} />

        <div className="privacyContainer">
          <div className="contentWrapper">
            <div className="left-Section">
              <h1 id="policy-cover">What This Policy Covers</h1>
              <p>
                Your privacy is important to us, and so is being transparent
                about how we collect, use, and share information about you. This
                policy is intended to help you understand:
              </p>
              <p>• What information we collect about you</p>
              <p>• How we use the information we collect</p>
              <p>• How do we store and secure the information we collect</p>
              <p>• How to access and control your information</p>
              <p>• How do we transfer information we collect internationally</p>
              <p>• Other important privacy information</p>
              <p>
                This Privacy Policy covers the information we collect about you
                when you use our products or services or otherwise interact with
                us (for example, by attending our premises or events or by
                communicating with us), unless a different policy is displayed.
                Nederlands Diagnostics (“ND”), we and us refers to Nederlands
                Diagnostics Private Inc. and any of our corporate affiliates. We
                offer a wide range of products, including our cloud, server and
                data center products. We refer to all of these products,
                together with our other services and websites as "Services" in
                this policy.
              </p>
              <p>
                This policy applies to information acquired from patients of ND
                that have completed the Patient Registration process and other
                accompanying forms that are required to administer their
                requests.
              </p>
              <p>
                Information collected and held by us is subject to the Data
                Privacy Act of 2012, and we respects and support privacy
                protection in relation to information collected. We are
                committed to complying with the Data Privacy Act (Republic Act
                No. 10173).
              </p>
              <h1 id="information">What Information We Collect About You</h1>
              <p>
                We collect and hold information relating to patients’ requests.
                This information includes name, address, and other details
                describing the patient. The personal information may include,
                but is not limited to the following data:
              </p>
              <p>• Name</p>
              <p>• Address</p>
              <p>• Telephone number</p>
              <p>• E-mail address</p>
              <p>• Age</p>
              <p>• Date of birth</p>
              <p>• Gender</p>
              <p>• Physician’s Name</p>
              <p>• Medical Information</p>
              <h1>Collection, Use and Disclosure of Personal Data</h1>
              <h1>How ND Uses Personal Information(Use of Data)</h1>
              <p>
                We use the information contained in the database primarily for
                managing and administering the medical diagnostic tests
                requested by patients directly or via their employers to
                complete their requirements. In addition, We utilize the
                information held on the database for the secondary purpose of
                performing statistical analysis, executing marketing campaigns,
                demand and supply strategic planning and product development.
                Explained further below. No persons other than us, or employees,
                are authorized to access patient information held in the program
                database. All persons who can access the information are
                required to maintain its confidentiality and comply with privacy
                laws.
              </p>
              <h1 id="collection">How is it Collected?</h1>
              <p>
                This happens whenever a patient registers in any of our branches
                and requests any of our services by completing registration,
                testing, and consent forms. In addition, on each occasion when a
                patient is tested, details of that transaction are recorded and
                kept in the program database including medical diagnostic
                testing results and information related to the patient. In
                addition, It is also provided to us voluntarily by you directly
                or via a third party) who has been duly authorized by you to
                disclose your personal data to us (your “authorized
                representative”, companies, and 3rd party health care providers,
                etc.) after you (or your authorized representative) have been
                notified of the purposes for which the data is collected, and
                you or your authorized representative have provided written
                consent to the collection and usage of your personal and medical
                data for those purposes, or collection and use of personal data
                without consent is permitted or required by laws. We shall seek
                your consent before collecting any additional personal data and
                before using your personal data for a purpose that has not been
                notified to you (except where permitted or authorized by law).
              </p>
              <h1>
                1. We may collect and use your personal data and medical
                information for any or all of the following purposes:
              </h1>
              <h2>(a) Primary Purpose:</h2>
              <p>
                • Managing and administering the medical diagnostic tests
                requested by the Data Subject{" "}
              </p>
              <p>
                • Performing obligations in the course of or in connection with
                our provision of the goods and/or services requested by you;
              </p>
              <p>
                • Transmitting to any unaffiliated third parties including our
                third-party service providers and agents, and relevant
                governmental and/or regulatory authorities, whether in the
                Philippines or abroad, for the aforementioned purposes; whilst
                complying with any applicable laws, regulations, codes of
                practice, guidelines, or rules, or to assist in law enforcement
                and investigations conducted by any governmental and/or
                regulatory authority;{" "}
              </p>
              <p>• Verifying your identity</p>
              <h2>
                (b) Secondary Purpose: performing statistical analysis,
                executing marketing campaigns, demand and supply strategic
                planning and product development.
              </h2>
              <p>
                • Responding to, handling, and processing queries, requests,
                applications, complaints, and feedback from you;{" "}
              </p>

              <p>• Managing your relationship with us;</p>
              <p>• Processing payment or credit transactions; </p>
              <p>
                • Sending you marketing information about our goods or services
                including notifying you of our marketing events, initiatives and
                promotions, lucky draws, membership and rewards schemes and
                other promotions;{" "}
              </p>
              <p>
                • Any other purposes for which you have provided the
                information;
              </p>
              <p>
                • Any other incidental business purposes related to or in
                connection with the above
              </p>
              <p>
                Whilst complying with any applicable laws, regulations, codes of
                practice, guidelines, or rules, or to assist in law enforcement
                and investigations conducted by any governmental and/or
                regulatory authority;
              </p>
              <h1>2. We may disclose your personal data:</h1>
              <p>
                • where such disclosure is required for performing obligations
                in the course of or in connection with our provision of the
                goods or services requested by you; or{" "}
              </p>
              <p>
                • To third party diagnostic testing facilities, service
                providers, agents and other organizations we have engaged to
                perform any of the functions listed in clause 1 above for us.
              </p>
              <p>
                • The purposes listed in the above clauses may continue to apply
                even in situations where your relationship with us (for example,
                pursuant to a contract) has been terminated or altered in any
                way, for a reasonable period thereafter (including, where
                applicable, a period to enable us to enforce our rights under
                any contract with you).{" "}
              </p>
              <h1 id="withdrawing">Withdrawing Your Consent</h1>
              <p>
                • The consent that you provide for the collection, use, and
                disclosure of your personal data will remain valid until such
                time it is withdrawn by you in writing. You may withdraw consent
                and request us to stop using and/or disclosing your personal
                data for any or all of the purposes listed above by submitting
                your request in writing or via email to our Data Protection
                Officer at the contact details provided below.
              </p>
              <p>
                • Upon receipt of your written request to withdraw your consent,
                we may require reasonable time (depending on the complexity of
                the request and its impact on our relationship with you) for
                your request to be processed and for us to notify you of the
                consequences of us acceding to the same, including any legal
                consequences which may affect your rights and liabilities to us.
                In general, we shall seek to process your request within ten
                (10) business days of receiving it.
              </p>

              <p>
                • Withdrawing of your consent, please note that we may not be in
                a position to continue providing our goods or services to you
                and we shall, in such circumstances, notify you before
                completing the processing of your request. Should you decide to
                cancel your withdrawal of consent, please inform us in writing
                in the manner described in clause 4 above.
              </p>

              <p>
                • Please note that withdrawing consent does not affect our right
                to continue to collect, use and disclose personal data where
                such collection, use and disclosure without consent is permitted
                or required under applicable laws.
              </p>
              <h1 id="stored">How Is It Stored And Managed ?</h1>
              <p>
                The patient registration and other testing and consent forms are
                securely stored at the branches, while the digital records are
                stored electronically in an encrypted computer database managed
                by us.
              </p>

              <p>
                We retain Data for as long as necessary for the use of our
                services or to provide access to and use of our website or for
                other essential purposes such as complying with our legal
                obligations, resolving disputes, enforcing our agreements and as
                long as processing & retaining your Data is necessary and
                permitted by applicable law.
              </p>
              <p>
                Since these needs can vary for different data types and
                purposes, actual retention periods can vary significantly.
              </p>
              <p>
                Even if we delete your Data, it may persist on backup or
                archival media for audit, legal, tax or regulatory purposes.
              </p>
              <h1 id="quality">Data Quality And Security</h1>
              <p>
                We are committed to taking all reasonable steps to make sure the
                information acquired using manual forms, and digital information
                held on its databases, are accurate and secure.
              </p>
              <h1 id="access">Access And Updating Customer Information</h1>
              <p>
                If you wish to review or update patient information about you
                held by us, you may approach any of our Customer Service
                personnel at the Customer Service counter at any of our branches
                or you may send an e-mail request to__________________ Every
                patient has the right not to receive direct marketing
                communications that are not included with any statement. If you
                wish to exercise this right, please inform our Customer Service
                personnel at any of our branches.
              </p>

              <h1 id="cookie">Use Of Cookies</h1>
              <p>
                Cookies are widely used on the internet. Cookies cannot identify
                a specific user but only the system on which they are used. We
                utilize cookies to collect information on the most popular
                pages, peak usage hours, and other factors that help us make our
                websites more user-friendly and efficient. When you visit our
                websites, we may place a cookie on your computer that relates to
                your personal information kept on our system the next time you
                visit. By changing your browser's preferences and options, you
                may control whether and how cookies are accepted. You can, for
                example, enable your browser to inform you when a cookie is
                received or to block cookies. However, if you choose not to
                accept cookies, you may not be able to access all the
                information and features of the website.
              </p>

              <h1 id="links">Links From Other Websites</h1>
              <p>
                Our website may contain hyperlinks to other organizations'
                websites. Even if you access other sites via links from our
                website, we cannot be held liable for their privacy policies and
                practices. Since this Privacy Policy only relates to our
                website, we recommend that you read the Privacy Policies of the
                other websites you visit. Furthermore, if you are linked to our
                website from a third-party site, we cannot be held liable for
                that third-party site's privacy policies and practices and
                recommend that you check that third-party site's Privacy Policy.
              </p>
              <h1 id="severability">Severability</h1>
              <p>
                If any court or competent authority finds that any provision of
                this Privacy Notice (or part of any provision) is invalid,
                illegal or unenforceable, that provision or part-provision will,
                to the extent required, be deemed to be deleted, and the
                validity and enforceability of the other provisions of this
                Privacy Notice will not be affected.
              </p>
              <h1 id="changes">Changes To This Privacy Policy</h1>
              <p>
                Please note that this Privacy Policy may be subject to change
                from time to time. The revised Privacy Policy will accordingly
                be published on this page. We will not reduce your rights under
                this Privacy Policy without your explicit consent. Please
                regularly check this Privacy Policy to ensure you are aware of
                the latest updates with respect to the same.
              </p>
              <h1 id="contact">Contact Us</h1>
              <p>
                We understand that you may have concerns about this statement or
                our data collection methods. Please get in touch with us using
                one of the following methods:
              </p>
              <p>Nederlands Group Data Protection Officer:</p>
              <p>
                Address: MegaOne Bldg. ground floor, Espana Blvd., cor. Mayon
                St., Sta. Teresita, Quezon City
              </p>
              <p>Email: contact@nederlandsdiagnostics.com</p>
            </div>
            <div className="right-Section">
              <p onClick={() => scrollToSection("policy-cover")}>
                What This Policy Covers
              </p>
              <p onClick={() => scrollToSection("information")}>
                What Information We Collect About You
              </p>
              <p onClick={() => scrollToSection("collection")}>
                Collection, Use and Disclosure of Personal Data
              </p>
              <p onClick={() => scrollToSection("withdrawing")}>
                Withdrawing Your Consent
              </p>
              <p onClick={() => scrollToSection("stored")}>
                How Is It Stored And Managed ?
              </p>
              <p onClick={() => scrollToSection("quality")}>
                Data Quality And Security
              </p>
              <p onClick={() => scrollToSection("access")}>
                Access And Updating Customer Information
              </p>
              <p onClick={() => scrollToSection("cookie")}>Use Of Cookies</p>
              <p onClick={() => scrollToSection("links")}>
                Links From Other Websites
              </p>
              <p onClick={() => scrollToSection("severability")}>
                Severability
              </p>
              <p onClick={() => scrollToSection("changes")}>
                Changes To This Privacy Policy
              </p>
              <p onClick={() => scrollToSection("changes")}>Contact Us</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
