import { RESET_ALERT, SET_ALERT } from "../../Redux/action.type";

export const setAlert = (type, message) => {
  return {
    type: SET_ALERT,
    payload: { type, message },
  };
};

export const resetAlert = () => {
  return {
    type: RESET_ALERT,
  };
};
