import React, { useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "./Profile.css";
import pooja from "Assests/Images/pooja.svg";
import user from "Assests/Icons/user.svg";
import email from "Assests/Icons/emailCM.svg";
import phone from "Assests/Icons/phoneIcon.svg";
import location from "Assests/Icons/location-pin.svg";
import closeIcon from "Assests/Icons/closeIcon.svg";
import roundedRed from "Assests/Icons/rounded-red.svg";
import check from "Assests/Icons/check.svg";
import calender from "Assests/Icons/calendar-7333844.svg";
import cupcake from "Assests/Icons/cupcake.svg";
import edit from "Assests/Icons/edit.svg";
import profile from "Assests/Images/profile.png";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import { setUserDataAction } from "../../Redux/Actions/set.action";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const userData = useSelector((state) => state?.setUserDataReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");

  const [formData, setFormData] = useState({
    name: userData?.name,
    email: userData?.email,
    mobile: userData?.mobile,
    dob: userData?.dob,
    age: userData?.age,
    address: userData?.address,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };

    if (name === "dob") {
      const age = moment().diff(moment(value), "years");
      updatedFormData = { ...updatedFormData, age };
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    delete formData.userId;
    if (
      formData?.mobileNumber &&
      !isValidPhoneNumber(`+${formData?.mobileNumber}`)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.keys(formData).length < 5) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      const promise = await httpPathName
        .put(
          `users/update-profile`,
          { ...formData },
          { headers: { "x-auth-token": token } }
        )
        .then((response) => {
          if (response.status === 200) {
            dispatch(setAlert("success", response?.data?.message));
            dispatch(setUserDataAction(response?.data?.updatedProfile));
            navigate("/profile");
          } else {
            dispatch(setAlert("error", response?.data?.message));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAlert("error", err?.response?.data?.message));
        });
    }
  };

  return (
    <>
      <Header />
      <div className="editProfileWrapper">
        <div className="homeProfile">
          <span
            onClick={() => navigate("/")}
            style={{ cursor: "pointer", color: "black" }}
          >
            Home
          </span>
          /<span>Profile</span>
        </div>
        <div className="editFormWrapper">
          <div className="imageWrapper">
            <div className="bothImage">
              {/* <img className="edit" src={edit} /> */}
              <img
                style={{ borderRadius: "100%" }}
                className="profilepic"
                src={profile}
                alt="profile"
              />
            </div>
            <div>
              <h1 className="name">{userData?.name}</h1>
              <p className="email">{userData?.email}</p>
            </div>
          </div>
          <form
            className="carrerBody"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="careerData">
              <div className="input-wrapper">
                <div>
                  <p>First Name *</p>
                  <div className="innerInputWrapper">
                    <img src={user} />
                    <input
                      name="name"
                      value={formData?.name}
                      onChange={(e) => handleChange(e)}
                      type="text"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
                {/* <div>
                  <p>Last Name *</p>
                  <div className="innerInputWrapper">
                    <img src={user} />
                    <input
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div> */}
                <div>
                  <p>Email ID </p>
                  <div className="innerInputWrapper">
                    <img src={email} />
                    <input
                      type="text"
                      name="email"
                      value={formData?.email}
                      onChange={(e) => handleChange(e)}
                      placeholder="Email"
                      disabled
                    />
                  </div>
                </div>
                {/* <div>
                  <p>Relationship</p>
                  <div className="innerInputWrapper">
                    <img src={user} />
                    <input
                      type="text"
                      name="relation"
                      onChange={(e) => handleChange(e)}
                      placeholder="Uncle"
                    />
                  </div>
                </div> */}
                <div>
                  <p>D.O.B</p>
                  <div className="innerInputWrapper">
                    <img src={calender} />
                    <input
                      type="date"
                      name="dob"
                      value={moment(formData?.dob).format("YYYY-MM-DD")}
                      onChange={(e) => handleChange(e)}
                      placeholder="State"
                    />
                  </div>
                </div>
                 {/*
                <div>
                  <p>Age</p>
                  <div className="innerInputWrapper">
                    <img src={cupcake} />
                    <input
                      name="age"
                      type="text"
                      value={formData?.age}
                      onChange={(e) => handleChange(e)}
                      placeholder="Age"
                      disabled
                    />
                  </div>
                </div>
                */}
                {/* <div>
                  <p>Location</p>
                  <div className="innerInputWrapper">
                    <img src={location} />
                    <input
                      name="location"
                      value={formData?.location}
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Location"
                    />
                  </div>
                </div> */}
                <div>
                  <p>Address</p>
                  <div className="innerInputWrapper">
                    <img src={location} />
                    <input
                      name="address"
                      value={formData?.address}
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="address"
                    />
                  </div>
                </div>
                {/* <div>
                  <p>Pin Code</p>
                  <div className="innerInputWrapper">
                    <img src={location} />
                    <input
                      type="text"
                      name="pincode"
                      value={formData?.pincode}
                      onChange={(e) => handleChange(e)}
                      placeholder="pincode"
                    />
                  </div>
                </div> */}
              </div>
              <div className="buttonWrapper">
                <button
                  onClick={() => navigate("/profile")}
                  type="button"
                  className="cancelbtn"
                >
                  Cancel
                </button>
                <button type="submit" className="submitbtn">
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditProfile;
