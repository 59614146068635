import React from "react";

const MyProfile = ({ orderData }) => {
  return (
    <div className="myProfile">
      <p>Upcoming Appointment Details</p>
      <table className="upcomingTable">
        {orderData?.length > 0 &&
          orderData?.map((item, i) => (
            <>
              {item?.orderItems.map((d, j) => (
                <>
                  <tr>
                    <th>Test Name</th>
                    <th>Time</th>
                    <th>Amount</th>
                  </tr>
                  <tr key={j}>
                    <td className="two-line-clamp">{d?.test_name}</td>
                    <td>{d?.slot}</td>

                    <td>{d?.mrp}</td>
                  </tr>
                </>
              ))}
            </>
          ))}
      </table>
    </div>
  );
};

export default MyProfile;
