import React, { useEffect, useState } from "react";
import user from "Assests/Icons/user.svg";
import email from "Assests/Icons/emailCM.svg";
import phone from "Assests/Icons/phoneIcon.svg";
import location from "Assests/Icons/location-pin.svg";
import closeIcon from "Assests/Icons/closeIcon.svg";
import roundedRed from "Assests/Icons/rounded-red.svg";
import check from "Assests/Icons/check.svg";
import calender from "Assests/Icons/calendar-7333844.svg";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Setting from "Components/Popup/Setting/Setting";

const Settings = () => {
  const token = localStorage.getItem("access_token");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  // const deleteAccount = async () => {
  //   const apiStatus = await httpPathName
  //     .delete("users/delete-user", { headers: { "x-auth-token": token } })
  //     .then((response) => {
  //       dispatch(setAlert("success", response?.data?.message));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       dispatch(setAlert("error", err?.response?.data?.message));
  //     });
  // };

  return (
    <>
      <div className="settings">
        {/* <form className='carrerBody' onSubmit={() => { }}>
          <div className='careerData'>
            <div className='input-wrapper'>
              <div>
                <p>First Name *</p>
                <div className='innerInputWrapper'>
                  <img src={user} />
                  <input type="text" placeholder="Your Name" />
                </div>

              </div>
              <div>
                <p>Last Name *</p>
                <div className='innerInputWrapper'>
                  <img src={user} />
                  <input type="text" placeholder="Last Name" />
                </div>
              </div>
              <div>
                <p>Email ID </p>
                <div className='innerInputWrapper'>
                  <img src={email} />
                  <input type="text" placeholder="Email" />
                </div>
              </div>
              <div>
                <p>Contact Number</p>
                <div className='innerInputWrapper'>
                  <img src={phone} />
                  <input type="text" placeholder="Mobile No" />
                </div>
              </div>
              <div>
                <p>D.O.B</p>
                <div className='innerInputWrapper'>
                  <img src={calender} />
                  <input type="date" placeholder="State" />
                </div>
              </div>
              <div>
                <p>Address</p>
                <div className='innerInputWrapper'>
                  <img src={location} />
                  <input type="text" placeholder="City" />
                </div>
              </div>
              <div>
                <p>Pincode</p>
                <div className='innerInputWrapper'>
                  <img src={location} />
                  <input type="text" placeholder="pincode" />
                </div>
              </div>
              <div>
                <p>Country</p>
                <div className='innerInputWrapper'>
                  <img src={location} />
                  <input type="text" placeholder="Country" />
                </div>
              </div>


              <div>
                <p>Password</p>
                <div className='innerInputWrapper'>
                  <img src={location} />
                  <input type="text" placeholder="Password" />
                </div>
              </div>
              <div>
                <p>Confirm Password</p>
                <div className='innerInputWrapper'>
                  <img src={location} />
                  <input type="text" placeholder="C-Password" />
                </div>
              </div>
              <div className='terms'>
                <input className='checkbx' type='checkbox' />
                <label>I agree to the terms and conditions of the company</label>
              </div>
            </div>
            <button className='submit'>Submit</button>
            <hr />
            
          </div>
        </form> */}
        <div className="deleteWrapper">
          <div>
            <p>Delete Account</p>
            <span>
              Would you like delete your account, once deleted account will not
              be reset.{" "}
            </span>
          </div>
          <button onClick={() => setShow(true)}>Delete Account</button>
        </div>
        {/* <div className='privacyPolicy'>
          <h1>Privacy Policy</h1>
          <p>It is a long established fact that a reader
            will be distracted by the readable content of a page when
            looking at its layout. The point of using Lorem Ipsum is
            that it has a more-or-less normal distribution of letters,
            as opposed to using 'Content here, content here', making it look
            like readable English. Many desktop publishing packages and web page
            editors now use Lorem Ipsum as their default model text,
            and a search for 'lorem ipsum' will uncover
            many web sites still in their infancy. Various versions
            have evolved over the years, sometimes by accident, sometimes on
            purpose (injected humour and the like).

            It is a long established
            fact that a reader will be distracted by the readable content of a page
            when looking at its layout. The point of using Lorem Ipsum is that it has a
            more-or-less normal distribution of letters, as opposed to using 'Content here,
            content here', making it look like readable English. Many desktop publishing packages
            and web page editors now use Lorem Ipsum as their default model text, and a search for
            'lorem ipsum' will uncover many web sites still in their infancy. </p>
        </div> */}
      </div>
      <Setting show={show} setShow={setShow} />
    </>
  );
};

export default Settings;
