import React, { useRef, useState } from "react";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useNavigate } from "react-router-dom";
import leftArrow from "Assests/Icons/arrow-narrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./HMOs.css";
import { setHmoStep2Action } from "../../Redux/Actions/hmo.action";

const Step2 = () => {
  const step2Data = useSelector((state) => state.setHmoStepReducer.step2);
  //const [tempFile, setTempFile] = useState(null)
  // console.log("FILEEEE",file)
  const [formData, setFormData] = useState({
    patient_first_name: step2Data?.patient_first_name,
    patient_last_name: step2Data?.patient_last_name,
    gender: step2Data?.gender,
    date_of_birth: step2Data?.date_of_birth,
    contact_number: step2Data?.contact_number,
    patient_type_of_validID: step2Data?.patient_type_of_validID,
    validID_number: step2Data?.validID_number,
    file: step2Data?.file,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileRef = useRef();
  const phoneRegex =
    /^(\+?\d{1,4}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;

  const validatePhoneNumber = (phone) => phoneRegex.test(phone);

  const uploadFile = () => {
    fileRef.current.click();
  };
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (isValidFileUploaded(file)) {
      setFormData({ ...formData, file: e.target.files[0] });
      //setTempFile(e.target.files[0])
    } else if (file.size > 1.5e7) {
      dispatch(setAlert("error", "Please Upload File less than 15 Mb"));
      //file is invalid
    } else {
      dispatch(setAlert("error", "Please Upload File type .jpg or .png only"));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //formData.file = tempFile

    if (
      formData?.contact_number &&
      !validatePhoneNumber(formData?.contact_number)
    ) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
    } else if (Object.values(formData).includes(undefined)) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      dispatch(setHmoStep2Action(formData));
      navigate("/HMOs/fill-out-hmo-details");
    }
  };

  return (
    <div className="hmo-step-1">
      <button onClick={() => navigate(-1)} className="backbtn">
        <img className="leftArrow" src={leftArrow} alt="leftarrow" />
      </button>

      <div className="dot-wrapper">
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Make an Appointment
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line active"></div>

        <div className="dot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="27.5" stroke="#F1BA6A" />
          </svg>
          <p className="step1Content">
            Fill Out Patient Details
            <br />
            <small className="fw-semibold" style={{ color: "#F1BA6A" }}>
              Progress
            </small>
          </p>
        </div>

        <div className="line"></div>

        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Fill out HMO Details.
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Fill Out Doctor’s Details
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Submit the request for approval.
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
      </div>
      <div className="phoneStepper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <p className="patientDetails" style={{ marginBottom: 0 }}>
            Fill Out Patient Details
          </p>
          <div className="steps">
            Step <span style={{ color: "#F1BA6A" }}>2</span>/5
          </div>
        </div>
        <small className="status fw-semibold">Progress</small>
      </div>

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="stepData"
      >
        <div className="input-wrapper">
          <div>
            <p>First Name *</p>
            <input
              name="patient_first_name"
              value={formData?.patient_first_name}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div>
            <p>Last Name *</p>
            <input
              name="patient_last_name"
              value={formData?.patient_last_name}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div>
            <p>Gender *</p>
            <select
              name="gender"
              required
              value={formData?.gender}
              onChange={(e) => handleChange(e)}
              id="gender"
            >
              <option hidden value="">
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <p>Date Of Birth *</p>
            <input
              name="date_of_birth"
              value={formData?.date_of_birth}
              onChange={(e) => handleChange(e)}
              type="date"
              required
              placeholder="123"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
          <div>
            <p>Contact Number *</p>
            <input
              name="contact_number"
              value={formData?.contact_number}
              onChange={(e) => handleChange(e)}
              autoComplete="off"
              type="tel"
              placeholder="Enter Phone Number"
            />
          </div>
          <div>
            <p>Patient Type of Valid ID *</p>
            <select
              value={formData?.patient_type_of_validID}
              onChange={(e) => handleChange(e)}
              name="patient_type_of_validID"
              id="patient_type_of_validID"
              required
            >
              <option hidden value="">
                Select Valid ID
              </option>
              <option value="Driving">Driving License</option>
              <option value="Id">Id Card</option>
            </select>
          </div>
          <div>
            <p>Valid ID Number: *</p>
            <input
              name="validID_number"
              value={formData?.validID_number}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Enter ID Number"
            />
          </div>
          <div className="uploadImageDoc">
            <p>Upload Image *</p>
            <input
              hidden
              ref={fileRef}
              onChange={fileChange}
              // onChange={e => {
              //     setFile(e.target.files[0]);
              // }}
              accept="image/png, image/gif, image/jpeg"
              type="file"
              name="file"
              placeholder="Image"
            />
            <label>
              {formData?.file?.name ? (
                <strong>{formData?.file?.name}</strong>
              ) : (
                "Image"
              )}
            </label>
            <div onClick={uploadFile} className="uploadFile">
              Upload
            </div>
          </div>
        </div>

        <div className="nextbtnWrapper">
          <button
            type="submit"
            // onClick={() => navigate("/HMOs/step3")}
            className="nextBtn"
          >
            Next
            <span className="rightArrowWrapper">
              <img src={rightArrow} alt="rightarow" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step2;
