// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container-brand {
    max-width: 780px;
    height: 300px;
}

.brand-info-slider {
    height: 300px;
}

.brand-info-slider .card-item {
    width: 220px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 4px 7px 13px 0px rgba(0, 0, 0, 0.18);
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        color: var(--primary-spacegrey, #2A364E);
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .desc {
        color: #2A364E;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
    }
}

.brand-info-slider .card-item img {
    padding-bottom: 24px;
}

.react-multi-carousel-dot-list  {
    position: relative;
}

.react-multi-carousel-dot button {
    background-color: #D9D9D9;
    border: none;
}

.react-multi-carousel-dot--active button {
    background-color: #A71F23;
}`, "",{"version":3,"sources":["webpack://./src/Components/Slider/BrandInfoSlider/BrandInfoSlider.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,gDAAgD;IAChD,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB;QACI,wCAAwC;QACxC,kBAAkB;QAClB,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,cAAc;QACd,kBAAkB;QAClB,eAAe;QACf,gBAAgB;IACpB;AACJ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".carousel-container-brand {\n    max-width: 780px;\n    height: 300px;\n}\n\n.brand-info-slider {\n    height: 300px;\n}\n\n.brand-info-slider .card-item {\n    width: 220px;\n    height: 240px;\n    flex-shrink: 0;\n    border-radius: 16px;\n    background: #FFF;\n    box-shadow: 4px 7px 13px 0px rgba(0, 0, 0, 0.18);\n    padding: 24px 8px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .title {\n        color: var(--primary-spacegrey, #2A364E);\n        text-align: center;\n        font-size: 18px;\n        font-weight: 600;\n    }\n\n    .desc {\n        color: #2A364E;\n        text-align: center;\n        font-size: 16px;\n        font-weight: 300;\n    }\n}\n\n.brand-info-slider .card-item img {\n    padding-bottom: 24px;\n}\n\n.react-multi-carousel-dot-list  {\n    position: relative;\n}\n\n.react-multi-carousel-dot button {\n    background-color: #D9D9D9;\n    border: none;\n}\n\n.react-multi-carousel-dot--active button {\n    background-color: #A71F23;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
