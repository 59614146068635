import React, { useEffect, useState } from 'react'
import FilterPanel from './FilterPanel'
import CardsView from './CardsView'
import './Services.css'

const ServicesList = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='servicesRoot'>
      <div className='hide-mobile'>
        <FilterPanel loading={loading} setLoading={setLoading} />
      </div>
      <CardsView loading={loading} setLoading={setLoading} />
    </div>
  )
}

export default ServicesList