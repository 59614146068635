import React, { useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import workingLab from "Assests/Images/woman-working-lab-with-microscope 1.png";
import dot3 from "Assests/Images/dot3.svg";
import "./HMOs.css";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import hmoWanner from "Assests/Images/hmoWanner.png";
import Wanner from "Components/Wnner/Wanner";
const HMOs = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="hmo">
        <Wanner title="HMOs" image={hmoWanner} />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default HMOs;
