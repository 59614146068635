import { useRef, useState, useEffect } from "react";
import { Button, Form, InputGroup, Modal, Tab, Tabs } from "react-bootstrap";
import { ReactComponent as MailIcon } from "Assests/Icons/mailIcon.svg";
import { ReactComponent as PhoneIcon } from "Assests/Icons/phoneIcon.svg";
import { ReactComponent as UserIcon } from "Assests/Icons/userIcon.svg";
import { ReactComponent as LockIcon } from "Assests/Icons/lockIcon.svg";
import "./Popup.css";
import CustomOtpInput from "Components/OTP/CustomOtpInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import isValidEmail, { setAuthTokenInDefaults } from "Global/Function/Function";
import httpPathName from "Global/Config/Config";
import {
  setUserDataAction,
  setUserForTestAction,
} from "../../Redux/Actions/set.action";
import { useNavigate } from "react-router-dom";

import MainLogo from "../../Assests/Images/ND.png";

export const TABS = {
  LOGIN: "login",
  SIGN_UP: "signUp",
};

export default function AuthenticationFlows(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showTabs, setShowTabs] = useState(true);
  const [currentTab, setCurrentTab] = useState(TABS.LOGIN);
  const [country, setCountry] = useState("+63");
  const [phone, setPhone] = useState("");
  const [loginStep, setLoginStep] = useState(0);
  const [signUpStep, setSignUpStep] = useState(0);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [otp, setOtp] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [authToken, setAuthToken] = useState("");

  //sign up fields state
  const [fullName, setFullName] = useState("");
  const [signUpCountry, setSignUpCountry] = useState("+63");
  const [signupPhone, setSignupPhone] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupOtp, setSignupOtp] = useState("");
  const [signupCheckbox, setSignupCheckbox] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    props.onClose();
    setShowTabs(true);
    setPhone("");
    setLoginStep(0);
    setSignUpStep(0);
    setOtp("");
    setShowPass(false);

    setFullName("");
    setSignupPhone("");
    setSignupEmail("");
    setSignupOtp("");
    setSignupCheckbox(false);

    if (currentTab === TABS.SIGN_UP) {
      setCurrentTab(TABS.LOGIN);
      swapColors();
    }
  };

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setSignupEmail(e.target.value);
  };

  const handleMobileChange = (e, isLogin) => {
    if (Boolean(isLogin)) {
      setPhone(e.target.value);
      setSignupPhone(e.target.value);
    } else {
      setSignupPhone(e.target.value);
    }
  };

  // const onClickLoginWithPassword = () => {
  //     setLoginStep(loginStep + 1)
  // }

  const onClickLoginWithOtp = () => {
    setLoginStep(loginStep - 1);
  };

  const onClickSignUp = () => {
    if (!fullName) {
      dispatch(setAlert("error", "Please enter a valid name"));
      return;
    }

    if (!isValidPhoneNumber(signUpCountry + signupPhone)) {
      dispatch(setAlert("error", "Please enter a valid mobile number"));
      return;
    }

    if (!isValidEmail(signupEmail)) {
      dispatch(setAlert("error", "Please enter a valid email id"));
      return;
    }

    if (!signupCheckbox) {
      dispatch(setAlert("error", "You must agree before submitting."));
      return;
    }
    var number = signUpCountry.replace(/\D/g, "").slice(-2);

    httpPathName
      .post("users/otpSignup", {
        mobile: number + signupPhone,
        email: signupEmail,
      })
      .then((res) => {
        if (res.status === 200) {
          setSignUpStep(signUpStep + 1);
          setShowTabs(false);
          dispatch(setAlert("success", res?.data?.message));
          setIsDisable(true);
          setResendTimer(180);
          setTimeout(() => {
            setIsDisable(false);
          }, 150000);
        } else {
          dispatch(setAlert("error", res?.response?.data?.error));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setAlert(
            "error",
            err?.response?.data?.message ?? err?.response?.data?.error
          )
        );
      });
  };

  const verifyOtpForSignup = () => {
    var number = signUpCountry.replace(/\D/g, "").slice(-2);
    httpPathName
      .post("users/signup", {
        name: fullName,
        email: signupEmail,
        mobile: number + signupPhone,
        otp: signupOtp,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(setAlert("success", res?.data?.message ?? res?.data?.error));
          handleClose();
        } else {
          dispatch(setAlert("error", res?.response?.data?.error));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setAlert(
            "error",
            err?.response?.data?.message ?? err?.response?.data?.error
          )
        );
      });
  };

  const resendOtpForSignup = async () => {
    try {
      const number = country.replace(/\D/g, "").slice(-2);
      const response = await httpPathName.post(
        "users/otpresend-Signup",
        {
          name: fullName,
          email: signupEmail,
          mobile: number + signupPhone,
        },
        {
          headers: {
            "X-Auth-Token": authToken,
            "content-type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        dispatch(setAlert("success", response?.data?.message));
        setIsDisable(true);
        setResendTimer(180);
        setTimeout(() => {
          setIsDisable(false);
        }, 150000);
      } else {
        dispatch(setAlert("error", response?.response?.data?.error));
      }
    } catch (err) {
      // Handle the error if needed
      console.error(err);
      dispatch(setAlert("error", err?.response?.data?.error));
    }
  };

  const onClickNext = () => {
    if (isValidPhoneNumber(country + phone)) {
      var number = country.replace(/\D/g, "").slice(-2);
      httpPathName
        .post("users/otpLogin", {
          mobile: number + phone,
        })
        .then((res) => {
          if (res.status === 200) {
            setLoginStep(loginStep + 1);
            setShowTabs(false);
            setAuthToken(res?.data?.user?.authToken);
            dispatch(setAlert("success", res?.data?.message));
            setIsDisable(true);
            setResendTimer(180);
            setTimeout(() => {
              setIsDisable(false);
            }, 150000);
          } else {
            dispatch(setAlert("error", res?.response?.data?.error));
          }
        })
        .catch((err) => {
          dispatch(setAlert("error", err?.response?.data?.error));
        });
    } else {
      dispatch(setAlert("danger", "Please enter a valid mobile number"));
    }
  };

  const verifyOtpForLogin = async () => {
    try {
      const number = country.replace(/\D/g, "").slice(-2);
      const response = await httpPathName.post(
        "users/otpVerify",
        {
          mobile: number + phone,
          otp: otp,
        },
        {
          headers: {
            "X-Auth-Token": authToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        handleClose();
        localStorage.setItem("access_token", authToken);
        const userData = response?.data?.data;

        // saving user data
        dispatch(setUserDataAction(userData));

        // saving user data for tests
        dispatch(
          setUserForTestAction([
            {
              userId: userData?._id,
              fullName: userData?.name,
              relationship: "self",
              mobileNumber: userData?.mobile,
              gender: "unknown",
              age: "0",
            },
          ])
        );

        // set token to axios common header
        setAuthTokenInDefaults(authToken);
        dispatch(setAlert("success", response?.data?.message));
        // redirect user to home page
        navigate("/");
      } else {
        dispatch(setAlert("error", response?.response?.data?.error));
      }
    } catch (err) {
      dispatch(
        setAlert(
          "error",
          err?.response?.data?.message ??
            err?.response?.data?.error ??
            "An error occurred"
        )
      );
    }
  };

  const resendOtpForLogin = async () => {
    try {
      const number = country.replace(/\D/g, "").slice(-2);
      const response = await httpPathName.post(
        "users/otpResend",
        {
          mobile: number + phone,
        },
        {
          headers: {
            "X-Auth-Token": authToken,
            "content-type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setAuthToken(response?.data?.user?.authToken);
        dispatch(setAlert("success", response?.data?.message));
        setIsDisable(true);
        setResendTimer(180);
        setTimeout(() => {
          setIsDisable(false);
        }, 150000);
      } else {
        dispatch(setAlert("error", response?.response?.data?.error));
      }
    } catch (err) {
      // Handle the error if needed
      console.error(err);
      dispatch(setAlert("error", err?.response?.data?.error));
    }
  };

  const changeCurrentTab = (value) => {
    if (value !== currentTab) {
      swapColors();
      setCurrentTab(value);
      setSignUpStep(0);
      setLoginStep(0);
    }
  };

  // console.log(signUpCountry);

  function loginFormBody() {
    switch (loginStep) {
      case 0:
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom01">
              <Form.Label required>
                Mobile Number <span className="star">*</span>
              </Form.Label>
              <InputGroup className="phone">
                <InputGroup.Text className="inputIcon">
                  <PhoneIcon />
                  <Form.Select
                    className="countryCode"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="+63">PH</option>
                    <option value="+91">IN</option>
                  </Form.Select>
                  {country}
                </InputGroup.Text>

                <Form.Control
                  className="inputStyle"
                  required
                  name="mobile"
                  type="tel"
                  placeholder="Enter mobile number"
                  autoComplete="off"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Button
              className="submit-btn mt-4"
              type="submit"
              disabled={false}
              onClick={onClickNext}
            >
              Next
            </Button>

            <p className="footerText">
              Don’t have an account?{" "}
              <span
                onClick={() => {
                  setCurrentTab(TABS.SIGN_UP);
                }}
                className="linkStyle"
              >
                Sign Up
              </span>
            </p>
          </Form>
        );
      case 1:
        return (
          <>
            <p className="otpHeading">
              An OTP has been sent on your registered mobile number.
            </p>
            <p className="otpCaption">Enter OTP</p>
            <CustomOtpInput value={otp} onChange={setOtp} numInputs={7} />

            <p className="resendOtp">
              <button
                style={{
                  color: isDisable ? "gray" : "",
                  pointerEvents: isDisable ? "none" : "",
                  paddingTop: "8px",
                  border: "unset",
                  background: "unset",
                }}
                disabled={isDisable}
                onClick={resendOtpForLogin}
              >
                Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
              </button>
            </p>
            <button
              className="submit-btn mt-4"
              type="submit"
              disabled={false}
              onClick={verifyOtpForLogin}
            >
              Verify
            </button>
            {/* <Button className="secondary-btn mt-4" type="submit" disabled={false} onClick={onClickLoginWithPassword}>
                            Login with Password
                        </Button> */}
          </>
        );
      case 2:
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom01">
              <Form.Label required>
                Mobile Number <span className="star">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text className="inputIcon">
                  <PhoneIcon />
                </InputGroup.Text>
                <Form.Control
                  className="inputStyle"
                  required
                  name="mobile"
                  type="tel"
                  placeholder="Enter mobile number"
                  autoComplete="off"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom13">
              <Form.Label required>
                Password <span className="star">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text className="inputIcon">
                  <LockIcon />
                </InputGroup.Text>
                <Form.Control
                  className="inputStyle"
                  required
                  name="password"
                  type="password"
                  placeholder="Enter password"
                  autoComplete="off"
                  onChange={(e) => {
                    // handleChange(e);
                  }}
                />
              </InputGroup>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Button
              className="submit-btn mt-4"
              type="submit"
              disabled={false}
              onClick={() => {}}
            >
              Login
            </Button>
            <Button
              className="secondary-btn mt-4"
              type="submit"
              disabled={false}
              onClick={onClickLoginWithOtp}
            >
              Login with OTP
            </Button>
          </Form>
        );
      default:
        return <></>;
    }
  }

  const clickHandler = () => {
    setShowPass(!showPass);
  };

  function signUpFormBody() {
    switch (signUpStep) {
      case 0:
        return (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="validationCustom11">
              <Form.Label required>
                Full Name <span className="star">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text className="inputIcon">
                  <UserIcon />
                </InputGroup.Text>
                <Form.Control
                  className="inputStyle"
                  required
                  name="full_name"
                  type="text"
                  placeholder="Full name"
                  autoComplete="off"
                  value={fullName}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                />
              </InputGroup>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom16">
              <Form.Label required>
                Email ID <span className="star">*</span>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text className="inputIcon">
                  <MailIcon />
                </InputGroup.Text>
                <Form.Control
                  className="inputStyle"
                  required
                  name="email"
                  type="email"
                  placeholder="Enter your email ID"
                  autoComplete="off"
                  value={signupEmail}
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                />
              </InputGroup>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom17">
              <Form.Label required>
                Mobile Number <span className="star">*</span>
              </Form.Label>
              <InputGroup className="phone">
                <InputGroup.Text className="inputIcon">
                  <PhoneIcon />
                  <Form.Select
                    className="countryCode"
                    value={signUpCountry}
                    onChange={(e) => setSignUpCountry(e.target.value)}
                  >
                    <option value="+63" selected>
                      PH
                    </option>
                    <option value="+91">IN</option>
                  </Form.Select>
                  {signUpCountry}
                </InputGroup.Text>
                <Form.Control
                  className="inputStyle"
                  required
                  name="mobile"
                  type="tel"
                  placeholder="Enter mobile number"
                  autoComplete="off"
                  value={signupPhone}
                  onChange={(e) => {
                    handleMobileChange(e, false);
                  }}
                />
              </InputGroup>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="validationCustom13">
                            <Form.Label required>
                                Password <span className="star">*</span>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text className='inputIcon'>
                                    <LockIcon />
                                </InputGroup.Text>
                                <Form.Control
                                    className="inputStyle"
                                    required
                                    name="password"
                                    type={showPass ? "text" : "password"}
                                    placeholder="Enter password"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </InputGroup>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group> */}

            {/* <Form.Group className="mb-3" controlId="validationCustom13">
                            <Form.Label required>
                                Confirm Password <span className="star">*</span>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text className='inputIcon'>
                                    <LockIcon />
                                </InputGroup.Text>
                                <Form.Control
                                    className="inputStyle"
                                    required
                                    name="password"
                                    type="password"
                                    placeholder="Enter password"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                />
                            </InputGroup>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group> */}

            <Form.Group className="mb-3">
              <Form.Check
                className="checkboxInput flexRowAlign"
                required
                value={signupCheckbox}
                onChange={(e) => setSignupCheckbox(e.target.checked)}
                label="I agree to the terms and conditions of the company."
                feedback="You must agree before submitting."
                feedbackType="invalid"
              />
            </Form.Group>

            <Button
              className="submit-btn"
              type="submit"
              disabled={false}
              onClick={onClickSignUp}
            >
              Sign Up
            </Button>

            <p className="footerText">
              Already have an account?{" "}
              <span
                onClick={() => {
                  setCurrentTab(TABS.LOGIN);
                }}
                className="linkStyle"
              >
                Login!
              </span>
            </p>
          </Form>
        );
      case 1:
        return (
          <>
            <p className="otpHeading">
              An OTP has been sent on your registered mobile number.
            </p>
            <p className="otpCaption">Enter OTP</p>
            <CustomOtpInput
              value={signupOtp}
              onChange={setSignupOtp}
              numInputs={7}
            />

            <p className="resendOtp">
              <button
                style={{
                  color: isDisable ? "gray" : "",
                  pointerEvents: isDisable ? "none" : "",
                  paddingTop: "8px",
                  border: "unset",
                  background: "unset",
                }}
                disabled={isDisable}
                onClick={resendOtpForSignup}
              >
                Resend OTP {resendTimer > 0 && `(${resendTimer}s)`}
              </button>
            </p>

            <Button
              className="submit-btn mt-4"
              type="submit"
              disabled={false}
              onClick={verifyOtpForSignup}
            >
              Verify
            </Button>
          </>
        );
      default:
        return <></>;
    }
  }

  const span1Ref = useRef(null);
  const span2Ref = useRef(null);

  function swapColors() {
    if (Boolean(span1Ref.current) && Boolean(span2Ref.current)) {
      [
        span1Ref.current.style.backgroundColor,
        span2Ref.current.style.backgroundColor,
      ] = [
        span2Ref.current.style.backgroundColor,
        span1Ref.current.style.backgroundColor,
      ];
      [span1Ref.current.style.height, span2Ref.current.style.height] = [
        span2Ref.current.style.height,
        span1Ref.current.style.height,
      ];
      [span1Ref.current.style.transform, span2Ref.current.style.transform] = [
        "translateX(100%)",
        "translateX(-100%)",
      ];
      setTimeout(() => {
        [span1Ref.current.style.transform, span2Ref.current.style.transform] = [
          "translateX(0)",
          "translateX(0)",
        ];
      }, 100);
    }
  }

  return (
    <>
      <Modal
        show={props.open}
        backdrop="static"
        keyboard={false}
        className="customModal"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <div>
            <h3 className="headerCaption text-xl">
              {currentTab === TABS.LOGIN ? "Login to" : "Sign Up"}
            </h3>
            {/* <div
              className="headerSubject text-center"
              style={{ paddingBottom: showTabs && "20px" }}
            >
              NeatherLand Diagonastics
              <img src={MainLogo} className="img-fluid" alt="Logo" />
            </div> */}

            <div className="headerLogo my-4 text-center">
              <img
                src={MainLogo}
                className="img-fluid"
                width="200"
                alt="Logo"
              />
            </div>
            <div className="tabsComp">
              {showTabs && (
                <>
                  <Tabs
                    defaultActiveKey={TABS.LOGIN}
                    variant="underline"
                    activeKey={currentTab}
                    onSelect={changeCurrentTab}
                  >
                    <Tab eventKey={TABS.LOGIN} title="Login" />
                    <Tab eventKey={TABS.SIGN_UP} title="Signup" />
                  </Tabs>

                  <div className="flexRowAlignJustify">
                    <span
                      ref={span1Ref}
                      className="tabSlider"
                      style={{
                        height: "4px",
                        backgroundColor: "#A71F23",
                        transition: "transform 330ms ease",
                      }}
                    />
                    <span
                      ref={span2Ref}
                      className="tabSlider"
                      style={{ height: "2px", backgroundColor: "#5E5E5E" }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {currentTab === "login" ? loginFormBody() : signUpFormBody()}
        </Modal.Body>
      </Modal>
    </>
  );
}
