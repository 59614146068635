import React, { useEffect, useState } from "react";
import diagnostic from "Assests/Images/Rectangle 102.png";
import "./AboutUs.css";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import httpPathName from "Global/Config/Config";

const Service = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getdata = async () => {
    const apiStatus = await httpPathName
      .get(`/admin/content/all-service`)
      .then((response) => {
        setData(response?.data?.Allservice);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleClick = (type) => {
    if (type === "Diagnostics") {
      navigate("/services/diagnostics")
    } else if (type === "Consultation") {
      navigate("/services/consultation")
    }else if(type === "Radiology"){
      navigate("/services/radiology")
    }else if(type ==="Employment Check"){
      navigate("/services/wellness-packages")
    }else if(type === "Seafarer's Pre"){
      navigate("/services/seafarer")
    }else if(type === "Lab @ Home"){
      navigate("/services/labhome")
    }
  }

  return (
    <div className="aboutservice">
      <div className="wrapper">
        {data?.length > 0 &&
          data?.map((item, i) => (
            <div
              key={i}
              onClick={() => handleClick(item?.heading)}
              className="aboutservice-card"
            >
              <div className="content">
                {i % 2 == 0 ? (
                  <div className="imageWrapper">
                    <img src={item?.image || diagnostic} alt="diagnostic" />
                  </div>
                ) : (
                  <div className="right-side">
                    <h1>{item?.heading}</h1>
                    <h2>{item?.subheading}</h2>
                    <p>{item?.description}</p>
                    {/* <div className="viewMore">
                  <p>View More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="12"
                    viewBox="0 0 72 12"
                    fill="none"
                  >
                    <path
                      d="M71.5303 6.53033C71.8232 6.23744 71.8232 5.76256 71.5303 5.46967L66.7574 0.696699C66.4645 0.403806 65.9896 0.403806 65.6967 0.696699C65.4038 0.989593 65.4038 1.46447 65.6967 1.75736L69.9393 6L65.6967 10.2426C65.4038 10.5355 65.4038 11.0104 65.6967 11.3033C65.9896 11.5962 66.4645 11.5962 66.7574 11.3033L71.5303 6.53033ZM0 6.75H71V5.25H0V6.75Z"
                      fill="#A71F23"
                    />
                  </svg>
                </div> */}
                  </div>
                )}

                {i % 2 != 0 ? (
                  <div className="imageWrapper">
                    <img src={item?.image || diagnostic} alt="diagnostic" />
                  </div>
                ) : (
                  <div className="right-side">
                    <h1>{item?.heading}</h1>
                    <h2>{item?.subheading}</h2>
                    <p>{item?.description}</p>
                    {/* <div className="viewMore">
                  <p>View More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="12"
                    viewBox="0 0 72 12"
                    fill="none"
                  >
                    <path
                      d="M71.5303 6.53033C71.8232 6.23744 71.8232 5.76256 71.5303 5.46967L66.7574 0.696699C66.4645 0.403806 65.9896 0.403806 65.6967 0.696699C65.4038 0.989593 65.4038 1.46447 65.6967 1.75736L69.9393 6L65.6967 10.2426C65.4038 10.5355 65.4038 11.0104 65.6967 11.3033C65.9896 11.5962 66.4645 11.5962 66.7574 11.3033L71.5303 6.53033ZM0 6.75H71V5.25H0V6.75Z"
                      fill="#A71F23"
                    />
                  </svg>
                </div> */}
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Service;
