import {
  CART_DATA,
  SELECTED_USERS_FOR_TEST,
  SIGN_OUT,
  USER_DATA,
  CART_TOTAL_AMOUNT,
  CART_REF_NO,
  MEMBER_LIST_DATA,
  SET_ALL_TESTS,
  CART_SELECTED_MEMBER_ID,
  PACKAGES_LIST,
  TESTS_LIST,
  ORDER_ID,
  RELATION_DATA,
  CONSULTATION_SLOT_DATA,
  SELECTED_DOCTOR,
} from "../../Redux/action.type";

export const signout = () => {
  return {
    type: SIGN_OUT,
  };
};

export const setUserDataAction = (data) => {
  return {
    type: USER_DATA,
    payload: data,
  };
};

export const setCartDataAction = (data) => {
  return {
    type: CART_DATA,
    payload: data,
  };
};

export const setOrderId = (data) => {
  return {
    type: ORDER_ID,
    payload: data,
  };
};

export const setUserForTestAction = (data) => {
  return {
    type: SELECTED_USERS_FOR_TEST,
    payload: data,
  };
};

export const setUserForTestTotalAmount = (data) => {
  return {
    type: CART_TOTAL_AMOUNT,
    payload: data,
  };
};

export const setUserCartRef = (data) => {
  return {
    type: CART_REF_NO,
    payload: data,
  };
};

export const setMemberList = (data) => {
  return {
    type: MEMBER_LIST_DATA,
    payload: data,
  };
};

export const setTestData = (data) => {
  return {
    type: SET_ALL_TESTS,
    payload: data,
  };
};

export const setSelectCartMemberId = (data) => {
  return {
    type: CART_SELECTED_MEMBER_ID,
    payload: data,
  };
};

export const setTestsList = (data) => {
  return {
    type: TESTS_LIST,
    payload: data,
  };
};

export const setPackagesList = (data) => {
  return {
    type: PACKAGES_LIST,
    payload: data,
  };
};

export const setRelData = (data) => {
  return {
    type: RELATION_DATA,
    payload: data,
  };
};

export const setConsultData = (data) => {
  return {
    type: CONSULTATION_SLOT_DATA,
    payload: data,
  };
};

export const setSelectDoctor = (data) => {
  return {
    type: SELECTED_DOCTOR,
    payload: data,
  };
};
