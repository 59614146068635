import React from "react";
import Logo from "Assests/Icons/WebsiteLogo.png";
import "./ComingSoon.css";
import { useState } from "react";
import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { subscribeApi } from "API/post.api";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  return (
    <div className="coming-soon">
      <div className="page-required">
        <div style={{ width: "200px" }}>
          <img
            style={{ objectFit: "cover", width: "100%" }}
            src={Logo}
            alt="Logo"
          />
        </div>

        {/* <button onClick={() => navigate("/about-us/1")}>About Us</button>
        <button onClick={() => navigate("/career")}>Career</button> */}
      </div>

      <section className="section-one">
        <div className="col-lg-6 col-md-8 mx-auto">
          {/* <div className="logo">
            <img src={Logo} alt="Logo" />
          </div> */}
          <h2>World class diagnostics at affordable price.</h2>
          <p>AGILE | ACCURATE | AFFORDABLE | ACCESSIBLE</p>
          <h1>COMING SOON</h1>
          <span>STAY TUNED</span>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <form className="subscribe">
                <label>SUBSCRIBE NOW TO GET THE UPDATE</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Enter your Email ID"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="button"
                  className="submit-btn"
                  onClick={() => {
                    const apiStatus = subscribeApi(email);
                    apiStatus
                      .then((response) => {
                        dispatch(setAlert("success", response?.data?.message));
                      })
                      .catch((error) => {
                        console.log("error", error);
                        if (
                          error?.response?.data &&
                          Object.values(error?.response?.data)[0]
                        )
                          dispatch(
                            setAlert(
                              "danger",
                              Object.values(error?.response?.data)[0]
                            )
                          );
                      });
                  }}
                ></button>
              </form>

              <Countdown
                date={"2023-09-10T12:00:00"}
                renderer={(props) => (
                  <div className="time-content">
                    <div className="time days">
                      <span className="number">
                        {(props?.days < 10 ? `0${props?.days}` : props?.days) ||
                          "00"}
                      </span>
                      <span className="text">days</span>
                    </div>
                    <div className="time hours">
                      <span className="number">
                        {(props?.hours < 10
                          ? `0${props?.hours}`
                          : props?.hours) || "00"}
                      </span>
                      <span className="text">hours</span>
                    </div>
                    <div className="time minutes">
                      <span className="number">
                        {(props?.minutes < 10
                          ? `0${props?.minutes}`
                          : props?.minutes) || "00"}
                      </span>
                      <span className="text">minutes</span>
                    </div>
                    <div className="time seconds">
                      <span className="number">
                        {(props?.seconds < 10
                          ? `0${props?.seconds}`
                          : props?.seconds) || "00"}
                      </span>
                      <span className="text">seconds</span>
                    </div>
                  </div>
                )}
              />

              {/* <div className="social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/Nederlandsdiagnostics"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="26"
                        viewBox="0 0 14 26"
                        fill="none"
                      >
                        <path
                          d="M3.81277 25.5503V12.7752H0.601562V8.37284L3.81277 8.37085L3.80828 5.77791C3.80828 2.18642 4.78237 0.00019455 9.01257 0.00019455H13.3765V4.40459H10.333C8.68521 4.40459 8.60687 5.01939 8.60687 6.16715L8.60038 8.37085H13.4179L12.9518 12.7737L8.60337 12.7752L8.60038 25.5503H3.81277Z"
                          fill="#A71F23"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/nederlands-diagnostics/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_325_3406)">
                          <path
                            d="M0.891721 7.9846V25.5503H5.63143V7.9846H0.891721ZM15.3514 7.9861C17.0091 7.9861 21.8302 8.65829 21.8302 14.6466V25.5503H16.8095V14.4919C16.8095 13.1954 16.075 12.2897 14.4846 12.2897C11.8447 12.2897 12.0189 14.4919 12.0189 14.4919V25.5503H7.25375V7.9846H12.0189V9.37389C12.7505 8.81348 13.6158 7.9861 15.3514 7.9861ZM0.84082 3.9924C0.84082 2.66998 1.91372 1.59708 3.23612 1.59708C4.55853 1.59708 5.63143 2.66998 5.63143 3.9924C5.63143 5.31482 4.55853 6.38772 3.23612 6.38772C1.91372 6.38772 0.84082 5.31482 0.84082 3.9924Z"
                            fill="#A71F23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_325_3406">
                            <rect
                              width="25.5998"
                              height="25.6"
                              fill="white"
                              transform="matrix(1 0 0 -1 0.817383 25.6001)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/nederlandsdiagnostics/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_325_3409)">
                          <path
                            d="M25.237 0.00019455C25.8723 0.00019455 26.3897 0.516188 26.3897 1.15145V24.399C26.3897 25.0328 25.8723 25.5503 25.237 25.5503H1.99258C1.35733 25.5503 0.839844 25.0328 0.839844 24.399V1.15145C0.839844 0.516188 1.35733 0.00019455 1.99258 0.00019455H25.237ZM18.0731 12.7752C18.0731 10.3131 16.077 8.31695 13.6148 8.31695C11.1526 8.31695 9.15654 10.3131 9.15654 12.7752C9.15654 15.2374 11.1526 17.2335 13.6148 17.2335C16.077 17.2335 18.0731 15.2374 18.0731 12.7752ZM23.196 22.3565V11.1784H21.0931C21.2029 11.6944 21.2668 12.2273 21.2668 12.7752C21.2668 16.994 17.8335 20.4273 13.6148 20.4273C9.39607 20.4273 5.9628 16.994 5.9628 12.7752C5.9628 12.2273 6.02667 11.6944 6.13646 11.1784H4.03358V22.3565H23.196ZM23.196 7.9846V3.19396H18.4054V7.9846H23.196Z"
                            fill="#A71F23"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_325_3409">
                            <rect
                              width="25.5998"
                              height="25.6"
                              fill="white"
                              transform="matrix(1 0 0 -1 0.817383 25.6001)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComingSoon;
