import NextBtn from "Components/Common/Button/NextBtn";
import httpPathName from "Global/Config/Config";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { setConsultData, setRelData } from "../../../Redux/Actions/set.action";
import FamilyDetailCard from "Components/Cards/FamilyDetail/FamilyDetailCard";
import Loading from "Components/Common/Loading/Loading";
import "./BookAppointment.css";
import AddMember from "Components/Popup/AddMember/AddMember";

const Consultation2 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const relData = useSelector((state) => state?.setRelationData);
  const selectedConsultData = useSelector(
    (state) => state?.setConsultationSlot
  );

  const handleClick = () => {
    if (!selectedId) {
      dispatch(setAlert("error", "Please Select Member"));
      return;
    }
    dispatch(
      setConsultData({
        ...selectedConsultData,
        relativeId: selectedId,
      })
    );
    navigate(`/services/consultation/${id}/submit-request`);
  };

  const getRelationshipData = async () => {
    try {
      setLoading(true);
      const response = await httpPathName.get("users/relationships", {
        headers: { "x-auth-token": token },
      });
      if (response?.status === 200) {
        dispatch(setRelData(response?.data?.relationship));
      } else {
        dispatch(setAlert("error", response?.data?.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(setAlert("error", err?.response?.data?.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRelationshipData();
  }, [show]);

  const selectedCard = (id) => {
    setSelectedId(id);
  };

  return (
    <div className="consultation-2 consultation-1">
      <div className="info-wrapper">
        {/* <FamilyDetailCard data={item} /> */}
        {loading && !relData?.length ? (
          <Loading />
        ) : !loading && !relData?.length ? (
          <div>No Member</div>
        ) : (
          <div className="family-wrapper">
            {relData?.length > 0 &&
              relData?.map((item, i) => (
                <div
                  onClick={() => selectedCard(item._id)}
                  className={`single-card ${
                    selectedId === item._id ? "selected" : ""
                  }`}
                  key={i}
                >
                  <FamilyDetailCard data={item} />
                </div>
              ))}
            <button onClick={() => setShow(true)}>+ Add a New Member</button>
          </div>
        )}
        <div className="btn-wrapper">
          <NextBtn onClick={handleClick} />
        </div>
      </div>
      <AddMember show={show} setShow={setShow} parentSetShow={setShow} />
    </div>
  );
};

export default Consultation2;
