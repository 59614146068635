import "bootstrap/dist/css/bootstrap.min.css";
import AlertBox from "Components/Alert/AlertBox";
import AppRoutes from "Routes/App.Routes";
import SampleCollection from "Components/Popup/SampleCollection/SampleCollection";
import { useSelector, useDispatch } from "react-redux";
// import { setAuthenticationModel } from "Redux/Actions/model";
import { setAuthenticationModel } from "./Redux/Actions/model";
import AuthenticationFlows from "Components/Login/AuthenticationFlows";
import ContactUsModel from "Components/Popup/ContactUs/ContactUsModel";
import HomeModel from "Components/Popup/Home/HomeModel";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const dispatch = useDispatch();
  const authModel = useSelector((state) => state?.setAuthModel);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>

      <div className="App">
        <AppRoutes />
        <AlertBox />
        <SampleCollection />
        <AuthenticationFlows
          open={authModel}
          onClose={() => dispatch(setAuthenticationModel(false))}
        />
        <ContactUsModel />
        <HomeModel />
      </div>
    </HelmetProvider>
  );
}

export default App;
