import {
  SET_UPLOAD_PRESCRIPTION,
  AUTHENTICATION_MODEL,
  CONTACT_MODEL,
  HOME_PAGE_MODEL,
} from "../../Redux/action.type";

export const setAuthenticationModel = (data) => {
  return {
    type: AUTHENTICATION_MODEL,
    payload: data,
  };
};

export const setUploadModel = (data) => {
  return {
    type: SET_UPLOAD_PRESCRIPTION,
    payload: data,
  };
};

export const setContactModel = (data) => {
  return {
    type: CONTACT_MODEL,
    payload: data,
  };
};

export const setHomeModel = (data) => {
  return {
    type: HOME_PAGE_MODEL,
    payload: data,
  };
};
