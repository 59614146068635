import React from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
const Form = () => {
   const inputString =
     "18076099|20230823110159|608|800|N|a07SJLjN8yWntDCpgyQIBxnLH7JaVq21";

     const sha1Hash = CryptoJS.SHA1(inputString).toString(CryptoJS.enc.Hex);
   
     console.log('SHA-1 Hash:', sha1Hash);

// const signingData = "18076099|20230823110159|608|800|N|a07SJLjN8yWntDCpgyQIBxnLH7JaVq21";
// const secureHashSecret = "a07SJLjN8yWntDCpgyQIBxnLH7JaVq21";

// const generateSecureHash = (data, secret) => {
//   const hash = CryptoJS.HmacSHA256(data, secret);
//   const hashInHex = hash.toString(CryptoJS.enc.Hex);
//   return hashInHex;
// };

// const secureHash = generateSecureHash(signingData, secureHashSecret);
  return (
    <div>
      <form
        name="payFormCcard"
        method="post"
        action="https://test.pesopay.com/b2cDemo/eng/payment/payForm.jsp"
      >
        <table>
          <tr>
            <td align="center" colspan="2">
              Required Parameter ( with UTF-8 Encoding ) for connect to our
              payment page
            </td>
          </tr>
          <tr>
            <td align="right">merchantId:</td>
            <td align="left">
              <input type="text" name="merchantId" value="18076099" />{" "}
            </td>
          </tr>
          <tr>
            <td align="right">amount:</td>
            <td align="left">
              <input type="text" name="amount" value="800" />
            </td>
          </tr>
          <tr>
            <td align="right">orderRef:</td>
            <td align="left">
              <input type="text" name="orderRef" value="20230823110159" />
            </td>
          </tr>
          <tr>
            <td align="right">currCode:</td>
            <td align="left">
              <input type="text" name="currCode" value="608" />
            </td>
          </tr>
          <tr>
            <td align="right">successUrl:</td>
            <td align="left">
              <input
                type="text"
                name="successUrl"
                value="https://stackoverflow.com/questions/71885184/render-docx-in-react-js"
              />
            </td>
          </tr>
          <tr>
            <td align="right">failUrl:</td>
            <td align="left">
              <input type="text" name="failUrl" value="https://www.yahoo.in/" />
            </td>
          </tr>
          <tr>
            <td align="right">cancelUrl:</td>
            <td align="left">
              <input
                type="text"
                name="cancelUrl"
                value="https://www.facebook.com/"
              />
            </td>
          </tr>
          <tr>
            <td align="right">payType:</td>
            <td align="left">
              <input type="text" name="payType" value="N" />
            </td>
          </tr>
          <tr>
            <td align="right">lang:</td>
            <td align="left">
              <input type="text" name="lang" value="E" />
            </td>
          </tr>
          <tr>
            <td align="right">mpsMode:</td>
            <td align="left">
              <input type="text" name="mpsMode" value="NIL" />
            </td>
          </tr>
          <tr>
            <td align="right">payMethod:</td>
            <td align="left">
              <input type="text" name="payMethod" value="ALL" />
            </td>
          </tr>
          <tr>
            <td align="center" colspan="2">
              Optional Parameter for connect to our payment page
            </td>
          </tr>

          <tr>
            <td align="right">secureHash:</td>
            <td align="left">
              <input type="text" name="secureHash" value={sha1Hash} />
            </td>
          </tr>
          <tr>
            <td align="right">remark:</td>
            <td align="left">
              <input type="text" name="remark" value="" />
            </td>
          </tr>
          <tr>
            <td align="right">redirect:</td>
            <td align="left">
              <input type="text" name="redirect" value="" />
            </td>
          </tr>
          <tr>
            <td align="right">oriCountry:</td>
            <td align="left">
              <input type="text" name="oriCountry" value="" />
            </td>
          </tr>
          <tr>
            <td align="right">destCountry:</td>
            <td align="left">
              <input type="text" name="destCountry" value="" />
            </td>
          </tr>

          <tr>
            <td colspan="2" align="center">
              <input type="submit" name="submit" />
            </td>
          </tr>
        </table>
      </form>
    </div>
  );
};

export default Form;
// 56100908|1280204670187|344|10|N|gMAVIEGVpqHvxoNEqbrZRuBDFT1B0icW
