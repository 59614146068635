import React, { useEffect } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { useFetcher, useNavigate } from "react-router-dom";
import moment from "moment";
import BackButton from "Components/BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";

const ThankYou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refNo = useSelector((state) => state.setRefNo);
  const orderId = useSelector((state) => state.setOrderId);
  const user = useSelector((state) => state?.setUserDataReducer);

  const testList = useSelector((state) => state.setCartDataReducer?.tests);

  const token = localStorage.getItem("access_token");
  const tAmount = useSelector((state) => state.setCartTotalAmount);

  const inhouse = testList?.inhouse ? testList?.inhouse : [];
  const outsource = testList?.outsource ? testList?.outsource : [];

  let outSorcetotalData = outsource.reduce((accu, curr) => {
    return accu + curr.mrp;
  }, 0);

  let inhousetotalData = inhouse.reduce((accu, curr) => {
    return accu + curr.mrp;
  }, 0);

  const savePaymentData = async () => {
    try {
      const response = await httpPathName.post(
        "payment/save-payment-details",
        {
          orderId: orderId,
          amount: inhousetotalData + outSorcetotalData,
          status: "Success",
          transactionId: refNo,
          payMethod: "Visa",
        },
        { headers: { "x-auth-token": token } }
      );
      if ([200, 201].includes(response?.status)) {
        dispatch(setAlert("success", response?.response?.data?.message));
      } else {
        dispatch(
          setAlert(
            "error",
            response?.response?.data?.message || "An error occurred"
          )
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        setAlert("error", err?.response?.data?.message || "An error occurred")
      );
    }
  };

  useEffect(() => {
    savePaymentData();
  }, []);

  return (
    <>
      <Header />
      <div className="backbtn">
        <BackButton />
      </div>

      <div className="thankyou">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
          >
            <circle cx="50" cy="50" r="50" fill="#A71F23" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M6.66797 21.0187L14.8731 29.1668L33.3346 10.8335"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h1>THANK YOU!</h1>
        <p>
          Your payment has been done ! Please visit our lab at scheduled time{" "}
        </p>
        <p>Trans ID : {refNo}</p>
        <p>Order ID : {orderId}</p>
        <p style={{ fontWeight: "600" }}>Your Check up Details </p>
        <div className="innerbox">
          <p>
            Patient Name:{" "}
            <span style={{ fontWeight: "500" }}>{user?.name}</span>
          </p>
          <div>
            Timings: {moment().format("HH:mm:ss A")} Date:{" "}
            {moment().format("DD/MM/YYYY")}
          </div>
        </div>
        <button onClick={() => navigate("/")}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M2.31 11.7098C2.34795 11.6206 2.40221 11.5392 2.47 11.4698L5.47 8.46985C5.61217 8.33737 5.80022 8.26524 5.99452 8.26867C6.18882 8.2721 6.37421 8.35081 6.51162 8.48823C6.64903 8.62564 6.72775 8.81102 6.73117 9.00532C6.7346 9.19963 6.66248 9.38767 6.53 9.52985L4.81 11.2498H21C21.1989 11.2498 21.3897 11.3289 21.5303 11.4695C21.671 11.6102 21.75 11.8009 21.75 11.9998C21.75 12.1988 21.671 12.3895 21.5303 12.5302C21.3897 12.6708 21.1989 12.7498 21 12.7498H4.81L6.53 14.4698C6.67045 14.6105 6.74934 14.8011 6.74934 14.9998C6.74934 15.1986 6.67045 15.3892 6.53 15.5298C6.38938 15.6703 6.19875 15.7492 6 15.7492C5.80125 15.7492 5.61062 15.6703 5.47 15.5298L2.47 12.5298C2.40221 12.4605 2.34795 12.3791 2.31 12.2898C2.27037 12.1983 2.24993 12.0996 2.24993 11.9998C2.24993 11.9001 2.27037 11.8014 2.31 11.7098Z"
              fill="#5E5E5E"
            />
          </svg>
          Back To Home
        </button>
      </div>
      <Footer />
    </>
  );
};

export default ThankYou;
