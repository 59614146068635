import axios from "axios";

export default function isValidEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const setAuthTokenInDefaults = token => {
    if (token) {
        axios.defaults.headers["X-Auth-Token"] = token;
        axios.defaults.headers["content-type"] = "application/json";
    }
    else
        delete axios.defaults.headers["X-Auth-Token"];
        delete axios.defaults.headers["content-type"];
}