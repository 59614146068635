import { Card, Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import "./Services.css";
import { ReactComponent as SearchIcon } from "Assests/Icons/searchIcon.svg";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import { ReactComponent as FilterIcon } from "Assests/Icons/filterIcon.svg";
import OldManCheckup from "Assests/Images/OldManCheckup.png";
import RightArrow from "Assests/Icons/RightArrow.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { bookNowApi } from "API/post.api";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch, useSelector } from "react-redux";
import httpPathName from "Global/Config/Config";
import { setTestData } from "../../Redux/Actions/set.action";
import { setFilterModel } from "../../Redux/Actions/service.action";
import Spinner from "react-bootstrap/Spinner";
import { useSearchParams } from "react-router-dom";
import { setAuthenticationModel } from "../../Redux/Actions/model";
import { Offcanvas, Tab } from "react-bootstrap";
import FILTER_DATA from "Global/Constants/Constants";

const navKeys = {
  ALL: "all",
  INDIVIDUAL: "Individual",
  PACKAGE: "Package",
};

export default function CardsView(props) {
  const filterModel = useSelector((state) => state.setFilterState);
  const [activeNavKey, setActiveNavKey] = useState(navKeys.ALL);
  const [mobileNavKey, setMobileNavKey] = useState("");
  const [searchText, setSearchText] = useState(filterModel?.search || "");
  const [mobileFilter, setMobileFilter] = useState(false);

  const token = localStorage.getItem("access_token");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const category = searchParams.get("category");

  useEffect(() => {
    if (!!search) {
      setSearchText(search);
    }

    if (!!category) {
      setActiveNavKey(category);
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testData = useSelector((state) => state.setTestData);

  let debounceTimer;
  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  // const filterModel = useSelector((state) => state.setFilterState);

  const getData = (filter) => {
    props.setLoading(true);
    httpPathName
      .post("test/getAllTests", filter ?? filterModel, {
        headers: {
          "X-Auth-Token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        props.setLoading(false);
        if (Boolean(res.data.data)) {
          dispatch(setTestData(res.data.data));
        }
      })
      .catch((err) => {
        props.setLoading(false);
        console.log("ERROR", err);
        dispatch(setAlert("danger", err?.response?.data?.message));
      });
  };

  useEffect(() => {
    debounce(() => getData(), 500);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [activeNavKey, searchText]);

  const changeActiveNav = (val) => {
    let filter = { ...filterModel };

    let filterModelNew = {
      ...filter,
      category: val,
    };

    dispatch(setFilterModel(filterModelNew));
    setActiveNavKey(val);
  };

  const onSearch = (e) => {
    let filter = { ...filterModel };

    let filterModelNew = {
      ...filter,
      search: e.target.value,
    };

    dispatch(setFilterModel(filterModelNew));

    setSearchText(e.target.value);
  };

  const openFilterPopup = () => {
    setMobileFilter(true);
  };

  const closeMobileFilter = () => {
    setMobileFilter(false);
  };

  const onChangeFilter = (event, key, value) => {
    let filter = { ...filterModel };

    if (Boolean(event.target.checked)) {
      if (!filter[key]?.includes(value)) {
        let filterModelNew = {
          ...filter,
          [key]: [...filter[key], value],
        };
        dispatch(setFilterModel(filterModelNew));

        debounce(() => getData(filterModelNew), 200);
      }
    } else {
      let filterModelNew = {
        ...filter,
        [key]: [...filter[key]?.filter((el) => el !== value)],
      };
      dispatch(setFilterModel(filterModelNew));

      debounce(() => getData(filterModelNew), 200);
    }
  };

  // console.log(testData);

  return (
    <div className="testsContainer">
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-1 mb-5">
        <Form noValidate className="hide-mobile">
          <Form.Group controlId="validationCustom01">
            <InputGroup>
              <InputGroup.Text className="inputIcon">
                <SearchIcon />
              </InputGroup.Text>
              <Form.Control
                className="inputStyle"
                required
                name="mobile"
                type="text"
                placeholder="Search test or Health packages"
                autoComplete="off"
                value={searchText}
                onChange={(e) => {
                  onSearch(e);
                }}
              />
              <button className="red-button">
                <img src={RightArrow} alt="RightArrow" />
              </button>
            </InputGroup>
          </Form.Group>
        </Form>
        <div>
          <Nav
            variant="pills"
            defaultActiveKey={navKeys.ALL}
            activeKey={activeNavKey}
            onSelect={(val) => changeActiveNav(val)}
            className="navContainer"
          >
            <Nav.Item>
              <Nav.Link
                eventKey="all"
                className={`navItem ${
                  activeNavKey === navKeys.ALL && "navItemActive"
                }`}
              >
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="Individual"
                className={`navItem ${
                  activeNavKey === navKeys.INDIVIDUAL && "navItemActive"
                }`}
              >
                Individual Tests
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="Package"
                className={`navItem ${
                  activeNavKey === navKeys.PACKAGE && "navItemActive"
                }`}
              >
                Packages
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-2">
        <div
          className="d-flex align-items-center filterButton"
          onClick={openFilterPopup}
        >
          Filter by <FilterIcon style={{ marginLeft: "4px" }} />
        </div>
        <Offcanvas
          placement="bottom"
          show={mobileFilter}
          onHide={closeMobileFilter}
        >
          <Offcanvas.Header>
            <div className="d-flex justify-content-center">
              <span className="dragIcon" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col
                  className="navContainerMobile"
                  style={{ maxWidth: "30vw" }}
                >
                  <Nav
                    variant="pills"
                    className="flex-column"
                    defaultActiveKey={"organs"}
                    activeKey={activeNavKey}
                    onSelect={(val) => setMobileNavKey(val)}
                  >
                    <Nav.Item>
                      <Nav.Link
                        style={{
                          fontSize: "3vw",
                          color:
                            mobileNavKey === "organs" ? "#A71F23" : "#2A364E",
                          backgroundColor:
                            mobileNavKey !== "organs" ? "#FFF" : "#F4F4F4",
                        }}
                        eventKey="organs"
                      >
                        By organs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{
                          fontSize: "3vw",
                          color:
                            mobileNavKey === "condition"
                              ? "#A71F23"
                              : "#2A364E",
                          backgroundColor:
                            mobileNavKey === "condition" ? "#FFF" : "#F4F4F4",
                        }}
                        eventKey="condition"
                      >
                        Test by health condition
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{
                          fontSize: "3vw",
                          color:
                            mobileNavKey === "gender" ? "#A71F23" : "#2A364E",
                          backgroundColor:
                            mobileNavKey === "gender" ? "#FFF" : "#F4F4F4",
                        }}
                        eventKey="gender"
                      >
                        Test by Gender
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="organs">
                      <Form className="mb-3">
                        {FILTER_DATA["organs"].map((e) => {
                          return (
                            <div className="flexRowAlign mb-3" key={e.key}>
                              <div>
                                <Form.Check
                                  id={`checkbox-${e.key}`}
                                  className="checkboxInput"
                                  checked={
                                    filterModel &&
                                    filterModel["organs"]?.includes(e.title)
                                  }
                                  onChange={(event) =>
                                    onChangeFilter(event, "organs", e.title)
                                  }
                                />
                              </div>
                              <div className="filterIcon">{e.logo}</div>
                              <span className="filterTitle">{e.title}</span>
                            </div>
                          );
                        })}
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="condition">
                      {FILTER_DATA["condition"].map((e) => {
                        return (
                          <div className="flexRowAlign mb-3" key={e.key}>
                            <div>
                              <Form.Check
                                id={`checkbox-${e.key}`}
                                className="checkboxInput"
                                checked={filterModel["condition"]?.includes(
                                  e.title
                                )}
                                onChange={(event) =>
                                  onChangeFilter(event, "condition", e.title)
                                }
                              />
                            </div>
                            <div className="filterIcon">{e.logo}</div>
                            <span className="filterTitle">{e.title}</span>
                          </div>
                        );
                      })}
                    </Tab.Pane>
                    <Tab.Pane eventKey="gender">
                      {FILTER_DATA["gender"].map((e) => {
                        return (
                          <div className="flexRowAlign mb-3" key={e.key}>
                            <div>
                              <Form.Check
                                id={`checkbox-${e.key}`}
                                className="checkboxInput"
                                checked={filterModel["gender"]?.includes(
                                  e.title
                                )}
                                onChange={(event) =>
                                  onChangeFilter(event, "gender", e.title)
                                }
                              />
                            </div>
                            <div className="filterIcon">{e.logo}</div>
                            <span className="filterTitle">{e.title}</span>
                          </div>
                        );
                      })}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className="cardContainer">
        {!!props.loading && (
          <div style={{ margin: "auto" }}>
            <Spinner />
          </div>
        )}
        <div className="cardWrapper">
          {!props.loading &&
            (!!testData && testData?.length > 0 ? (
              testData.map((test) => {
                return (
                  <Card
                    key={test?._id}
                    className="cardStyle"
                    onClick={() =>
                      navigate(
                        `/services/${encodeURIComponent(test.test_name)}`,
                        {
                          state: { id: test?._id },
                        }
                      )
                    }
                  >
                    <Card.Img
                      variant="top"
                      className="cardImg"
                      src={test.image}
                    />
                    <Card.Body className="cardBody">
                      <Card.Title className="mb-1">{test.test_name}</Card.Title>
                      {/* <Card.Text className="mb-1 cardDesc2line">{test.intented_use}</Card.Text> */}
                      <div className="flexRowAlign mb-1">
                        <span>
                          <div className="price">₱{test.mrp}</div>
                          {/* <div className="retailPrice">₱3400.00</div> */}
                        </span>
                        <span style={{ flexGrow: 1 }} />
                        <span>
                          {/* <div style={{ color: "#5E5E5E" }}>
                          Include <span style={{ color: "#2A364E" }}>7</span>
                        </div> */}
                          {test?.parameter && (
                            <div className="param" style={{ color: "#5E5E5E" }}>
                              Parameter{" "}
                              <span style={{ color: "#2A364E" }}>
                                {test.parameter}
                              </span>
                            </div>
                          )}
                        </span>
                      </div>
                      <button
                        className="book-btn flexRowAlignJustify"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!token) {
                            // Replace False with true to Enable Auth Modal
                            dispatch(setAuthenticationModel(true));
                          } else {
                            const apiStatus = bookNowApi(token, test?._id);
                            apiStatus
                              .then((response) => {
                                console.log(response);
                                navigate("/cart");
                              })
                              .catch((err) => {
                                console.log(err);
                                dispatch(
                                  setAlert("error", err?.response?.data?.error)
                                );
                              });
                          }
                        }}
                      >
                        <CartIcon style={{ marginRight: "8px" }} /> Book Now
                      </button>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <div className="no-data-found">No Data Found!</div>
            ))}
        </div>
      </div>
    </div>
  );
}
