import React, { useEffect, useState } from "react";
import "./BookAppointment.css";
import Calendar from "moedim";
import moment from "moment";
import { fetchSlotforDoctorAppointment } from "API/get.api";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "Components/Common/Loading/Loading";
import { setConsultData } from "../../../Redux/Actions/set.action";

const SceduleSlot = ({ id }) => {
  const [active, setActive] = useState("Book Hospital Visit");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlotData, setTimeSlotData] = useState([]);
  const [completeSlotData, setCompleteSlotData] = useState({});
  const [selectedId, setSelectedId] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.setUserDataReducer);

  const tablist = ["Book Hospital Visit", "Book Video Consult"];
  const slotTypeData = [
    {
      title: "Not Available",
      background: "#D9D9D9",
    },
    {
      title: "Almost Filled",
      background: "#F1BA6A",
    },
    {
      title: "Fast Filling",
      background: "#A61F23",
    },
    {
      title: "Available",
      background: "#FFF",
      border: "1px solid",
    },
    {
      title: "Selected",
      background: "#3AA1ED",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      const apiStatus = fetchSlotforDoctorAppointment(
        token,
        id,
        moment(selectedDate).format("DD-MM-YYYY")
      );
      apiStatus
        .then((response) => {
          if (response?.status === 200) {
            setTimeSlotData(response.data.slots[0].timeSlots);
            setCompleteSlotData(response.data?.slots[0]);
            // dispatch(setAlert("success", response?.data?.message));
          } else {
            setTimeSlotData([]);
            dispatch(setAlert("error", response?.response?.data?.message));
          }
        })
        .catch((err) => {
          console.log(err);
          setTimeSlotData([]);
          dispatch(setAlert("error", err?.response?.data?.message));
        })
        .finally(() => {
          setLoading(false);
        });
    }, 2000);

    return () => clearTimeout(debounce);
    // eslint-disable-next-line
  }, [selectedDate]);

  const handleTabClick = (tab) => {
    setActive(tab);
  };
  const changeDate = (e) => {
    setSelectedDate(e);
  };
  const bookAppointmentFn = () => {
    if (!completeSlotData._id) {
      dispatch(setAlert("error", "Please Select Slot Id"));
      return;
    } else if (!selectedId.id) {
      dispatch(setAlert("error", "Please Select Time"));
      return;
    } else if (!user?._id) {
      dispatch(setAlert("error", "User Id Not Found"));
      return;
    }
    dispatch(
      setConsultData({
        slot: completeSlotData._id,
        time: selectedId.id,
        date: moment(selectedDate).format("DD-MM-YYYY"),
        timeStamp: selectedId.time,
        modeOfConsultation: active,
        userId: user?._id,
        relativeId: "",
      })
    );
    navigate("appointment-details");
  };

  return (
    <div className="doctor-schedule-slot">
      <h2>Schedule Appointment</h2>
      <div className="wrapper">
        <div className="calender-section">
          <div className="tab-wrapper">
            {tablist.length > 0 &&
              tablist.map((tab, i) => (
                <span
                  className={`${active === tab ? "active" : ""}`}
                  key={i}
                  //   onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </span>
              ))}
          </div>
          <div className="calender-wrapper">
            <Calendar value={selectedDate} onChange={changeDate} />
          </div>
        </div>
        <div className="slot-section">
          <h4>
            Slots on <span>{moment(selectedDate).format("DD-MMMM-YYYY")}</span>
          </h4>
          {loading && !timeSlotData?.length ? (
            <Loading />
          ) : !loading && timeSlotData?.length ? (
            <div className="slot-wrapper">
              {timeSlotData?.length > 0 &&
                timeSlotData?.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setSelectedId({
                        time: `${item?.startTime} To ${item?.endTime}`,
                        id: item._id,
                      });
                    }}
                    className={`${
                      item.status == "Almost Filled" &&
                      item._id != selectedId.id
                        ? "almost-filled"
                        : item.status == "Fast Filling"
                        ? "fast-filling"
                        : item.status == "Not Available"
                        ? "not-available"
                        : item._id == selectedId.id
                        ? "selected"
                        : ""
                    }`}
                  >
                    {`${item?.startTime} To ${item?.endTime}`}
                  </div>
                ))}
            </div>
          ) : (
            <div className="no-data-found ">NO Slots Found</div>
          )}

          <div className="slot-type">
            {slotTypeData.map((s, i) => (
              <div className="slot-type-data" key={i}>
                <span
                  style={{
                    background: s.background,
                    border: s.border ? s.border : "",
                  }}
                ></span>
                <p>{s.title}</p>
              </div>
            ))}
          </div>
          {selectedId.id && (
            <button onClick={bookAppointmentFn}>Book Appointment</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SceduleSlot;
