// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimers .disclaimerWrapper {
  max-width: 78vw;
  margin: auto;
  padding-top: 2%;
  padding-bottom: 3%;
}

.disclaimers .disclaimerWrapper p {
  color: #5e5e5e;
  text-align: justify;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Disclaimers/Disclaimers.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".disclaimers .disclaimerWrapper {\n  max-width: 78vw;\n  margin: auto;\n  padding-top: 2%;\n  padding-bottom: 3%;\n}\n\n.disclaimers .disclaimerWrapper p {\n  color: #5e5e5e;\n  text-align: justify;\n  font-family: Poppins;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
