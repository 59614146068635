import React from 'react'

const SettingPhone = () => {
    return (
        <div className='settingPhone'>
            <h1>Delete Account</h1>
            <p>Would you like delete your account, once
                deleted account will not be reset. </p>
            <button>Delete Account</button>
        </div>
    )
}

export default SettingPhone