import React, { useRef, useState } from "react";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useNavigate } from "react-router-dom";
import leftArrow from "Assests/Icons/arrow-narrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { setHmoStep3Action } from "../../Redux/Actions/hmo.action";

const Step3 = () => {
  const step3Data = useSelector((state) => state.setHmoStepReducer.step3);
  // const [file, setFile] = useState(null)
  const [formData, setFormData] = useState({
    hmoCard: step3Data?.hmoCard,
    policyNumber: step3Data?.policyNumber,
    companyName: step3Data?.companyName,
    file: step3Data?.file,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();

  const uploadFile = () => {
    fileRef.current.click();
  };
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (isValidFileUploaded(file)) {
      setFormData({ ...formData, file: e.target.files[0] });
      //setTempFile(e.target.files[0])
    } else if (file.size > 1.5e7) {
      dispatch(setAlert("error", "Please Upload File less than 15 Mb"));
      //file is invalid
    } else {
      dispatch(setAlert("error", "Please Upload File type .jpg or .png only"));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //formData.file = file
    if (formData && Object.values(formData).includes(undefined)) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      dispatch(setHmoStep3Action(formData));
      navigate("/HMOs/fill-out-doctors-details");
    }
  };

  return (
    <div className="hmo-step-1">
      <button onClick={() => navigate(-1)} className="backbtn">
        <img src={leftArrow} alt="leftarrow" />
      </button>

      <div className="dot-wrapper">
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Make an Appointment
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line active"></div>

        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Fill Out Patient Details
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line"></div>

        <div className="dot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Fill out HMO Details.
            <br />
            <small className="fw-semibold" style={{ color: "#F1BA6A" }}>
              Pending
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Fill Out Doctor’s Details
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Submit the request for approval.
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
      </div>

      <div className="phoneStepper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <p className="patientDetails" style={{ marginBottom: 0 }}>
            Fill Out Patient Details
          </p>
          <div className="steps">
            Step <span style={{ color: "#F1BA6A" }}>3</span>/5
          </div>
        </div>
        <span className="status">Progress</span>
      </div>

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="stepData"
      >
        <div className="input-wrapper">
          <div>
            <p>HMO Card *</p>
            <input
              name="hmoCard"
              value={formData?.hmoCard}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Enter Card"
            />
          </div>
          <div>
            <p>HMO ID/Policy Number *</p>
            <input
              name="policyNumber"
              value={formData?.policyNumber}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="ID/Policy Number"
            />
          </div>
          <div>
            <p>Company Name *</p>
            <input
              name="companyName"
              value={formData?.companyName}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Enter Company Name"
            />
          </div>

          <div className="uploadImageDoc">
            <p>Please Upload Image</p>
            <input
              hidden
              ref={fileRef}
              onChange={fileChange}
              accept="image/png, image/gif, image/jpeg"
              type="file"
              name="file"
              placeholder="Image"
            />
            <label>
              {formData?.file?.name ? (
                <strong>{formData?.file?.name}</strong>
              ) : (
                "Image"
              )}
            </label>
            <div onClick={uploadFile} className="uploadFile">
              Upload
            </div>
          </div>
        </div>

        <div className="nextbtnWrapper">
          <button
            type="submit"
            // onClick={() => navigate("/HMOs/step4")}
            className="nextBtn"
          >
            Next
            <span className="rightArrowWrapper">
              <img src={rightArrow} alt="rightarow" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step3;
