// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-list {
  width: 100%;
}

.delete-list .modal-body {
  width: 100%;
}
.delete-list .modal-content {
  width: 100%;
}

.delete-list .modal-content .delWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-list .modal-content div .no {
  border-radius: 5px;
  background: #3aa1ed;
  box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);
  color: var(--white, #fff);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 100%;
  padding: 1%;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
}

.delete-list .modal-content div .yes {
    border-radius: 5px;
    background: #3aa1ed;
    box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);
    color: var(--white, #fff);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 100%;
    padding: 1%;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
  }
  
`, "",{"version":3,"sources":["webpack://./src/Components/Popup/Delete/Delete.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iDAAiD;EACjD,yBAAyB;EACzB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iDAAiD;IACjD,yBAAyB;IACzB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;EACpB","sourcesContent":[".delete-list {\n  width: 100%;\n}\n\n.delete-list .modal-body {\n  width: 100%;\n}\n.delete-list .modal-content {\n  width: 100%;\n}\n\n.delete-list .modal-content .delWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.delete-list .modal-content div .no {\n  border-radius: 5px;\n  background: #3aa1ed;\n  box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);\n  color: var(--white, #fff);\n  font-family: Poppins;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 120%;\n  width: 100%;\n  padding: 1%;\n  border: none;\n  width: fit-content;\n}\n\n.delete-list .modal-content div .yes {\n    border-radius: 5px;\n    background: #3aa1ed;\n    box-shadow: 8px 12px 32px 0px rgba(2, 2, 2, 0.08);\n    color: var(--white, #fff);\n    font-family: Poppins;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n    width: 100%;\n    padding: 1%;\n    border: none;\n    width: fit-content;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
