import React, { useEffect } from "react";
import labs from "Assests/Images/labs.png";
import dot3 from "Assests/Images/dot3.svg";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "./Terms.css";
import { useNavigate } from "react-router-dom";
import clientWanner from "Assests/Images/clientWanner.png";
import Wanner from "Components/Wnner/Wanner";

const Terms = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  const scrollToSection = (typeId) => {
    const targetSectionContent = document.getElementById(typeId);
    let position = targetSectionContent.getBoundingClientRect();
    // scrolls to 20px above element
    window.scrollTo(position.left, position.top + window.scrollY - 200);

    // if (targetSectionContent) {
    //   targetSectionContent.scrollIntoView({ behavior: "smooth" });
    // }
  };

  return (
    <>
      <Header />
      <div className="terms">
        <Wanner title="Terms & Conditions" image={clientWanner} />

        <div className="termsWrapper">
          <div className="contentWrapper">
            <div className="left-section">
              <p>
                Welcome to www.[·] (the "Site"). Please read these terms and
                conditions carefully before using this Site. By accessing or
                using the Site, you agree to be bound by these terms and
                conditions. The provision of information and services on this
                Site by the owners and operators of the Site and their suppliers
                is subject to your agreement to the terms and conditions below.
              </p>
              <h1 id="term">1. Scope of These Terms</h1>
              <p>
                These terms of use apply to your use of our www. .[·] Web site
                (the “Site”). The Site is an internet-based portal operated by
                Nederlands Diagnostics Private Inc., a privately held company
                duly incorporated in the Philippines (hereinafter referred to as
                “Nederlands” or “We” or “Our” or “Us” or “Company”)
              </p>

              <h1 id="disclaimer">2. Disclaimer of Content</h1>
              <p>
                This site provides information about our company and its wholly
                owned subsidiaries, and the products and services offered by
                each of them, including diagnostic health testing and services,
                data analysis, clinical trial capabilities, and information
                about various health conditions.
              </p>
              <p>
                If you are a patient or healthcare consumer, you should not use
                the information found on this Site to replace a relationship
                with your physician or other healthcare professional and should
                not rely on that information as professional medical advice.
                Always seek the advice of your physician or other qualified
                healthcare provider concerning questions you have regarding a
                medical condition, and before starting, stopping or modifying
                any treatment or medication. In the case of a health emergency,
                seek immediate assistance from emergency personnel. Never delay
                obtaining medical advice or disregard medical advice because of
                something you have or have not read on this site.
              </p>
              <p>
                The information (including, without limitation, advice and
                recommendations) and services on this Site are intended solely
                as a general educational aid and are neither medical nor health
                care advice for any individual problem nor a substitute for
                medical or other professional advice and services from a
                qualified health care provider familiar with your medical
                history. Use of this Site does not create a doctor-patient
                relationship. Nothing contained in this Site is intended to be
                used for medical diagnosis or treatment.
              </p>
              <p>
                While we attempt to keep all the information on the Site
                up-to-date, medical treatment and knowledge change quickly, and
                this Site should not be considered error-free or a comprehensive
                source of all information on a particular topic. We make no
                warranties or representations as to the accuracy of the content
                of the Site and assume no responsibility for any consequences
                relating directly or indirectly to any action or inaction you
                take based upon the information and material on this Site. Your
                use of the Site is subject to the additional disclaimers and
                caveats that may appear throughout these terms and conditions
                and the Site. You assume the entire risk of loss in using this
                Site and materials contained in the Site.
              </p>
              <p>
                Features and specifications of products or services described or
                depicted on the Site are subject to change at any time without
                notice.
              </p>
              <h1 id="copyRight">
                3. Copyright, Trademarks and other Intellectual Property
              </h1>
              <p>
                Copyright, trademark, patent, and other applicable laws protect
                the services and materials appearing on the Site. All materials
                on the Site may be accessed, downloaded or printed for the
                non-commercial purpose of scientific or educational advancement
                or within the scope allowable by these terms and conditions. No
                other use of these materials may be made without express written
                permission from us. Any unauthorized use of the words or images
                on this Site may violate copyright laws, trademark laws, the
                laws of privacy and publicity, and civil and criminal statutes.
              </p>

              <p>
                The Site includes material that is derived in whole or in part
                from materials that are copyrighted, including the format and
                layout of the Site. The copyrights are owned by Nederlands
                Diagnostics Private Inc., or for licensed content, the content
                providers.
              </p>
              <p>
                None of the names, trademarks, service marks and logos appearing
                on this Site may be used in any advertising or publicity, or
                otherwise to indicate our sponsorship of or affiliation with any
                product or service without express written permission from us.
                Nothing contained on the Site should be construed as granting,
                by implication, estoppel, waiver or otherwise, any license or
                right of use to any trademark displayed on the Site without the
                written permission of the owners and operators of the Site or
                the third party owner of the trademark, if any. This Site may
                contain other proprietary notices and copyright information, the
                terms of which must be observed and followed.
              </p>
              <h1 id="rightChange">4. Right to Change Terms and Conditions</h1>
              <p>
                We may, at any time and from time to time, change these terms
                and conditions. Any changes to these terms and conditions will
                be effective immediately upon posting of the changed terms and
                conditions on the Site. You agree to review these terms and
                conditions periodically, and use of the Site following any such
                change constitutes your agreement to follow and be bound by the
                terms and conditions as changed.
              </p>

              <h1 id="linkto-other">5. Links to Other Sites</h1>
              <p>
                This Site may contain third-party-owned content (e.g., articles,
                data feeds, abstracts, etc.) and may also include hypertext
                links to third-party-owned websites. We provide such third-party
                links as a courtesy to our users. We have no control over any
                third-party-owned websites or content referenced, accessed by,
                or available on this Site and, therefore, we do not endorse,
                sponsor, recommend or otherwise accept any responsibility for
                such third-party websites or content or for the availability of
                such web sites. IN PARTICULAR, WE DO NOT ACCEPT ANY LIABILITY
                ARISING OUT OF ANY ALLEGATION THAT ANY THIRD-PARTY-OWNED CONTENT
                (WHETHER PUBLISHED ON THIS, OR ANY OTHER, WEBSITE) INFRINGES THE
                INTELLECTUAL PROPERTY RIGHTS OF ANY PERSON OR ANY LIABILITY
                ARISING OUT OF ANY INFORMATION OR OPINION CONTAINED ON SUCH
                THIRD PARTY WEB SITE OR CONTENT. If you link to third-party
                sites from our Site, we encourage you to consult the policy
                statements of each site before you visit.
              </p>

              <h1 id="advertising">6. Advertising and Sponsorships</h1>
              <p>
                We do not accept paid advertisements or sponsorships on this
                Site.
              </p>

              <h1 id="warranty">
                7. Disclaimer of Warranty; Limitation of Liability
              </h1>
              <p>
                We DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES WITH REGARD TO
                THE INFORMATION, SERVICES AND MATERIALS CONTAINED ON THIS SITE,
                INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT. YOUR USE OF THIS SITE IS AT YOUR OWN RISK.
                ACCESS TO THIS SITE MAY BE INTERRUPTED AND INFORMATION, SERVICES
                AND MATERIALS MAY NOT BE ERROR-FREE. WE AND ANYONE ELSE INVOLVED
                IN CREATING, PRODUCING OR DELIVERING THIS SITE OR THE
                INFORMATION, SERVICES AND MATERIALS CONTAINED HEREIN ASSUMES NO
                LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS OR
                USEFULNESS OF ANY INFORMATION, SERVICES AND MATERIALS PROVIDED
                ON THIS SITE; THEY ALSO SHALL NOT BE LIABLE FOR ANY DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THIS SITE. ALL
                INFORMATION, SERVICES AND MATERIALS ARE PROVIDED "AS IS" AND "AS
                AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
              </p>
              <p>
                YOU ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS SET FORTH ABOVE
                ARE FUNDAMENTAL ELEMENTS OF THIS AGREEMENT AND THIS SITE WOULD
                NOT BE PROVIDED TO YOU ABSENT SUCH LIMITATIONS.
              </p>
              <p>
                PLEASE NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION
                OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT
                APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR
                LIMITATIONS REGARDING THE EXCLUSION OF IMPLIED WARRANTIES.
              </p>
              <h1 id="privacy">8. Privacy Policy</h1>
              <p>
                We will treat all health-related information submitted by you as
                confidential, customers or other visitors to the Site, in
                accordance with Our ' Privacy Policy. The purpose of our privacy
                policy is to identify the information we collect online, the
                steps we take to protect it and your choices regarding how that
                information is used.
              </p>

              <h1 id="indemnification">9. Indemnification</h1>
              <p>
                You agree to indemnify, defend and hold harmless Us and the
                respective affiliates, employees, officers, directors, agents,
                servants and representatives of each from any liability, loss,
                claim, suit, damage, and expense (including reasonable
                attorneys´ fees and expenses) related to (i) your violation of
                these terms and conditions and (ii) your posting of material to
                this Site.
              </p>
              <h1 id="applicable">10. Applicable Law</h1>
              <p>
                These terms and conditions and the resolution of any dispute
                related to these terms and conditions shall be construed in
                accordance with the laws of the Philippines. Any dispute between
                Us and you related to these terms and conditions shall be
                resolved exclusively by the state and federal courts of the
                State of Quezon.
              </p>
              <h1 id="termination">11. Termination</h1>
              <p>
                These terms and conditions are effective unless and until
                modified as noted above, or terminated, at any time, by Us. If,
                in the sole discretion of Us, you fail to comply with these
                terms and conditions, We may deny you access to the Site.
              </p>
              <h1 id="contact">12. Contact Information</h1>
              <p>We are having our branch office in Quezon, Philippines</p>
              <p>Corporate Headquarters</p>
              <p>NEDERLANDS Diagnostics Private Inc.</p>
              <p>
                MegaOne Bldg. ground floor, Espana Blvd., cor. Mayon St., Sta.
                Teresita, Quezon City{" "}
              </p>
              <p>contact@nederlandsdiagnostics.com</p>
            </div>
            <div className="right-section">
              <p onClick={() => scrollToSection("term")}>
                Scope of These Terms
              </p>
              <p onClick={() => scrollToSection("disclaimer")}>
                Disclaimer of Content
              </p>
              <p onClick={() => scrollToSection("copyRight")}>
                Copyright, Trademarks and other Intellectual Property
              </p>
              <p onClick={() => scrollToSection("rightChange")}>
                Right to Change Terms and Conditions
              </p>
              <p onClick={() => scrollToSection("linkto-other")}>
                Links to Other Sites
              </p>
              <p onClick={() => scrollToSection("advertising")}>
                Advertising and Sponsorships
              </p>
              <p onClick={() => scrollToSection("warranty")}>
                Disclaimer of Warranty; Limitation of Liability
              </p>
              <p onClick={() => scrollToSection("privacy")}>Privacy Policy</p>
              <p onClick={() => scrollToSection("indemnification")}>
                Indemnification
              </p>
              <p onClick={() => scrollToSection("applicable")}>
                Applicable Law
              </p>
              <p onClick={() => scrollToSection("termination")}>Termination</p>
              <p onClick={() => scrollToSection("contact")}>
                Contact Information
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
