import React, { useEffect, useState, useRef } from "react";
import "./Client.css";
import partnerWithUs from "Assests/Images/closeup-ampoule-needle-syringe-document-with-phonendoscope 1.png";
import dot3 from "Assests/Images/dot3.svg";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import httpPathName from "Global/Config/Config";
import { useNavigate } from "react-router-dom";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import clientWanner from "Assests/Images/clientWanner.png";
import Wanner from "Components/Wnner/Wanner";
// import useMetaTags from "Components/Hooks/useMetaTags";

const Client = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expression, setExpression] = useState(generateRandomKeyword());
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({});
  const canvasRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Clear previous content
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Set text properties
    context.font = "30px Arial";
    context.fillStyle = "blue";
    context.textAlign = "center";
    context.textBaseline = "middle";
    // Draw CAPTCHA text
    context.fillText(expression, canvas.width / 2, canvas.height / 2);
  }, [expression]);

  // const onChange = () => {};

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("We are the Most Trusted Partner in Healthcare Industry","Discover why we are the most trusted healthcare partner in the Philippines. Read testimonials from satisfied customers and experience our commitment to excellence firsthand.")

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function generateRandomKeyword() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let keyword = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      keyword += characters.charAt(randomIndex);
      // setExpression(keyword);
    }

    return keyword;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(formData).length < 3) {
      dispatch(setAlert("error", "All fields are required!"));
    } else if (formData?.search !== expression) {
      dispatch(setAlert("error", "Please enter a valid Captcha Code"));
    } else {
      delete formData.search;
      console.log("formdata", formData);
      // const promise = await httpPathName
      //   .put(
      //     `users/update-relation/${data?._id}`,
      //     { ...formData },
      //     { headers: { "x-auth-token": token } }
      //   )
      //   .then((response) => {
      //     dispatch(setAlert("success", response?.data?.message));
      //     setShow(false);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     dispatch(setAlert("error", err?.response?.data?.message));
      //   });
    }
  };

  return (
    <>
      <MetaDetails
        title="We are the Most Trusted Partner in Healthcare Industry"
        description="Discover why we are the most trusted healthcare partner in the Philippines. Read testimonials from satisfied customers and experience our commitment to excellence firsthand."
      />

      <Header />
      <div className="client">
        <Wanner title="Client Portal" image={clientWanner} />

        <div className="clientWrapper">
          <h1>
            Welcome to <span style={{ fontWeight: "500" }}>Client Portal</span>
          </h1>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div>
              <p>Username</p>
              <div className="inputWrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    stroke="#2A364E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                    stroke="#2A364E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  name="userName"
                  type="text"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <p>Password</p>
              <div className="inputWrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.75 8.33V7C16.75 4.38 14.62 2.25 12 2.25C9.38 2.25 7.25 4.38 7.25 7V8.33C5.54 8.68 4.25 10.19 4.25 12V18C4.25 20.07 5.93 21.75 8 21.75H16C18.07 21.75 19.75 20.07 19.75 18V12C19.75 10.19 18.46 8.67 16.75 8.33ZM12 3.75C13.79 3.75 15.25 5.21 15.25 7V8.25H8.75V7C8.75 5.21 10.21 3.75 12 3.75ZM18.25 18C18.25 19.24 17.24 20.25 16 20.25H8C6.76 20.25 5.75 19.24 5.75 18V12C5.75 10.76 6.76 9.75 8 9.75H16C17.24 9.75 18.25 10.76 18.25 12V18Z"
                    fill="#2A364E"
                  />
                  <path
                    d="M12 13.25C11.59 13.25 11.25 13.59 11.25 14V16C11.25 16.41 11.59 16.75 12 16.75C12.41 16.75 12.75 16.41 12.75 16V14C12.75 13.59 12.41 13.25 12 13.25Z"
                    fill="#2A364E"
                  />
                </svg>
                <input
                  type="password"
                  name="password"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <p>Enter The Captch</p>
              <div className="inputCaptchWrapper">
                <input
                  type="text"
                  name="search"
                  onChange={(e) => handleChange(e)}
                  //value={search}
                  //onChange={(e) => setSearch(e.target.value)}
                />
                <canvas ref={canvasRef} width={120} height={40}></canvas>
                {/* <span>{expression}</span> */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.0004 9.00009C16.6281 9.83575 17 10.8745 17 12.0001C17 13.1257 16.6281 14.1644 16.0004 15.0001M18 5.29177C19.8412 6.93973 21 9.33459 21 12.0001C21 14.6656 19.8412 17.0604 18 18.7084M4.6 9.00009H5.5012C6.05213 9.00009 6.32759 9.00009 6.58285 8.93141C6.80903 8.87056 7.02275 8.77046 7.21429 8.63566C7.43047 8.48353 7.60681 8.27191 7.95951 7.84868L10.5854 4.69758C11.0211 4.17476 11.2389 3.91335 11.4292 3.88614C11.594 3.86258 11.7597 3.92258 11.8712 4.04617C12 4.18889 12 4.52917 12 5.20973V18.7904C12 19.471 12 19.8113 11.8712 19.954C11.7597 20.0776 11.594 20.1376 11.4292 20.114C11.239 20.0868 11.0211 19.8254 10.5854 19.3026L7.95951 16.1515C7.60681 15.7283 7.43047 15.5166 7.21429 15.3645C7.02275 15.2297 6.80903 15.1296 6.58285 15.0688C6.32759 15.0001 6.05213 15.0001 5.5012 15.0001H4.6C4.03995 15.0001 3.75992 15.0001 3.54601 14.8911C3.35785 14.7952 3.20487 14.6422 3.10899 14.4541C3 14.2402 3 13.9601 3 13.4001V10.6001C3 10.04 3 9.76001 3.10899 9.54609C3.20487 9.35793 3.35785 9.20495 3.54601 9.10908C3.75992 9.00009 4.03995 9.00009 4.6 9.00009Z"
                    stroke="#131A29"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
                <svg
                  onClick={() => setExpression(generateRandomKeyword())}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 8.5C5 5 8.51657 3.00122 11.9991 3.00122C16.9692 3.00122 20.9982 7.03039 20.9982 12.0006C20.9982 16.9708 16.9692 21 11.9991 21C8.51657 21 5.49605 19.0217 3.99995 16.1276M3 8.5V4.00006M3 8.5H7"
                    stroke="#131A29"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {/* <ReCAPTCHA sitekey="Your client site key" onChange={onChange} /> */}
            </div>
            <button type="submit">Login</button>
            <p className="forget">Forget Password ?</p>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Client;
