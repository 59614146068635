import React, { useState } from "react";
import LogoWhite from "Assests/Icons/WebsiteLogoWhite.png";
import LocationIcon from "Assests/Icons/Location.svg";
import NewLocationIcon from "Assests/Icons/Group 21.svg";
import PhoneIcon from "Assests/Icons/Phone.svg";
import NewPhoneIcon from "Assests/Icons/Group 22.svg";
import newPhoneDesign1 from "Assests/Icons/newPhoneDesign.svg";
import newCall from "Assests/Images/newCall.svg";
import EmailIcon from "Assests/Icons/Email.svg";
import NewEmailIcon from "Assests/Icons/Group 23.svg";
import RightArrow from "Assests/Icons/RightArrow.svg";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import { setUploadModel } from "../../Redux/Actions/model";
import { useDispatch } from "react-redux";
import SampleCollection from "Components/Popup/SampleCollection/SampleCollection";
import { setAlert } from "../../Redux/Actions/alert.action";
import httpPathName from "Global/Config/Config";
import ND from "Assests/Images/ND.png";
import logo21 from "Assests/Images/logo21.png";
import footerlogo from "Assests/Images/footer-logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [locationHovered, setLocationHovered] = useState(false);
  const [phoneHovered, setPhoneHovered] = useState(false);
  const [emailHovered, setEmailHovered] = useState(false);

  const [email, setEmail] = useState("");

  const token = localStorage.getItem("access_token");

  const sendEmail = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!email) {
      dispatch(setAlert("error", "Please Enter Email"));
      return;
    }
    const formData = {
      email: email,
    };
    const promise = await httpPathName
      .post(
        `users/add-subscriber`,
        { ...formData },
        { headers: { "x-auth-token": token } }
      )
      .then((response) => {
        dispatch(setAlert("success", response?.data?.message));
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Invalid Email Error"));
      });
  };

  return (
    <>
      <div className="footer">
        <div className="logo-list">
          <div>
            <Link className="footer-logo" to="/">
              <img src={footerlogo} alt="Logo White" className="img-fluid" />
            </Link>
          </div>
          <div className="wrapper">
            <div>
              <h4 className="text-overflow-handler">Company</h4>
              <ul>
                <li onClick={() => navigate("/about-us")}>About us</li>
                <li onClick={() => navigate("/about-us?tab=brandTab")}>
                  The Brand
                </li>
                <li onClick={() => navigate("/about-us?tab=aboutTab")}>
                  Mission & Vision
                </li>
                <li onClick={() => navigate("/about-us?tab=teamsTab")}>
                  Management Team
                </li>
                <li onClick={() => navigate("/career")}>Careers</li>
              </ul>
            </div>
            <div>
              <h4 className="text-overflow-handler">Services</h4>
              <ul>
                <li onClick={() => navigate("/labs")}>Find a Lab</li>
                <li
                  onClick={() => {
                    navigate("/services/all-test-package?category=Package");
                  }}
                >
                  Health Packages
                </li>
                <li
                  onClick={() => {
                    navigate("/services/wellness-packages")
                  }}
                >
                  Pre-Employment Checks
                </li>
                <li onClick={() => navigate("/corporate")}>
                  Corporate Tie-Ups
                </li>
                <li
                  onClick={() => {
                    navigate("/services/seafarer");
                  }}
                >
                  Seafarers/Sea Men
                </li>
                <li
                  onClick={() => {
                    dispatch(
                      setUploadModel({
                        show: true,
                        title: "Upload Prescription",
                      })
                    );
                  }}
                >
                  Upload a Prescription
                </li>
              </ul>
            </div>
            <div>
              <h4
                className="text-overflow-handler"
                style={{ marginRight: "10px" }}
              >
                Readers Section
              </h4>
              <ul>
                <li onClick={() => navigate("/faqs")}>FAQs</li>
                <li onClick={() => navigate("/blog")}>Blogs</li>
                <li>Videos</li>
                <li>PR Media</li>
                {/* <li>Patient Awareness</li>
                <li>Experts/KOLs Section</li> */}
                <li onClick={() => navigate("/client-portal")}>
                  Client Portal
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-overflow-handler">Reach us</h4>
              <ul className="img-list">
                <li>
                  {locationHovered ? (
                    <img
                      onMouseLeave={() => setLocationHovered(false)}
                      src={NewLocationIcon}
                      alt="location"
                    />
                  ) : (
                    <img
                      onMouseEnter={() => setLocationHovered(true)}
                      src={LocationIcon}
                      alt="location"
                    />
                  )}

                  <span className="address">
                    Nederlands Diagnostics Ground Floor, Mega One Building,
                    Espana Blvd. corner Mayon St. Brgy. Sta.Teresita Quezon City
                    National Capital Région (Manila) PH
                  </span>
                </li>
                <li>
                  {phoneHovered ? (
                    <img
                      onMouseLeave={() => setPhoneHovered(false)}
                      src={NewPhoneIcon}
                      alt="phone"
                    />
                  ) : (
                    <img
                      onMouseEnter={() => setPhoneHovered(true)}
                      src={newCall}
                      alt="phone1"
                    />
                  )}

                  <div>
                    <a
                      href="tel:+632 8929 4422"
                      title="Call Us"
                      className="d-inline-block text-decoration-none text-white"
                    >
                      +632 8929 4422
                    </a>
                    <span className="d-inline-block mx-2">/</span>
                    <a
                      href="tel:+632 8876 1466"
                      title="Call Us"
                      className="d-inline-block text-decoration-none text-white"
                    >
                      +632 8876 1466
                    </a>
                  </div>
                </li>
                <li>
                  {emailHovered ? (
                    <img
                      onMouseLeave={() => setEmailHovered(false)}
                      src={NewEmailIcon}
                      alt="email"
                    />
                  ) : (
                    <img
                      onMouseEnter={() => setEmailHovered(true)}
                      src={EmailIcon}
                      alt="email"
                    />
                  )}

                  <a
                    href="mailto:contact@nederlandsdiagnostics.com"
                    className="d-inline-block text-decoration-none text-white"
                  >
                    contact@nederlandsdiagnostics.com
                  </a>
                </li>
              </ul>
              <div className="search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17 4.25H7C4.38 4.25 2.25 6.38 2.25 9V15C2.25 17.62 4.38 19.75 7 19.75H17C19.62 19.75 21.75 17.62 21.75 15V9C21.75 6.38 19.62 4.25 17 4.25ZM20.25 15C20.25 16.79 18.79 18.25 17 18.25H7C5.21 18.25 3.75 16.79 3.75 15V9C3.75 7.21 5.21 5.75 7 5.75H17C18.79 5.75 20.25 7.21 20.25 9V15Z"
                    fill="black"
                  />
                  <path
                    d="M17.5801 8.37985L12.6901 11.6399C12.2701 11.9199 11.7201 11.9199 11.3001 11.6399L6.41008 8.37985C6.07008 8.14985 5.60008 8.23985 5.37008 8.58985C5.14008 8.93985 5.23008 9.39985 5.58008 9.62985L10.4701 12.8899C10.9301 13.1999 11.4601 13.3499 12.0001 13.3499C12.5401 13.3499 13.0601 13.1999 13.5301 12.8899L18.4201 9.62985C18.7601 9.39985 18.8601 8.92985 18.6301 8.58985C18.4001 8.24985 17.9301 8.14985 17.5901 8.37985H17.5801Z"
                    fill="black"
                  />
                </svg>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter your email id"
                />

                <button onClick={sendEmail}>
                  <img src={RightArrow} alt="RightArrow" />
                </button>
              </div>
              <ul className="icons-list">
                <a
                  href="https://www.facebook.com/Nederlandsdiagnostics"
                  target="blank"
                >
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                    >
                      <path
                        d="M2.00702 15.9688V7.98434H0V5.23284L2.00702 5.2316L2.00421 3.61101C2.00421 1.36633 2.61302 -6.10352e-05 5.25692 -6.10352e-05H7.98441V2.75269H6.08218C5.05232 2.75269 5.00335 3.13694 5.00335 3.85429L4.9993 5.2316H8.01029L7.71899 7.98341L5.00117 7.98434L4.9993 15.9688H2.00702Z"
                        fill="white"
                      />
                    </svg>
                  </li>
                </a>
                <a
                  href="https://www.linkedin.com/company/nederlands-diagnostics/"
                  target="blank"
                >
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_136_2878)">
                        <path
                          d="M0.531813 4.99019V15.9688H3.49415V4.99019H0.531813ZM9.56916 4.99113C10.6053 4.99113 13.6184 5.41125 13.6184 9.15393V15.9688H10.4805V9.05725C10.4805 8.24696 10.0214 7.68088 9.02741 7.68088C7.37751 7.68088 7.48636 9.05725 7.48636 9.05725V15.9688H4.50811V4.99019H7.48636V5.8585C7.94359 5.50824 8.48441 4.99113 9.56916 4.99113ZM0.5 2.49507C0.5 1.66856 1.17057 0.99799 1.99708 0.99799C2.82359 0.99799 3.49415 1.66856 3.49415 2.49507C3.49415 3.32158 2.82359 3.99214 1.99708 3.99214C1.17057 3.99214 0.5 3.32158 0.5 2.49507Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_136_2878">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(1 0 0 -1 0.484375 16)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </li>
                </a>
                <a
                  href="https://www.instagram.com/nederlandsdiagnostics/"
                  target="blank"
                >
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_136_2881)">
                        <path
                          d="M15.4983 -6.10352e-05C15.8954 -6.10352e-05 16.2188 0.322434 16.2188 0.719471V15.2492C16.2188 15.6453 15.8954 15.9688 15.4983 15.9688H0.970468C0.573431 15.9688 0.25 15.6453 0.25 15.2492V0.719471C0.25 0.322434 0.573431 -6.10352e-05 0.970468 -6.10352e-05H15.4983ZM11.0208 7.98434C11.0208 6.44547 9.77328 5.19791 8.23441 5.19791C6.69554 5.19791 5.44797 6.44547 5.44797 7.98434C5.44797 9.52321 6.69554 10.7708 8.23441 10.7708C9.77328 10.7708 11.0208 9.52321 11.0208 7.98434ZM14.2227 13.9726V6.98629H12.9084C12.977 7.30879 13.0169 7.64189 13.0169 7.98434C13.0169 10.6211 10.8711 12.7669 8.23441 12.7669C5.59768 12.7669 3.45187 10.6211 3.45187 7.98434C3.45187 7.64189 3.49179 7.30879 3.56041 6.98629H2.2461V13.9726H14.2227ZM14.2227 4.99019V1.99604H11.2286V4.99019H14.2227Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_136_2881">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(1 0 0 -1 0.234375 16)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="wrapper">
            <div>
              Copyright © <a href="/">Nederlands diagnostics</a>{" "}
            </div>
            <div>
              <a
                href="/privacy-policy"
                className="footerbelowLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <a
                href="/disclaimers"
                className="footerbelowLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/disclaimers");
                }}
              >
                Disclaimers
              </a>{" "}
              |{" "}
              <a
                href="/terms"
                className="footerbelowLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/terms");
                }}
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <SampleCollection /> */}
    </>
  );
};

export default Footer;
