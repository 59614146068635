import {
  SET_UPLOAD_PRESCRIPTION,
  AUTHENTICATION_MODEL,
  CONTACT_MODEL,
  HOME_PAGE_MODEL,
} from "../../Redux/action.type";

const obj = {
  show: false,
  authenticationModel: false,
  title: "",
};

const authModel = false;

const contact = false;
const homeModel = false;

export const setAuthModel = (state = authModel, action) => {
  switch (action.type) {
    case AUTHENTICATION_MODEL:
      if (!action.payload) {
        action.payload = authModel;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setUploadModel = (state = obj, action) => {
  switch (action.type) {
    case SET_UPLOAD_PRESCRIPTION:
      if (!action.payload) {
        action.payload = obj;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setContactUsModel = (state = contact, action) => {
  switch (action.type) {
    case CONTACT_MODEL:
      if (!action.payload) {
        action.payload = contact;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setHomePageModel = (state = homeModel, action) => {
  switch (action.type) {
    case HOME_PAGE_MODEL:
      if (!action.payload) {
        action.payload = homeModel;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
