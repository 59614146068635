import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import "./Setting.css";
import { useNavigate } from "react-router-dom";

const Setting = ({ show, setShow }) => {
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteAccount = async () => {
    try {
      const response = await httpPathName.delete("users/delete-user", {
        headers: { "x-auth-token": token },
      });
      dispatch(setAlert("success", response?.data?.message));
      localStorage.removeItem("access_token");
      setShow(false);
      navigate("/");
    } catch (err) {
      console.log(err);
      setShow(false);
      dispatch(setAlert("error", err?.response?.data?.message));
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      className="setting"
    >
      <Modal.Header closeButton>
        <Modal.Title>Would You Like To Delete Your Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between btnWrapper">
          <button onClick={deleteAccount}>Yes</button>
          <button onClick={() => setShow(false)}>No</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Setting;
