import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

export default function CustomOtpInput(props) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const isMobile = width <= 768;

  return (
    <>
      <OtpInput
        renderSeparator={<div style={{ padding: isMobile ? "7px" : "14px" }} />}
        inputStyle={{
          width: isMobile ? "24px" : "46px",
          height: isMobile ? "24px" : "46px",
          borderRadius: "50%",
          fontSize: isMobile ? "14px" : "18px",
          fontWeight: 600,
          color: "#2A364E",
          border: "1px solid var(--paragraph, #5E5E5E)",
        }}
        renderInput={(props) => <input {...props} />}
        {...props}
      />
    </>
  );
}
