import {
  HMO_STEP1_DATA,
  HMO_STEP2_DATA,
  HMO_STEP3_DATA,
  HMO_STEP4_DATA,
} from "../../Redux/action.type";

const stepData = {
  step1: {},
  step2: {},
  step3: {},
  step4: {},
};

// const step1 = {}
// const step2 = {}
// const step3 = {}
// const step4 = {}

export const setHmoStepReducer = (state = stepData, action) => {
  switch (action.type) {
    case HMO_STEP1_DATA:
      state.step1 = action.payload;
      return state;
    case HMO_STEP2_DATA:
      state.step2 = action.payload;
      return state;
    case HMO_STEP3_DATA:
      state.step3 = action.payload;
      return state;
    case HMO_STEP4_DATA:
      state.step4 = action.payload;
      return state;
    default:
      return state;
  }
};
