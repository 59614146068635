import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import diagnostics from "Assests/Images/diagnostics.png";
import consultDoctor from "Assests/Images/consultDoctor.png";
import dot3 from "Assests/Images/dot3.svg";
import React from "react";
import Wanner from "Components/Wnner/Wanner";
import "./BookAppointment.css";
import SceduleSlot from "./SceduleSlot";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const BookAppointment = () => {
  const { id } = useParams();

  const doctorDetails = useSelector((state) => state?.setConsultSelectedDoctor);

  return (
    <>
      <Header />
      <Wanner title="Consultation" image={diagnostics} dot={false} />
      <div className="doctor-book-appointment">
        <div className="top">
          <div className="doctor-profile">
            <img
              src={doctorDetails?.doctor_image || consultDoctor}
              alt={doctorDetails?.doctor_name || "consultDoctor"}
            />
            <div>
              <h2>{doctorDetails?.doctor_name || ""} </h2>
              <p>{doctorDetails?.designation}</p>
            </div>
          </div>
          <div className="about-section">
            <h3>About</h3>
           
            <p className="content">
              We offer a holistic in-house end-to-end solution - Right from the
              time of receiving a call, to the home collection, testing of the
              sample, generating report and mailing it further to the patient,
              every step is carefully and professionally executed by the
              experienced in-house home sample collection team. For all our home
              sample collection we offer a one-time use kit for every patient
              which makes us your trusted and reliable partner in pathology
              testing. We offer a holistic in-house end-to-end solution - Right
              from the time of receiving a call, to the home collection, testing
              of the sample, generating report and mailing it further to the
              patient, every step is carefully and professionally executed by
              the experienced in-house home sample collection team. For all our
              home sample collection we offer a one-time use kit for every
              patient which makes us your trusted and reliable partner in
              pathology testing.{" "}
            </p>
          </div>
        </div>
        <SceduleSlot id={id} />
        <p className="content">
          Keeping a view of “One Stop Solution Service Provider” Phi Sante
          Diagnostics offers consultation both inhouse and teleconsultation
          services at the ease of patients. Being a certified MFOWs clinic you
          can avail consultation from specialties like Urologist, Nephrologist,
          Surgeon, Obstetrics and Gynecology, Hematologist, endocrinologist,
          Cardiologist, Infectious Specialist, Dentist, Ophthalmologist and
          Otorhinologist.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default BookAppointment;
