import React from "react";
import "./Search.css";
import searchIcon from "Assests/Icons/searchIcon.svg";
import rightarrowwhite from "Assests/Icons/rightarrowwhite.svg";

const Search = ({ onChange, name, placeholder = "Search" }) => {
  return (
    <div className="custom-search-wrapper">
      <img src={searchIcon} alt="searchIcon" />
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className="red-wrapper">
        <img src={rightarrowwhite} alt="rightarrowwhite" />
      </div>
    </div>
  );
};

export default Search;
