import React from "react";
import "./LabHome.css";
import diagnostics from "Assests/Images/diagnostics.png";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import { Card, Form, InputGroup, Nav } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "Assests/Icons/searchIcon.svg";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import OldManCheckup from "Assests/Images/OldManCheckup.png";
import RightArrow from "Assests/Icons/RightArrow.svg";
import { useEffect, useState } from "react";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Spinner from "react-bootstrap/Spinner";
import httpPathName from "Global/Config/Config";
import { bookNowApi } from "API/post.api";
import { setAuthenticationModel } from "../../Redux/Actions/model";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";
import { fetchServiceContent } from "API/get.api";
import Search from "Components/Common/Search/Search";

const navKeys = {
  ALL: "all",
  INDIVIDUAL: "Individual",
  PACKAGE: "Package",
};

const LabHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const [activeNavKey, setActiveNavKey] = useState(navKeys.ALL);
  const [search, setSearch] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchContent();
  }, []);

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setContent(res?.data?.serviceContent?.lab_home?.description);
    } catch (e) {
      console.log("err", e);
    }
  }

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Avail our hassle-free lab home services from your convenience.","Experience the convenience of Nederlands Diagnostics' lab home services, bringing high-quality diagnostic testing to the comfort of your own home.")

  const fetchLabData = async () => {
    setLoading(true);
    const formData = {
      keyword: search,
    };
    const apiStatus = await httpPathName
      .post(
        "home/getLab-HomeTestList",
        { ...formData },
        { headers: { "x-auth-token": token } }
      )
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setTestData(
            response?.data?.data?.tests?.length > 0
              ? response?.data?.data?.tests
              : []
          );
          setPackageData(
            response?.data?.data?.packages?.length > 0
              ? response?.data?.data?.packages
              : []
          );
        } else {
          dispatch(setAlert("error", response?.response?.data?.message));
          setTestData([]);
          setPackageData([]);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let debounceTimer;
  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  useEffect(() => {
    debounce(() => fetchLabData(), 500);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search, activeNavKey]);

  function filterData() {
    let data = [];
    if (activeNavKey == "Individual") {
      if (testData?.length > 0) {
        data = [...testData];
      }
    } else if (activeNavKey == "Package") {
      if (packageData?.length > 0) {
        data = [...packageData];
      }
    } else {
      if (testData?.length > 0 || packageData?.length > 0) {
        data = [...testData, ...packageData];
      } else {
        data = [];
      }
    }
    return data;
  }

  const CardItem = ({
    img,
    alt,
    title,
    short_desc,
    price,
    original_price,
    include,
    parameter,
    long_desc,
    key,
    id,
  }) => {
    return (
      <div
        onClick={() =>
          navigate(
            `/services/${encodeURIComponent(title)}`,
            {
              state: { id: id },
            }
          )
        }
        className="card-item"
      >
        <img src={img} alt={alt} />
        <div className="main-content">
          <h6>{title || "No Title"}</h6>
          {/* <p className="description">{short_desc || "No Description"}</p> */}
          <span>₱ {price}</span>
          <button
            className="book-btn flexRowAlignJustify"
            onClick={(e) => {
              e.stopPropagation();
              if (!token) {
                // Replace False with true to Enable Auth Modal
                dispatch(setAuthenticationModel(true));
              } else {
                const apiStatus = bookNowApi(token, id);
                apiStatus
                  .then((response) => {
                    console.log(response);
                    navigate("/cart");
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(setAlert("error", err?.response?.data?.error));
                  });
              }
            }}
          >
            <CartIcon style={{ marginRight: "8px" }} /> Book Now
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <MetaDetails
        title="Avail our hassle-free lab home services from your convenience."
        description="Experience the convenience of Nederlands Diagnostics' lab home services, bringing high-quality diagnostic testing to the comfort of your own home."
      />

      <Header />
      <div className="labHome">
        <Wanner title="Lab@Home" image={diagnostics} dot={false} />
        <div className="diagnosticWrapper">
          {/* <h1>Lab@Home</h1>
          <img src={dot3} alt="dot3" /> */}
          <p>{content?.description || ""}</p>
          <div className="searchTabWrapper">
            <Search
              placeholder="Search Test or Health packages"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div>
              <Nav
                variant="pills"
                defaultActiveKey={navKeys.ALL}
                activeKey={activeNavKey}
                onSelect={setActiveNavKey}
                className="navContainer"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="all"
                    className={`navItem ${activeNavKey === navKeys.ALL && "navItemActive"
                      }`}
                  >
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Individual"
                    className={`navItem ${activeNavKey === navKeys.INDIVIDUAL && "navItemActive"
                      }`}
                  >
                    Individual Tests
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Package"
                    className={`navItem ${activeNavKey === navKeys.PACKAGE && "navItemActive"
                      }`}
                  >
                    Packages
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
        <div className="section1">
          {filterData()?.length > 0 ? (
            <div className="cardWrapper">
              {filterData()?.map((item, i) => (
                <CardItem
                  key={i}
                  img={item?.image}
                  alt={item?.test_name}
                  title={item?.test_name}
                  short_desc={item?.intented_use}
                  price={item?.mrp}
                  original_price={item?.price}
                  include={7}
                  parameter={76}
                  id={item?._id}
                />
              ))}
            </div>
          ) : !!loading ? (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              <Spinner />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              No Data Found
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LabHome;
