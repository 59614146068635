import React, { useEffect, useState } from "react";
import consultation1 from "Assests/Images/consultation1.png";
import consultation2 from "Assests/Images/consultation2.png";
import consultation3 from "Assests/Images/consultation3.png";
import consultation4 from "Assests/Images/consultation4.png";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import diagnostics from "Assests/Images/diagnostics.png";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import "./Consultation.css";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";
import { setSelectDoctor } from "../../Redux/Actions/set.action";
import { fetchServiceContent } from "API/get.api";

const Consultation = () => {
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const [data, setData] = useState({
    specialist: [],
    doctor: [],
  });

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Book expert consultation services at Nederlands Diagnostics.","Nederlands Diagnostics offers expert consultation services to guide patients and healthcare providers in understanding diagnostic test results effectively.")

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchContent();
  }, []);

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setContent(res?.data?.serviceContent?.consultation);
    } catch (e) {
      console.log("err", e);
    }
  }

  const consultData = async () => {
    const apiStatus = await httpPathName
      .get("home/getDoctorsList", { headers: { "x-auth-token": token } })
      .then((response) => {
        // dispatch(setAlert("success", response?.data?.message));
        setData({
          specialist: response?.data?.data?.listOfSpecialists,
          doctor: response?.data?.data?.listOfDoctors,
        });
        // setLoading(false);
        //setShow(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
        // setLoading(false);
      });
  };

  useEffect(() => {
    consultData();
  }, []);

  const handleNavigate = (item) => {
    navigate(`/services/consultation/${item._id}`);
    dispatch(setSelectDoctor(item));
  };

  function Section1() {
    return (
      <>
        <div className="section1-wrapper">
          <h1>
            Our <span style={{ fontWeight: "600" }}>Specialists</span>
          </h1>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="white" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <div className="cardWrapper">
            {content?.our_specialists?.length > 0 &&
              content?.our_specialists?.map((item, i) => (
                <div key={i} className="card">
                  <img src={item?.image} alt={item?.heading} />
                  <p>{item?.heading}</p>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }

  function Section2() {
    return (
      <>
        <div className="section2-wrapper">
          <h1>Need Help with Booking Your Consultation?</h1>
          <p>
            Our Experts are here to help you at{" "}
            <span style={{ color: "#A71F23" }}>+632 8929 4422</span>
          </p>
        </div>
      </>
    );
  }

  function Section3() {
    return (
      <>
        <div className="section3-wrapper">
          <div className="cardWrapper">
            {data?.doctor?.length > 0 &&
              data?.doctor?.map((item) => (
                <div className="card">
                  <div className="img-wrapper">
                    <img src={item?.doctor_image} />
                  </div>

                  <div className="contentWrapper">
                    <p>
                      {item?.doctor_name} - {item?.designation}
                    </p>
                    <span>{`₱ ${item?.price}`}</span>
                  </div>
                  <button onClick={() => handleNavigate(item)}>
                    <CartIcon style={{ marginRight: "8px" }} /> Book Now
                  </button>
                </div>
              ))}

            {/* <div className="card">
              <img src={consultation2} />
              <div className="contentWrapper">
                <p>Dr. Deepti Jain - Obs. And Gynaecologist</p>
                <span>₹500</span>
              </div>
            </div>

            <div className="card">
              <img src={consultation3} />
              <div className="contentWrapper">
                <p>Dr. Deepti Jain - Obs. And Gynaecologist</p>
                <span>₹500</span>
              </div>
            </div>

            <div className="card">
              <img src={consultation4} />
              <div className="contentWrapper">
                <p>Dr. Deepti Jain - Obs. And Gynaecologist</p>
                <span>₹500</span>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }

  function Section4() {
    return (
      <>
        <div className="section4-wrapper">
          <h1>{content?.heading || ""}</h1>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#2A364E" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <p>{content?.description || ""}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDetails
        title="Book expert consultation services at Nederlands Diagnostics."
        description="Nederlands Diagnostics offers expert consultation services to guide patients and healthcare providers in understanding diagnostic test results effectively."
      />

      <Header />
      <div className="consultation">
        <Wanner title="Consultation" image={diagnostics} dot={false} />

        <div className="section1">{Section1()}</div>
        <div className="section4">{Section4()}</div>
        <div className="section3">{Section3()}</div>
        <div className="section2">{Section2()}</div>
      </div>
      <Footer />
    </>
  );
};

export default Consultation;
