import React from "react";
import Stepper from "Components/Common/Stepper/Stepper";
import consultDoctor from "Assests/Images/consultDoctor.png";
import "./BookAppointment.css";
import NextBtn from "Components/Common/Button/NextBtn";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Consultation1 = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedConsultData = useSelector(
    (state) => state?.setConsultationSlot
  );
  const doctorDetails = useSelector((state) => state?.setConsultSelectedDoctor);
  const handleClick = () => {
    navigate(`/services/consultation/${id}/patient-details`);
  };

  return (
    <div className="consultation-1">
      <div className="info-wrapper">
        <div className="doctor-profile">
          <img
            src={doctorDetails?.doctor_image || consultDoctor}
            alt="consultDoctor"
          />
          <div className="doc-description">
            <h2>{doctorDetails?.doctor_name || ""}</h2>
            <p>{doctorDetails?.designation}</p>
          </div>
        </div>
        <div className="wrapper">
          <div className="input-wrapper">
            <label>Preferred Date & Time Of Visit </label>
            <input
              value={`${selectedConsultData?.date}  ${selectedConsultData?.timeStamp}`}
              readOnly
            />
          </div>

          <div className="input-wrapper">
            <label>Mode of Consultation</label>
            <input value={selectedConsultData?.modeOfConsultation} readOnly />
          </div>
        </div>
        <div className="btn-wrapper">
          <NextBtn onClick={handleClick} />
        </div>
      </div>
      <p>
        Keeping a view of “One Stop Solution Service Provider” Phi Sante
        Diagnostics offers consultation both inhouse and teleconsultation
        services at the ease of patients. Being a certified MFOWs clinic you can
        avail consultation from specialties like Urologist, Nephrologist,
        Surgeon, Obstetrics and Gynecology, Hematologist, endocrinologist,
        Cardiologist, Infectious Specialist, Dentist, Ophthalmologist and
        Otorhinologist.
      </p>
    </div>
  );
};

export default Consultation1;
