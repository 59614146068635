// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-member .modal-title.h4 {
  font-size: 2vw;
  color: var(--primary-color);
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: 75px 0;
}

.add-member .modal-header {
  position: relative;
  border: unset;
}

.add-member .modal-header button {
  position: absolute;
  right: 5%;
  top: 15%;
}

.add-member .modal-body {
  width: 70%;
  margin: 0 auto;
}

.add-member .modal-body form > div {
  border: solid 1px #5e5e5e;
  border-radius: 15px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 20px;
}

.add-member .modal-body form > div input,
.add-member .modal-body form > div select {
  border: unset;
  outline: none;
  width: 100%;
}

.add-member .modal-dialog {
  max-width: 708px;
  width: 100%;
}

.add-member .modal-body button {
  border-radius: 31px;
  background: #3aa1ed;
  border: unset;
  width: 100%;
  color: white;
  font-size: 1.3vw;
  font-weight: normal;
  padding: 10px 0;
  margin-bottom: 50px;
  margin-top: 25px;
}

.add-member svg {
  margin-right: 10px;
}

.add-member .modal-body label {
  font-size: 1vw;
  color: #5e5e5e;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Popup/AddMember/AddMember.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,2BAA2B;EAC3B,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".add-member .modal-title.h4 {\n  font-size: 2vw;\n  color: var(--primary-color);\n  font-weight: bold;\n  width: 100%;\n  text-align: center;\n  margin: 75px 0;\n}\n\n.add-member .modal-header {\n  position: relative;\n  border: unset;\n}\n\n.add-member .modal-header button {\n  position: absolute;\n  right: 5%;\n  top: 15%;\n}\n\n.add-member .modal-body {\n  width: 70%;\n  margin: 0 auto;\n}\n\n.add-member .modal-body form > div {\n  border: solid 1px #5e5e5e;\n  border-radius: 15px;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  padding: 0 16px;\n  margin-bottom: 20px;\n}\n\n.add-member .modal-body form > div input,\n.add-member .modal-body form > div select {\n  border: unset;\n  outline: none;\n  width: 100%;\n}\n\n.add-member .modal-dialog {\n  max-width: 708px;\n  width: 100%;\n}\n\n.add-member .modal-body button {\n  border-radius: 31px;\n  background: #3aa1ed;\n  border: unset;\n  width: 100%;\n  color: white;\n  font-size: 1.3vw;\n  font-weight: normal;\n  padding: 10px 0;\n  margin-bottom: 50px;\n  margin-top: 25px;\n}\n\n.add-member svg {\n  margin-right: 10px;\n}\n\n.add-member .modal-body label {\n  font-size: 1vw;\n  color: #5e5e5e;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
