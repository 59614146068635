export const SET_ALERT = "SET_ALERT";
export const RESET_ALERT = "RESET_ALERT";
export const USER_DATA = "USER_DATA";
export const CART_DATA = "CART_DATA";
export const ORDER_ID = "ORDER_ID";
export const SELECTED_USERS_FOR_TEST = "SELECTED_USERS_FOR_TEST";
export const SIGN_OUT = "SIGN_OUT";

export const AUTHENTICATION_MODEL = "AUTHENTICATION_MODEL";

export const CONTACT_MODEL = "CONTACT_MODEL";

export const HMO_STEP1_DATA = "HMO_STEP1_DATA";
export const HMO_STEP2_DATA = "HMO_STEP2_DATA";
export const HMO_STEP3_DATA = "HMO_STEP3_DATA";
export const HMO_STEP4_DATA = "HMO_STEP4_DATA";

export const CART_REF_NO = "CART_REF_NO";
export const CART_TOTAL_AMOUNT = "CART_TOTAL_AMOUNT";
export const CART_SELECTED_MEMBER_ID = "CART_SELECTED_MEMBER_ID";

export const PROFILE_ACTIVE_TAB = "PROFILE_ACTIVE_TAB";

export const MEMBER_LIST_DATA = "MEMBER_LIST_DATA";

export const SET_FILTER_MODEL = "SET_FILTER_MODEL";
export const SET_ALL_TESTS = "SET_ALL_TESTS";

export const SET_UPLOAD_PRESCRIPTION = "SET_UPLOAD_PRESCRIPTION";
export const TESTS_LIST = "TESTS_LIST";
export const PACKAGES_LIST = "PACKAGES_LIST";

export const RELATION_DATA = "RELATION_DATA";

export const HOME_PAGE_MODEL = "HOME_PAGE_MODEL";

export const CONSULTATION_SLOT_DATA = "CONSULTATION_SLOT_DATA";

export const SELECTED_DOCTOR = "SELECTED_DOCTOR";
