import httpPathName from "Global/Config/Config";

export const bookNowApi = async (token, test) => {
  const promise = await httpPathName.post(
    `cart/bookNow/${test}`,
    {
      action: "",
    },
    {
      headers: { "x-auth-token": token },
    }
  );
  return promise;
};

export const bookNowQtyApi = async (token, test, action) => {
  const promise = await httpPathName.post(
    `cart/bookNow/${test}`,
    {
      action,
    },
    {
      headers: { "x-auth-token": token },
    }
  );
  return promise;
};

export const deleteTestApi = async (token, test, action) => {
  const promise = await httpPathName.post(
    `cart/deleteCart/${test}`,
    {
      action: action,
    },
    {
      headers: { "x-auth-token": token },
    }
  );
  return promise;
};

export const subscribeApi = async (email) => {
  const promise = await httpPathName.post("users/addEmail", { email: email });
  return promise;
};

export const proceedFromCart = async (token, data) => {
  const promise = await httpPathName.put(
    "cart/proceedToNext",
    { ...data },
    {
      headers: { "x-auth-token": token },
    }
  );
  return promise;
};

export const addANewMemberApi = async (token, data) => {
  const promise = await httpPathName.post(
    "users/addNewMember",
    { ...data },
    {
      headers: { "x-auth-token": token },
    }
  );
  return promise;
};

export const fetchAllJobsApi = async (token, data) => {
  const promise = await httpPathName.post(
    "career/getAllJobs",
    { ...data },
    { headers: { "x-auth-token": token } }
  );
  return promise;
};

export const jobApplyApi = async (token, data) => {
  const promise = await httpPathName.post(
    "career/apply",
    { ...data },
    {
      headers: {
        "x-auth-token": token,
        accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
    }
  );
  return promise;
};

// export const proceedToNextApi = async (token, data) => {
//   const promise = await httpPathName.post(
//     "career/getAllJobs",
//     { ...data },
//     { headers: { "x-auth-token": token } }
//   );
//   return promise;
// };

export const makeAppointment = async (token, data) => {
  const promise = await httpPathName.post(
    "hmo/makeAppointment",
    { ...data },
    {
      headers: {
        "x-auth-token": token,
        accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
    }
  );
  return promise;
};

export const onlinePayment = async (token, data) => {
  const promise = await httpPathName.post(
    "cart/online-payment",
    { ...data },
    { headers: { "x-auth-token": token } }
  );
  return promise;
};

export const applyCoupon = async (token, data) => {
  const promise = await httpPathName.post(
    "cart/apply-coupon",
    { ...data },
    { headers: { "x-auth-token": token } }
  );
  return promise;
};

export const submitFeedback = async (id, data) => {
  const promise = await httpPathName.patch(`blog/leave-a-comment/${id}`, {
    ...data,
  });
  return promise;
};

export const doctorAppointmnetSubmit = async (token, id, data) => {
  const promise = await httpPathName.post(
    `doctor/addDoctorAppointment/${id}`,
    { ...data },
    { headers: { "x-auth-token": token } }
  );
  return promise;
};
