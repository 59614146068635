import BookingUser from "Components/Cards/BookingUser/BookingUser";
import SelectedTest from "Components/Cards/SelectedTest/SelectedTest";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Cart.css";
import MemberList from "Components/Popup/MemberList/MemberList";
import AddMember from "Components/Popup/AddMember/AddMember";
import { useEffect } from "react";
import { fetchTheCartApi } from "API/get.api";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { setCartDataAction } from "../../Redux/Actions/set.action";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const userForTests = useSelector((state) => state.setUserForTestReducer);
  const testList = useSelector((state) => state.setCartDataReducer?.tests);
  const tAmount = useSelector((state) => state.setCartTotalAmount);

  const home = testList?.home ? testList?.home : [];
  const lab = testList?.lab ? testList?.lab : [];

  let outSorcetotalData = lab.reduce((accu, curr) => {
    return accu + curr.mrp;
  }, 0);

  let hometotalData = home.reduce((accu, curr) => {
    return accu + curr.mrp;
  }, 0);

  const [show, setShow] = useState(false);
  const [internalPopup, setinternalPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  useEffect(() => {
    const apiStatus = fetchTheCartApi(token);
    apiStatus
      .then((response) => {
        dispatch(setCartDataAction({ tests: response?.data?.data }));
      })
      .catch((err) => {
        console.log("eee", err);
        dispatch(setAlert("error", err?.response?.data?.error));
      });
    // eslint-disable-next-line
  }, []);

  function section1() {
    return (
      <>
        <div className="dot-wrapper">
          <div className="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="27.5" stroke="#F1BA6A" />
            </svg>
          </div>

          <div className="line"></div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>

          <div className="line"></div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
        </div>

        <div>
          {/* <h4>Test Booking for</h4> */}
          <div className="card-wrapper">
            {/* {userForTests && userForTests.length > 0
              ? userForTests.map((curr, idx) => (
                  <BookingUser data={curr} key={idx} />
                ))
              : null}

            <button
              onClick={() => {
                setShow(true);
              }}
              className="add-member-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21 11.25H12.75V3C12.75 2.59 12.41 2.25 12 2.25C11.59 2.25 11.25 2.59 11.25 3V11.25H3C2.59 11.25 2.25 11.59 2.25 12C2.25 12.41 2.59 12.75 3 12.75H11.25V21C11.25 21.41 11.59 21.75 12 21.75C12.41 21.75 12.75 21.41 12.75 21V12.75H21C21.41 12.75 21.75 12.41 21.75 12C21.75 11.59 21.41 11.25 21 11.25Z"
                  fill="white"
                />
              </svg>

              <span>Add Members</span>
            </button> */}
          </div>
        </div>

        <div>
          <div className="price">
            <h4>Cart</h4>
            <h4>Total Amount : ₱{outSorcetotalData + hometotalData}</h4>
          </div>

          {home && home.length > 0
            ? home.map((curr, idx) => {
                return <SelectedTest data={curr} key={idx} />;
              })
            : null}

          {lab && lab.length > 0
            ? lab.map((curr, idx) => {
                return <SelectedTest data={curr} key={idx} />;
              })
            : null}
        </div>

        <button onClick={() => navigate("/collect-location")}>Proceed</button>
      </>
    );
  }

  return (
    <div className="cart-page">
      <Header />
      {!home?.length && !lab?.length ? (
        <div style={{ margin: "8vw 0" }} className="no-data-found">
          No Items In Cart
        </div>
      ) : (
        <div className="section-1">{section1()}</div>
      )}
      <Footer />

      {
        <MemberList
          show={show}
          setShow={setShow}
          childSetShow={setinternalPopup}
        />
      }

      {
        <AddMember
          show={internalPopup}
          setShow={setinternalPopup}
          parentSetShow={setShow}
        />
      }
    </div>
  );
};

export default Cart;
