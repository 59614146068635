import React, { useEffect, useRef, useState } from "react";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useNavigate } from "react-router-dom";
import leftArrow from "Assests/Icons/arrow-narrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { setHmoStep4Action } from "../../Redux/Actions/hmo.action";
import { fetchServiceContent } from "API/get.api";

const Step4 = () => {
  const step4Data = useSelector((state) => state.setHmoStepReducer?.step4);
  const step2Data = useSelector((state) => state.setHmoStepReducer?.step2);
  // const [file1, setFile1] = useState(null)
  // const [file2, setFile2] = useState([])
  const [formData, setFormData] = useState({
    firstName: step4Data?.firstName,
    lastName: step4Data?.lastName,
    specialization: step4Data?.specialization,
    clinicAddress: step4Data?.clinicAddress,
    doctorDiagnosis: step4Data?.doctorDiagnosis,
    otherSymtoms: step4Data?.otherSymtoms,
    file1: step4Data?.file1,
    file2: step4Data?.file2 || 1,
  });

  const [specialist,setSpecialist] = useState([])

  useEffect(() => {
    if (step2Data?.gender !== "Male") {
      setFormData({
        ...formData,
        lastMensturation: step4Data?.lastMensturation
          ? step4Data?.lastMensturation
          : "",
      });
    }
  }, []);


  useEffect(()=>{
    fetchContent()
  },[])

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setSpecialist(res?.data?.serviceContent?.consultation?.our_specialists);
    } catch (e) {
      console.log("err", e);
    }
  }
  let [documentUi, setdocumentUi] = useState(
    Object.keys(formData?.file2)?.length || 1
  );
  const [doc, setDoc] = useState(1);
  const navigate = useNavigate();
  const fileRef1 = useRef();
  const fileRef2 = useRef();
  const dispatch = useDispatch();

  const uploadFile1 = () => {
    fileRef1.current.click();
  };
  const uploadFile2 = () => {
    fileRef2.current.click();
  };

  const addDocument = () => {
    setdocumentUi((prev) => prev + 1);
    //setDoc(prev => prev + 1)
  };

  const handleDoc = (index, files) => {
    //setFile2({ ...file2, [index]: files })
    if (files.length < 1) {
      return;
    }
    if (files.size < 1.5e7) {
      let d = { ...formData.file2, [index]: files };
      setFormData({ ...formData, file2: d });
      //setTempFile(e.target.files[0])
    } else {
      dispatch(setAlert("error", "Please Upload File less than 15 Mb"));
    }
  };
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const fileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (isValidFileUploaded(file)) {
      setFormData({ ...formData, file1: e.target.files[0] });
      //setTempFile(e.target.files[0])
    } else if (file.size > 1.5e7) {
      dispatch(setAlert("error", "Please Upload File less than 15 Mb"));
      //file is invalid
    } else {
      dispatch(setAlert("error", "Please Upload File type .jpg or .png only"));
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // formData.file1 = file1
    // formData.file2 = file2
    console.log("form data", formData);
    if (formData && Object.values(formData)?.includes(undefined)) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      dispatch(setHmoStep4Action(formData));
      navigate("/HMOs/submit-the-request-for-approval");
    }
  };

  return (
    <div className="hmo-step-1">
      <button onClick={() => navigate(-1)} className="backbtn">
        <img src={leftArrow} alt="leftarrow" />
      </button>

      <div className="dot-wrapper">
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Make an Appointment
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line active"></div>

        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Fill Out Patient Details
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line"></div>

        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Fill out HMO Details.
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="dot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Fill Out Doctor’s Details
            <br />
            <small className="fw-semibold" style={{ color: "#F1BA6A" }}>
              Progress
            </small>
          </p>
        </div>
        <div className="line"></div>
        <div className="withoutdot">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
          </svg>
          <p className="step1Content">
            Submit the request for approval.
            <br />
            <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
              Pending
            </small>
          </p>
        </div>
      </div>
      <div className="phoneStepper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <p className="patientDetails" style={{ marginBottom: 0 }}>
            Fill Out Doctor’s Details
          </p>
          <div className="steps">
            Step <span style={{ color: "#F1BA6A" }}>4</span>/5
          </div>
        </div>
        <span className="status">Progress</span>
      </div>

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="stepData"
      >
        <div className="input-wrapper">
          <div>
            <p>First Name *</p>
            <input
              name="firstName"
              value={formData?.firstName}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div>
            <p>Last Name *</p>
            <input
              name="lastName"
              value={formData?.lastName}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Your Name"
            />
          </div>
          <div>
            <p>Specialization *</p>
            <select
              required
              name="specialization"
              value={formData?.specialization}
              onChange={(e) => handleChange(e)}
              id="specialization"
            >
              <option hidden value="">
                Select Specialization
              </option>
              {
                specialist?.length > 0 && specialist.map((item,i)=>(
                  <option key={i} value={item?.heading}>{item?.heading}</option>
                ))
              }
             
            </select>
          </div>
          <div>
            <p>Doctor's Clinic Address/Hospital Affliation: *</p>
            <input
              name="clinicAddress"
              value={formData?.clinicAddress}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Doctor's Clinic Address/Hospital Affliation:"
            />
          </div>
          {step2Data?.gender !== "Male" && (
            <div>
              <p>First Day of last Menstruation: *</p>
              <textarea
                name="lastMensturation"
                value={formData?.lastMensturation}
                onChange={(e) => handleChange(e)}
                rows="3"
                placeholder="Please indicate reason incase not applicable"
              />
            </div>
          )}

          <div>
            <p>Doctor's Diagnosis *</p>
            <textarea
              name="doctorDiagnosis"
              value={formData?.doctorDiagnosis}
              onChange={(e) => handleChange(e)}
              rows="3"
              placeholder="Type Here..."
            />
          </div>
        </div>
        <div className="describesymptoms">
          <p>
            Please describe other symptoms you may be experiencing in relation
            to the doctor's diagnosis. *
          </p>
          <textarea
            name="otherSymtoms"
            value={formData?.otherSymtoms}
            onChange={(e) => handleChange(e)}
            rows="3"
            placeholder="Type Here..."
          />
        </div>
        <h1 className="notes">
          *In order for us to process the request, the HMO requires the initial
          diagnosis of the doctor (e.g. patient condition,rule out, to
          consider).
        </h1>

        <div className="input-wrapper">
          <div className="uploadImageDoc">
            <p>Upload Doctor’s Request *</p>
            <input
              hidden
              ref={fileRef1}
              onChange={fileChange}
              // onChange={e => {
              //     setFile1(e.target.files[0]);
              // }}
              accept="image/png, image/gif, image/jpeg"
              type="file"
              name="file1"
              placeholder="Image"
            />
            <label>
              {formData?.file1?.name ? (
                <strong>{formData?.file1?.name}</strong>
              ) : (
                "Upload"
              )}
            </label>
            <div onClick={uploadFile1} className="uploadFile2">
              Upload
            </div>
            <span>File type .jpg or .png only (Max 15MB)</span>
          </div>

          {/* <div className='uploadImageDoc'>
                        <p>Upload Document *</p>
                        <input
                            hidden
                            ref={fileRef2}
                            onChange={e => {
                                setFile2(e.target.files[0]);
                            }}
                            accept="image/png, image/gif, image/jpeg"
                            type="file"
                            placeholder="Image" />
                        <label>{file2?.name ? file2.name : "Document"}</label>
                        <div onClick={uploadFile2} className='uploadFile2'>Upload</div>
                        <span>Upload supported file (Max 15MB)</span>
                    </div>  */}

          {new Array(documentUi).fill(0).map((d, i) => {
            return (
              <div className="uploadImageDoc">
                <p>Upload Document *</p>
                <input
                  hidden
                  ref={fileRef2}
                  onChange={(e) => {
                    handleDoc(i, e.target.files[0]);
                    //setFile2(e.target.files[0]);
                  }}
                  accept="image/png, image/gif, image/jpeg"
                  type="file"
                  name="file2"
                  placeholder="Image"
                />
                {/* <label>{file2[i]?.name ? file2[i].name : "Document"}</label> */}
                <label>
                  {formData?.file2 && formData?.file2[i]?.name ? (
                    <strong>{formData?.file2[i].name}</strong>
                  ) : (
                    "Document"
                  )}
                </label>
                <div onClick={uploadFile2} className="uploadFile2">
                  Upload
                </div>
                <span>Upload supported file (Max 15MB)</span>
              </div>
            );
          })}
        </div>
        <div className="input-wrapperoutside">
          <div onClick={addDocument} className="uploadFile3">
            + Add Document
          </div>
        </div>

        <div className="nextbtnWrapper">
          <button
            type="submit"
            // onClick={() => navigate("/HMOs/step5")}
            className="nextBtn"
          >
            Next
            <span className="rightArrowWrapper">
              <img src={rightArrow} alt="rightarow" />
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step4;
