import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import aboutUs from "Assests/Images/test-tube-with-blood-sample-covid-19-test 1.png";
import dot3 from "Assests/Images/dot3.svg";
import "./AboutUs.css";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import About from "./About";
import Brand from "./Brand";
import Team from "./Team";
import Service from "./Service";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import { useLocation } from "react-router-dom";
import aboutWrapper from "Assests/Images/aboutWrapper.png";
import Wanner from "Components/Wnner/Wanner";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("aboutTab");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      setActiveTab(tabParam);
    }
  }, [location.search]);

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
    removeTabParam();
  };
  const removeTabParam = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("tab");
    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
    window.history.replaceState(null, "", newPath);
  };

  return (
    <>
      <MetaDetails
        title="Our mission is to provide advanced diagnostic solutions in the Philippines."
        description="Learn about Nederlands Diagnostics, our mission, values, and our dedication to offering top-quality diagnostic testing services across the Philippines."
      />

      <Header />
      <div className="aboutus">
        <Wanner title="About Us" image={aboutWrapper} dot={false} />

        <Tabs
          id="justify-tab-example"
          className="mb-3 about-us-tabs"
          activeKey={activeTab}
          onSelect={handleTabSelect}
        >
          <Tab eventKey="aboutTab" title="About Us">
            <About />
          </Tab>
          <Tab eventKey="brandTab" title="The Brand">
            <Brand />
          </Tab>
          <Tab eventKey="teamsTab" title="Teams">
            <Team />
          </Tab>
          <Tab eventKey="servicesTab" title="Services">
            <Service />
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
