import React, { useEffect } from "react";
import "./Disclaimers.css";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import labs from "Assests/Images/labs.png";
import { useNavigate } from "react-router-dom";
import clientWanner from "Assests/Images/clientWanner.png";
import Wanner from "Components/Wnner/Wanner";
const Disclaimers = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);

  return (
    <>
      <Header />
      <div className="disclaimers">
        <Wanner title="Disclaimer" image={clientWanner} />

        <div className="disclaimerWrapper">
          <p>
            Nederlands Diagnostics Private Inc. has taken all reasonable care in
            developing the website, and we believe that all information is
            accurate at the time of publication or last modification. The
            Company reserves the right to make changes to this site without
            notice. This site contains links to sites outside www.[·] Nederlands
            Diagnostics Private Inc. is not responsible for the content of such
            other websites. Neither Nederlands nor its officers, employees or
            agents shall be liable for any loss, damage or expense arising out
            of any access to or use of this site or any site linked to it. You
            may print copies of the information on this site for your personal
            use and download files for personal use and may not, without our
            permission, copy and distribute this information on any other work,
            publication or website. No reproduction of any part of the site may
            be used for commercial gain.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimers;
