import axios from "axios";
import { setAuthenticationModel } from "../../Redux/Actions/model";
import { signout } from "../../Redux/Actions/set.action";
import store from "../../Redux/store";

let httpPathName = axios.create({
  // baseURL: "http://3.6.44.198:8080/",
  // baseURL: "https://3.6.44.198:8443/",
  baseURL: "https://backend.nederlandsdiagnostics.com/",
  withCredentials: false,
  headers: {
    // 'Access-Control-Allow-Origin': '*'
    "Content-Type": "application/json",
  },
});

// var httpRequest = "";

httpPathName.interceptors.response?.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.error == "jwt expired") {
      store.dispatch(signout());
      // store.dispatch(setAuthenticationModel(true))
    }

    return error;
  }
);

export default httpPathName;
