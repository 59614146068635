import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./BannerSlider.css";
import { useNavigate } from "react-router-dom";
// import Agile from  "Assests/Images/agile.png"
// import aceesable from "Assests/Images/aceesable.png"
// import accurate from "Assests/Images/accurate.png"
// import affordable from "Assests/Images/affordable.png"
import AgileBanner from "Assests/Images/banners/agile-new.webp";
import AccessibleBanner from "Assests/Images/banners/accessible-new.webp";
import AccurateBanner from "Assests/Images/banners/accurate-new.webp";
import AffordableBanner from "Assests/Images/banners/affordable-new.webp";
import Spinner from "react-bootstrap/Spinner";

const BannerSlider = ({ leftSideData, rightSideData }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (leftSideData?.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loading, leftSideData]);

  const CardItem = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${
            leftSideData?.length > 0 ? leftSideData[0] : null
          })`,
        }}
        className="card-item hide-mobile"
      >
        {loading && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
        {/* <div className="holder">
          <h3>Health Testing Made Easy</h3>
          <h6>Convenient At-Home and In-Lab Testing Options</h6>
          <p>
            At PhiSanté, we offer hassle-free health testing with two convenient
            options: At-Home Testing, where we come to you, or In-Lab Visits,
            where you visit us. Both options ensure accurate results and
            excellent service. Your health, your choice.
          </p>

          <button onClick={() => navigate("/services/all-test-package")}>
            Book a test
          </button>
        </div> */}
      </div>
    );
  };

  const CarouselItem = ({ svgi, title }) => {
    return (
      <div className="custom-item">
        {svgi}
        <h6>{title || "No title"}</h6>
        <p>
          Fast Turn Around Time from Sample Extraction (Collection) to Report
          Delivery.
        </p>
      </div>
    );
  };
  const CarouselItem2 = ({ src }) => {
    return (
      <div className="custom-item">
        <img src={src} alt="agile" width="800" height="660" loading="lazy" />
      </div>
    );
  };

  return (
    <div className="banner-slider">
      <CardItem />

      <Carousel interval={5000} controls={false}>
        {rightSideData?.length > 0 ? (
          rightSideData?.map((item, i) => (
            <Carousel.Item key={i}>
              <CarouselItem2 src={item || AgileBanner} />
            </Carousel.Item>
          ))
        ) : (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
        {/* <Carousel.Item>
          <CarouselItem2 src={AgileBanner} />
        </Carousel.Item> */}

        {/* <Carousel.Item>
          <CarouselItem2 src={AccessibleBanner} />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselItem2 src={AccurateBanner} />
        </Carousel.Item>

        <Carousel.Item>
          <CarouselItem2 src={AffordableBanner} />
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
};

export default BannerSlider;
