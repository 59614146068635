import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { addANewMemberApi } from "API/post.api";
import { setContactModel } from "../../../Redux/Actions/model";
import ContactUs from "./ContactUs";

const ContactUsModel = () => {
  const dispatch = useDispatch();
  const contactModel = useSelector((state) => state?.setContactUsModel);
  return (
    <Modal
      show={contactModel}
      onHide={() => {
        dispatch(setContactModel(false));
        //setShow(false);
      }}
      className="contactUs-Model"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <ContactUs />
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModel;
