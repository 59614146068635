// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-icon-text {
  width: 14vw;
  height: 14vw;
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  cursor: pointer;
}

.vertical-icon-text svg path {
  fill: var(--primary-color);
}

.vertical-icon-text span {
  font-size: 1.3vw;
  margin-top: 1vw;
}

.vertical-icon-text svg {
  width: 8vw;
  height: 7vw;
}
.vertical-icon-text img {
  width: 8vw;
  height: 7vw;
}

.vertical-icon-text:hover {
  background: #f99d1c;
  color: white;
  transform: scale(1.05);
}

.vertical-icon-text:hover svg path {
  fill: white;
}

@media screen and (max-width: 601px) {
  .vertical-icon-text {
    width: 48%;
    margin-bottom: 2vw;
    height: 20vw;
  }
  .vertical-icon-text svg {
    height: unset;
    width: 10vw;
  }
  .vertical-icon-text img {
    height: unset;
    width: 10vw;
  }
  .vertical-icon-text span {
    font-size: 3.5vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Cards/VerticalIconText/VerticalIconText.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,UAAU;IACV,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".vertical-icon-text {\n  width: 14vw;\n  height: 14vw;\n  border-radius: 16px;\n  background: #fff;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  color: var(--primary-color);\n  cursor: pointer;\n}\n\n.vertical-icon-text svg path {\n  fill: var(--primary-color);\n}\n\n.vertical-icon-text span {\n  font-size: 1.3vw;\n  margin-top: 1vw;\n}\n\n.vertical-icon-text svg {\n  width: 8vw;\n  height: 7vw;\n}\n.vertical-icon-text img {\n  width: 8vw;\n  height: 7vw;\n}\n\n.vertical-icon-text:hover {\n  background: #f99d1c;\n  color: white;\n  transform: scale(1.05);\n}\n\n.vertical-icon-text:hover svg path {\n  fill: white;\n}\n\n@media screen and (max-width: 601px) {\n  .vertical-icon-text {\n    width: 48%;\n    margin-bottom: 2vw;\n    height: 20vw;\n  }\n  .vertical-icon-text svg {\n    height: unset;\n    width: 10vw;\n  }\n  .vertical-icon-text img {\n    height: unset;\n    width: 10vw;\n  }\n  .vertical-icon-text span {\n    font-size: 3.5vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
