import React, { useEffect, useState } from "react";
import TeamM1 from "Assests/Images/team-m1.png";
import TeamM2 from "Assests/Images/team-m2.png";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";

const Team = () => {
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  // const [data, setData] = useState({
  //   management: [],
  //   philipins: [],
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const fetchData = async () => {
  //   const apiStatus = await httpPathName
  //     .get("aboutUs/getTeamList", { headers: { "x-auth-token": token } })
  //     .then((response) => {
  //       // dispatch(setAlert("success", response?.data?.message));
  //       setData({
  //         management: response?.data?.data?.management_team,
  //         philipins: response?.data?.data?.philippines_team,
  //       });
  //       // setLoading(false);
  //       //setShow(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       dispatch(setAlert("error", err?.response?.data?.message));
  //       // setLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  const getData = async () => {
    const apiStatus = await httpPathName
      .get(`admin/content/get-teamsContentbyId`)
      .then((response) => {
        console.log("respoinse", response);
        setData(response?.data?.teamscontent[0]);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const CardItem = ({ image, title, desc }) => {
    return (
      <>
        <div className="card-item">
          <img src={image} alt="team" />
          <p className="title">{title || "no title"}</p>
          <p>{desc || "no desc"}</p>
        </div>
      </>
    );
  };

  return (
    <div className="about-team">
      <div className="section1">
        <h3>
          Our <p className="bold-head">Teams</p>
        </h3>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="8"
          viewBox="0 0 50 8"
          fill="none"
        >
          <rect width="8" height="8" rx="4" fill="#2A364E" />
          <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
          <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
        </svg> */}
        <p>{data?.ourTeams?.description}</p>
      </div>

      <div className="section-2">
        <div>
          <div className="slider">
            <h5>
              Management <p className="bold-head">Team</p>
            </h5>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="8"
              viewBox="0 0 50 8"
              fill="none"
            >
              <rect width="8" height="8" rx="4" fill="#2A364E" />
              <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
              <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
            </svg> */}
            <div className="wrapper">
              {data?.managementTeam?.length > 0 &&
                data?.managementTeam?.map((item) => (
                  <CardItem
                    image={item?.image}
                    title={item?.name}
                    desc={item?.designation}
                  />
                ))}
            </div>
          </div>

          {/* <div className="slider">
            <h5>
              Philippines <p className="bold-head">Team</p>
            </h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="8"
              viewBox="0 0 50 8"
              fill="none"
            >
              <rect width="8" height="8" rx="4" fill="#2A364E" />
              <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
              <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
            </svg>
            <div className="wrapper">

            {data?.philipins?.length > 0 &&
                data?.philipins?.map((item) => (
                  <CardItem
                    image={item?.image}
                    title={item?.name}
                    desc={item?.designation}
                  />
                ))}
           
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Team;
