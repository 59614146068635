import React from "react";
import BackButton from "Components/BackButton/BackButton";

const Cancel = () => {
  return (
    <div className="cancel">
      <div className="backbtn">
        <BackButton />
      </div>
      <h1>Your Payment Failed or Cancelled</h1>
    </div>
  );
};

export default Cancel;
