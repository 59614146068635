import { SET_FILTER_MODEL } from "../../Redux/action.type";

const initialState = {
  organs: [],
  condition: [],
  gender: [],
  // radiology_test: [],
  category: "",
  search: "",
};

export const setFilterState = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_MODEL:
      if (!action.payload) {
        action.payload = initialState;
      }
      state = action.payload;
      return state;
    default:
      return state;
  }
};
