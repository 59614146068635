import React, { useEffect, useState } from "react";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import { setHmoStep1Action } from "../../Redux/Actions/hmo.action";
import "./HMOs.css";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";

const Step1 = () => {
  const step1Data = useSelector((state) => state.setHmoStepReducer.step1);
  const [formData, setFormData] = useState({
    hpd_branch: step1Data?.hpd_branch,
    data_of_visit: step1Data?.data_of_visit,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //     if (Object.keys(step1Data).length > 0) {
  //         setFormData({
  //             branch : step1Data.branch,
  //             date : step1Data.date
  //         })
  //     }
  // }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("form data", formData);
    if (Object.values(formData).includes(undefined)) {
      dispatch(setAlert("error", "All fields are required!"));
    } else {
      dispatch(setHmoStep1Action(formData));
      navigate("/HMOs/fill-out-patient-details");
    }
  };

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Nederlands Diagnostics Promotes HMO Partnerships","HMO partners can book appointments with Nederlands Diagnostics for their members. Book diagnostic tests and consultations conveniently to ensure timely healthcare delivery.")

  return (
    <>
      <MetaDetails
        title="Nederlands Diagnostics Promotes HMO Partnerships"
        description="HMO partners can book appointments with Nederlands Diagnostics for their members. Book diagnostic tests and consultations conveniently to ensure timely healthcare delivery."
      />

      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        className="hmo-step-1"
      >
        <div className="hmo-para-Wrapper">
          <p>
            Complete the form below to process your HMO requests at least 2 days
            prior to your visit.
          </p>
          <p>
            You will be required to upload your valid ID, HMO card, and test
            request issued by an HMO accredited doctor with corresponding
            diagnosis. Unmatched details may result in delay of approval. We
            will contact you to verify some information if necessary.​
          </p>
          <p>
            Kindly note submission of this form should be at least 2 days before
            the scheduled date and that approved requests are only valid within
            2 days from appointment date.
          </p>
          <p>
            You will receive a confirmation email once your request has been
            successfully received. Please bear in mind that you will receive
            another confirmation email or phone call from our staff once your
            booking and approval have been confirmed.
          </p>
        </div>

        <div className="dot-wrapper">
          <div className="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="27.5" stroke="#F1BA6A" />
            </svg>
            <p className="step1Content">
              Make an Appointment
              <br />
              <small className="fw-semibold" style={{ color: "#F1BA6A" }}>
                Progress
              </small>
            </p>
          </div>

          <div className="line"></div>

          <div className="withoutdot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
            </svg>
            <p className="step1Content">
              Fill Out Patient Details
              <br />
              <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
                Pending
              </small>
            </p>
          </div>

          <div className="line"></div>
          <div className="withoutdot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
            </svg>
            <p className="step1Content">
              Fill out HMO Details.
              <br />
              <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
                Pending
              </small>
            </p>
          </div>

          <div className="line"></div>
          <div className="withoutdot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
            </svg>
            <p className="step1Content">
              Fill Out Doctor’s Details
              <br />
              <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
                Pending
              </small>
            </p>
          </div>

          <div className="line"></div>
          <div className="withoutdot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
            </svg>
            <p className="step1Content">
              Submit the request for approval.
              <br />
              <small className="fw-semibold" style={{ color: "#D9D9D9" }}>
                Pending
              </small>
            </p>
          </div>
        </div>
        <div className="phoneStepper">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <p className="patientDetails" style={{ marginBottom: 0 }}>
              Fill Out Patient Details
            </p>
            <div className="steps">
              Step <span style={{ color: "#F1BA6A" }}>1</span>/5
            </div>
          </div>
          <span className="status">Progress</span>
        </div>

        <div className="stepData">
          <div className="input-wrapper">
            <div>
              <p>Preferred Date Of Visit</p>
              <input
                required
                name="data_of_visit"
                type="date"
                placeholder="123"
                value={formData?.data_of_visit}
                onChange={(e) => handleChange(e)}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <p>Select Branch:</p>
              <select
                name="hpd_branch"
                id="hpd_branch"
                value={formData?.hpd_branch}
                onChange={(e) => handleChange(e)}
                required
              >
                <option hidden value="">
                  Select Branch
                </option>
                <option value="Quezon City">Quezon City</option>
              </select>
            </div>
          </div>
          <div className="nextbtnWrapper">
            <button
              type="submit"
              // onClick={() => navigate("/HMOs/step2")}
              className="nextBtn"
            >
              Next
              <span className="rightArrowWrapper">
                <img src={rightArrow} alt="rightarow" />
              </span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step1;
