import React, { useState, useEffect } from "react";
import "./Radiology.css";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import diagnostics from "Assests/Images/diagnostics.png";
import { Card, Form, InputGroup, Nav } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "Assests/Icons/searchIcon.svg";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import RightArrow from "Assests/Icons/RightArrow.svg";
import blogPic from "Assests/Images/Rectangle 116.png";
import { useNavigate } from "react-router-dom";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";
import { bookNowApi } from "API/post.api";
import { setAuthenticationModel } from "../../Redux/Actions/model";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";
import { fetchServiceContent } from "API/get.api";
import Search from "Components/Common/Search/Search";

const navKeys = {
  INDIVIDUAL: "Individual",
  PACKAGE: "Package",
};

const Radiology = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [activeNavKey, setActiveNavKey] = useState(navKeys.INDIVIDUAL);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchContent();
  }, []);

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setContent(res?.data?.serviceContent?.radiology);
    } catch (e) {
      console.log("err", e);
    }
  }

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Get advanced radiology services now in the Philippines.","Take the benefit of Nederlands Diagnostics' advanced radiology services, providing precise imaging solutions to aid in diagnosis and treatment planning.")

  const fetchRadiologyData = async () => {
    setLoading(true);
    const formData = {
      keyword: search,
    };
    const apiStatus = await httpPathName
      .post(
        "home/getRadiologyTests",
        { ...formData },
        { headers: { "x-auth-token": token } }
      )
      .then((response) => {
        // dispatch(setAlert("success", response?.data?.message));
        setData(response?.data?.data);
        setLoading(false);
        //setShow(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetchRadiologyData();
  // }, [search]);

  let debounceTimer;
  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  useEffect(() => {
    debounce(() => fetchRadiologyData(), 500);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [search]);

  const CardItem = ({
    id,
    img,
    alt,
    title,
    short_desc,
    price,
    original_price,
    include,
    parameter,
    long_desc,
    key,
  }) => {
    const bookNow = (e) => {
      e.stopPropagation();
      if (!token) {
        // Replace False with true to Enable Auth Modal
        dispatch(setAuthenticationModel(true));
      } else {
        const apiStatus = bookNowApi(token, id);
        apiStatus
          .then((response) => {
            navigate("/cart");
          })
          .catch((err) => {
            console.log(err);
            dispatch(setAlert("error", err?.response?.data?.error));
          });
      }
    };

    return (
      <div
        // onClick={() => navigate(`/services/${id}`)}
        className="card-item"
      >
        <img src={img} alt={alt} />
        <div className="main-content">
          <h6>{title || "No Title"}</h6>
          {/* <p>{short_desc || "No Description"}</p> */}
          <span>₱ {price}</span>
          <button className="book-btn flexRowAlignJustify" onClick={bookNow}>
            <CartIcon style={{ marginRight: "8px" }} /> Book Now
          </button>
        </div>
      </div>
    );
  };

  function section1() {
    return (
      <>
        <div className="section1-Wrapper">
          <p>{content?.description || ""}</p>
          <h2 className="section-title">
            why <span style={{ fontWeight: "500" }}>choose us</span>
          </h2>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#A71F23" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <div className="teamWrapper">
            {content?.why_choose_us?.length > 0 &&
              content?.why_choose_us?.map((item, i) => (
                <div key={i} className="card">
                  <div className="cardData">
                    <img src={item?.image} alt={item?.heading} />
                    <div>
                      <h1>{item?.heading || ""}</h1>
                      <p>{item?.description || ""}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <h2>
            Book an appointment below or get in touch with us @{" "}
            <a
              className="text-decoration-none"
              href="tel:+632 8929 4422"
              style={{ color: "#A71F23", fontWeight: "500" }}
            >
              +632 8929 4422
            </a>
          </h2>
        </div>
      </>
    );
  }

  function section2() {
    return (
      <>
        <div className="section2-Wrapper">
          <div className="searchTabWrapper">
            <Search
              placeholder="Search Test or Health packages"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDetails
        title="Get advanced radiology services now in the Philippines."
        description="Take the benefit of Nederlands Diagnostics' advanced radiology services, providing precise imaging solutions to aid in diagnosis and treatment planning."
      />

      <Header />
      <div className="radiology">
        <Wanner title="Radiology" image={diagnostics} dot={false} />

        <div className="section1">{section1()}</div>
        <div className="section2">{section2()}</div>
        <div className="section3">
          {data?.length > 0 ? (
            <div className="cardWrapper">
              {data?.map((item, i) => (
                <CardItem
                  key={i}
                  id={item?._id}
                  img={item?.image}
                  alt={item?.test_name}
                  title={item?.test_name}
                  short_desc="Embrace wellness with our Grand Package: specialized lab tests for seniors. 
                Embrace wellness with our Grand Package: specialized lab tests."
                  price={item?.price || item?.mrp}
                  //original_price={item?.price}
                  include={7}
                  parameter={76}
                />
              ))}
            </div>
          ) : !!loading ? (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              <Spinner />
            </div>
          ) : (
            <div style={{ margin: "auto", width: "100%" }}>No Data Found</div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Radiology;
{
  /* <div style={{ margin: 'auto' }}><Spinner /></div> */
}
