import React from 'react'
import searchIcon from "Assests/Icons/searchIcon.svg"
import rightarrowwhite from "Assests/Icons/rightarrowwhite.svg"
import download from "Assests/Icons/download.svg"

const HistoryPhone = () => {
    const historyData = [
        {
            date: "28.10.2022",
            name: "Shubham kumar sharma",
            contactNumber: "+91 9500036549",
            package: "Package",
            amount: "₱800",
        },
        {
            date: "28.10.2023",
            name: "Shubham kumar",
            contactNumber: "+91 9500036565",
            package: "Package",
            amount: "₱500",
        }
    ]
    return (
        <div className='historyPhone'>
            <div className='historyWrapper'>
                <h1>Overall History</h1>
                <div className='inputWrapper'>
                    <img src={searchIcon} />
                    <input />
                    <div className='inputsubmit'>
                        <img src={rightarrowwhite} alt='rightarrow' />
                    </div>

                </div>
            </div>
            {
                historyData.map((item, i) => (
                    <div key={i} className='historyCard'>
                        <div className='oneLine'>
                            <div className='name'>
                                <p>Name</p>
                                <span>{item.name}</span>
                            </div>
                            <p className='date'>Date : <span className='datecontent'>{item.date}</span></p>
                        </div>
                        <div className='oneLineData'>
                            <p>Contact:</p>
                            <span>{item.contactNumber}</span>
                        </div>
                        <div className='oneLineData'>
                            <p>Package</p>
                            <span>{item.package}</span>
                        </div>
                        <div className='oneLine'>
                            <div className='name'>
                                <p>Amount</p>
                                <span>{item.amount}{i%2==0 ? 
                            <span className='paid'>(Paid)</span>
                            :<span className='unpaid'>(Unpaid)</span>}</span>
                            </div>
                            <p className='date'>Report<img className='download' src={download} alt='downlaod' /></p>
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default HistoryPhone