// Stepper.js
import React from "react";
import stepper from "./stepper.css";
import Step from "./Step";

const Stepper = ({ steps }) => {
  return (
    <div className="custom-stepper">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <Step
            stepNumber={step.stepNumber}
            title={step.title}
            status={step.status}
          />
          {index < steps.length - 1 && (
            <div
              style={{
                background: ["in-progress", "pending"].includes(step?.status)
                  ? "#F1BA6A"
                  : "#A71F23",
              }}
              className="line"
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
