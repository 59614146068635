import React from "react";
import "./Button.css";
import arrow from "../../../Assests/Icons/right-arrow-7288805.svg";

const NextBtn = ({ onClick }) => {
  return (
    <div>
      <button onClick={onClick} className="custom-next-btn">
        Next
        <div className="arrow-wrapper">
          <img src={arrow} alt="arrow" />
        </div>
      </button>
      ;
    </div>
  );
};

export default NextBtn;
