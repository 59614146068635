import React from "react";
import BookingUser from "Components/Cards/BookingUser/BookingUser";
import "./MemberList.css";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import { memberListApi } from "API/get.api";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserForTestAction,
  setMemberList,
} from "../../../Redux/Actions/set.action";

const MemberList = ({ show, setShow, childSetShow }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("access_token");
  const userForTests = useSelector((state) => state.setUserForTestReducer);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState(
    userForTests ? userForTests : []
  );
  const [membersList, setMembersList] = useState([]);
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    const apiStatus = memberListApi(token);
    apiStatus
      .then((response) => {
        setMembersList(response?.data?.data?.relation);
        dispatch(setMemberList(response?.data?.data?.relation));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  }, [show]);

  useEffect(() => {
    // push keys to flag
    for (let i of selectedFamilyMembers) {
      setFlags([...flags, i?._id]);

      // also styling that div
      if (i?._id) {
        const element = document.getElementById(i._id)?.children[0];
        if (element) {
          element.style.backgroundColor = "#4a89d0";
          element.style.color = "white";
        }
      }
    }
  }, [show]);

  const handleChange = (e) => {
    if (flags.includes(e._id)) {
      setSelectedFamilyMembers(
        selectedFamilyMembers.filter((item) => item._id !== e._id)
      );
    } else setSelectedFamilyMembers([...selectedFamilyMembers, e]);
  };

  const changeStyleOnSelect = (id) => {
    if (flags.includes(id)) {
      const element = document.getElementById(id).children[0];
      element.style.backgroundColor = "white";
      element.style.color = "#2a364e";
    } else {
      const element = document.getElementById(id).children[0];
      element.style.backgroundColor = "#4a89d0";
      element.style.color = "white";
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      className="member-list"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {membersList && membersList.length > 0
          ? membersList.map((curr, idx) => {
              return (
                <div
                  key={idx}
                  id={curr._id}
                  onClick={() => {
                    if (flags.includes(curr._id)) {
                      // deselect
                      setFlags(flags.filter((item) => item !== curr._id));
                    } else {
                      // select
                      setFlags([...flags, curr._id]);
                    }
                    handleChange(curr);
                    changeStyleOnSelect(curr._id);
                  }}
                >
                  <BookingUser data={curr} />
                </div>
              );
            })
          : null}
      </Modal.Body>

      <div className="button-wrapper">
        <button
          onClick={() => {
            childSetShow(true);
            setShow(false);
          }}
          className="add-member-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 11.25H12.75V3C12.75 2.59 12.41 2.25 12 2.25C11.59 2.25 11.25 2.59 11.25 3V11.25H3C2.59 11.25 2.25 11.59 2.25 12C2.25 12.41 2.59 12.75 3 12.75H11.25V21C11.25 21.41 11.59 21.75 12 21.75C12.41 21.75 12.75 21.41 12.75 21V12.75H21C21.41 12.75 21.75 12.41 21.75 12C21.75 11.59 21.41 11.25 21 11.25Z"
              fill="white"
            />
          </svg>

          <span>Add a new member</span>
        </button>
        <button
          onClick={() => {
            dispatch(setUserForTestAction(selectedFamilyMembers));
            setShow(false);
          }}
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default MemberList;
