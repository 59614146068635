import React, { useState, useEffect } from "react";
import "./Employment.css";
import diagnostics from "Assests/Images/diagnostics.png";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import dot3 from "Assests/Images/dot3.svg";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";
import { bookNowApi } from "API/post.api";
import { setAuthenticationModel } from "../../Redux/Actions/model";
import { useNavigate } from "react-router";
import { ReactComponent as SearchIcon } from "Assests/Icons/searchIcon.svg";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";
import { fetchServiceContent } from "API/get.api";

const nav = [
  "Annual Physical Examination",
  "Pre-Employment Checkup",
  "Preventive Health Packages",
  "OncoNed",
];

const Employment = () => {
  const token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchContent();
  }, []);

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setContent(res?.data?.serviceContent?.wellness_package);
    } catch (e) {
      console.log("err", e);
    }
  }

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Our wellness packages promote holistic health.","Check out our wellness packages designed to promote holistic health and well-being through comprehensive diagnostic assessments. Now Book them from your home.")

  const fetchEmployData = async () => {
    setLoading(true);

    const apiStatus = await httpPathName
      .get("home/getEmploymentCheckTestList", {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        // dispatch(setAlert("success", response?.data?.message));
        setData(response?.data?.data);
        setLoading(false);
        //setShow(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmployData();
  }, []);

  const CardItem = ({
    img,
    alt,
    title,
    short_desc,
    price,
    original_price,
    include,
    parameter,
    long_desc,
    key,
    id,
  }) => {
    return (
      <div
        // onClick={() => navigate(`/services/${id}`)}
        className="card-item"
      >
        <img src={img || diagnostics} alt={alt} />
        <div className="main-content">
          <h6 className="card-title h5">{title || "No Title"}</h6>
          {/* <p className="description">{short_desc || "No Description"}</p> */}
          <span>₱ {price}</span>
          <button
            className="book-btn flexRowAlignJustify"
            onClick={(e) => {
              e.stopPropagation();
              if (!token) {
                // Replace False with true to Enable Auth Modal
                dispatch(setAuthenticationModel(true));
              } else {
                const apiStatus = bookNowApi(token, id);
                apiStatus
                  .then((response) => {
                    console.log(response);
                    navigate("/cart");
                  })
                  .catch((err) => {
                    console.log(err);
                    dispatch(setAlert("error", err?.response?.data?.error));
                  });
              }
            }}
          >
            <CartIcon style={{ marginRight: "8px" }} /> Book Now
          </button>
        </div>
      </div>
    );
  };

  function section1() {
    return (
      <>
        <div className="section1-warpper">
          {/* <h1>Wellness Packages</h1>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#2A364E" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <p>{content?.description || ""}</p>
        </div>
      </>
    );
  }

  function section2() {
    return (
      <>
        <div className="section2-Wrapper">
          <h1>
            Our <span style={{ fontWeight: "500" }}>Offerings</span>
          </h1>
        
          <div className="offeringTabWrapper">
            {content?.our_offerings?.length > 0 &&
              content?.our_offerings.map((item, i) => (
                <div
                  onClick={() => setSelected(i)}
                  className={`${
                    i == selected ? "offeringTab" : "offeringTab-n-Sel"
                  }`}
                  key={i}
                >
                  {item}
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }

  function section3() {
    return (
      <>
        <div>
          {data?.length > 0 ? (
            <div className="cardWrapper">
              {data?.map((item, i) => (
                <CardItem
                  key={i}
                  img={item?.image}
                  alt={item?.test_name}
                  title={item?.test_name}
                  short_desc={item?.intented_use}
                  price={item?.mrp}
                  original_price={item?.price}
                  include={7}
                  parameter={76}
                  id={item?._id}
                />
              ))}
            </div>
          ) : !!loading ? (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              <Spinner />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center"
              style={{ margin: "auto", width: "100%" }}
            >
              No Data Found
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDetails
        title="Our wellness packages promote holistic health."
        description="Check out our wellness packages designed to promote holistic health and well-being through comprehensive diagnostic assessments. Now Book them from your home."
      />

      <Header />
      <div className="employment">
        <Wanner title="Wellness Packages" image={diagnostics} dot={false} />

        <div className="section1">{section1()}</div>
        <div className="section2">{section2()}</div>
        <div className="section3">{section3()}</div>
      </div>
      <Footer />
    </>
  );
};

export default Employment;
