import React from "react";
import "./HorizontalIconText.css";

const HorizontalIconText = ({ svg, image, title, onClick }) => {
  return (
    <div className="horizontal-icon-text" onClick={onClick}>
      {/* {svg && svg} */}
      {image ? <img src={image} alt={title} /> : svg}
      <span>{title || "No Title"}</span>
    </div>
  );
};

export default HorizontalIconText;
