import React from 'react'
import './Services.css'
import ServicesList from './ServicesList'
import ServiceDetailView from './ServiceDetailView'
import Header from 'Components/Header/Header'
import Footer from 'Components/Footer/Footer'
import { useParams } from 'react-router'
// import useMetaTags from 'Components/Hooks/useMetaTags'
import MetaDetails from "Components/MetaDetails/MetaDetails";

const Services = () => {
  const { id } = useParams()

  // useMetaTags("We offer diagnostic testing packages to diversified clients.","Explore Nederlands Diagnostics' comprehensive testing packages, offering a wide range of diagnostic tests tailored to meet your healthcare needs.")

  return (
    <>
      <MetaDetails
        title="We offer diagnostic testing packages to diversified clients."
        description="Explore Nederlands Diagnostics' comprehensive testing packages, offering a wide range of diagnostic tests tailored to meet your healthcare needs."
      />

      <Header />
      {Boolean(id) ? <ServiceDetailView id={id} /> : <ServicesList />}
      <Footer />
    </>
  )
}

export default Services