import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import Wanner from "Components/Wnner/Wanner";
import diagnostics from "Assests/Images/diagnostics.png";
import Stepper from "Components/Common/Stepper/Stepper";

const ConsultationAppointment = () => {
  const location = useLocation();
  const [steps, setSteps] = useState([
    {
      stepNumber: 1,
      title: "Appointment Details",
      status: "pending",
      url: "appointment-details",
    },
    {
      stepNumber: 2,
      title: "Fill Out Patient Details",
      status: "pending",
      url: "patient-details",
    },
    {
      stepNumber: 3,
      title: "Submit the request for approval",
      status: "pending",
      url: "submit-request",
    },
  ]);

  useEffect(() => {
    // Update step statuses based on the current route
    const updateStepStatuses = () => {
      const pathname = location.pathname;
      const queryParams = new URLSearchParams(location.search);
      const submit = queryParams.get("submit");

      const newSteps = steps.map((step, index) => {
        if (pathname.includes("appointment-details") && step.stepNumber === 1) {
          return { ...step, status: "in-progress" };
        } else if (
          pathname.includes("patient-details") &&
          step.stepNumber === 2
        ) {
          return { ...step, status: "in-progress" };
        } else if (
          pathname.includes("submit-request") &&
          step.stepNumber === 3
        ) {
          if (submit === "true") {
            return { ...step, status: "completed" };
          }
          return { ...step, status: "in-progress" };
        } else if (
          index <
          steps.findIndex(
            (s) =>
              s?.url === pathname.split("/")[pathname.split("/").length - 1]
          )
        ) {
          return { ...step, status: "completed" };
        }
        return { ...step, status: "pending" };
      });

      setSteps(newSteps);
    };

    updateStepStatuses();
  }, [location]);

  return (
    <>
      <Header />
      <div>
        <Wanner title="Consultation" image={diagnostics} dot={false} />
        <Stepper steps={steps} />
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default ConsultationAppointment;
