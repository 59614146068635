import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import PioneerIcon from "Assests/Icons/pioneerIcon.svg";
import HqIcon from "Assests/Icons/hqIcon.svg";
import InvestorsIcon from "Assests/Icons/investorsIcon.svg";
import "./BrandInfoSlider.css";

const BrandInfoSlider = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1050 },
            items: 3,
        },
        medDesktop1: {
            breakpoint: { max: 1750, min: 1550 },
            items: 3,
            partialVisibilityGutter: 700,
        },
        medDesktop2: {
            breakpoint: { max: 1550, min: 1350 },
            items: 3,
            partialVisibilityGutter: 500,
        },
        laptop: {
            breakpoint: { max: 1350, min: 1150 },
            items: 2,
            partialVisibilityGutter: 300,
        },
        tablet: {
            breakpoint: { max: 1150, min: 464 },
            items: 2,
            partialVisibilityGutter: 0, // Ensure no partial visibility on smaller screens
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0, // Ensure no partial visibility on smaller screens
        },
    };

    const CardItem = ({
        img,
        alt,
        title,
        short_desc
    }) => {
        return (
            <div className="card-item">
                <img className='img-fluid' src={img} alt={alt} />
                <p className='title'>
                    {title}
                </p>
                <p className='desc'>
                    {short_desc}
                </p>
            </div>
        );
    };

    return (
        <div className="carousel-container-brand">
            <Carousel 
                containerClass='brand-info-slider' 
                responsive={responsive} 
                arrows={false} 
                showDots 
                renderDotsOutside 
                centerMode={false} // Ensure centerMode is disabled to avoid centering issues
            >
                <div>
                    <CardItem
                        img={PioneerIcon}
                        alt="PioneerImage"
                        title="Pioneer"
                        short_desc="Founded in 2015"
                    />
                </div>

                <div>
                    <CardItem
                        img={HqIcon}
                        alt="HqImage"
                        title="HQ"
                        short_desc="Based in the PhiSanté with management teams in the Middle East and India"
                    />
                </div>

                <div>
                    <CardItem
                        img={InvestorsIcon}
                        alt="InvestorsImage"
                        title="Investors"
                        short_desc="Backed by private investors and promoters’ wealth"
                    />
                </div>
            </Carousel>
        </div>
    );
};

export default BrandInfoSlider;
