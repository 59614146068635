import React from "react";
import right from "../../../Assests/Icons/right-red-icon (1).svg";
import left from "../../../Assests/Icons/left-red-icon (2).svg";

const PrevNextBtn = ({ title, onClick, disabled }) => {
  return (
    <div className="prev-next-btn">
      <button type="button" disabled={disabled} onClick={onClick}>
        <p className={`${title == "Next" ? "right" : "left"}`}>
          {title || "Next"}
        </p>
        <div
          className={`${
            title == "Next" ? "img-container-right" : "img-container-left"
          }`}
        >
          {title == "Next" ? (
            <img src={right} alt="right" />
          ) : (
            <img src={left} alt="left" />
          )}
        </div>
      </button>
    </div>
  );
};

export default PrevNextBtn;
