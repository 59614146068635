import React, { useState } from "react";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useNavigate } from "react-router-dom";
import leftArrow from "Assests/Icons/arrow-narrow-left.svg";
import roundedRed from "Assests/Icons/rounded-red.svg";
import check from "Assests/Icons/check.svg";
import { makeAppointment } from "API/post.api";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import moment from "moment";

const Step5 = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const step1Data = useSelector((state) => state.setHmoStepReducer?.step1);
  const step2Data = useSelector((state) => state.setHmoStepReducer?.step2);
  const step3Data = useSelector((state) => state.setHmoStepReducer?.step3);
  const step4Data = useSelector((state) => state.setHmoStepReducer?.step4);
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);

  const submitApproval = async () => {
    const formData = new FormData();
    for (let i = 0; i < Object.values(step4Data.file2).length; i++) {
      formData.append("files", Object.values(step4Data.file2)[i]);
    }
    let data = {
      data_of_visit: moment(step1Data.data_of_visit).format("DD-MM-YYYY"),
      hpd_branch: step1Data.hpd_branch,
      patient_first_name: step2Data.patient_first_name,
      patient_last_name: step2Data.patient_last_name,
      gender: step2Data.gender,
      date_of_birth: moment(step2Data.date_of_birth).format("DD-MM-YYYY"),
      contact_number: step2Data.contact_number,
      patient_type_of_validID: step2Data.patient_type_of_validID,
      validID_number: step2Data.validID_number,
      patient_image: step2Data.file,
      hmo_card: step3Data.hmoCard,
      hmo_id_or_policy_number: step3Data.policyNumber,
      company_name: step3Data.companyName,
      hmo_upload_image: step3Data.file,
      doctor_first_name: step4Data.firstName,
      doctor_last_name: step4Data.lastName,
      specialization: step4Data.specialization,
      doctor_clinic_address: step4Data.clinicAddress,
      first_day_of_last_menstruation: step4Data.lastMensturation,
      doctor_diagnosis: step4Data.doctorDiagnosis,
      symptoms_experienced: step4Data.otherSymtoms,
      doctors_request_upload_image: step4Data.file1,
      doctor_upload_document: step4Data.file2[0],
    };

    const apiStatus = makeAppointment(token, data);
    apiStatus
      .then((response) => {
        if (response?.status == 200 || response?.status == 201) {
          dispatch(setAlert("success", response?.data?.message));
          setStatus(true);
        }
      })
      .catch((e) => {
        dispatch(setAlert("error", e?.response?.data?.message));
        console.log(e);
      });
  };
  return (
    <div className="hmo-step-1">
      <button onClick={() => navigate(-1)} className="backbtn">
        <img src={leftArrow} alt="leftarrow" />
      </button>

      <div className="dot-wrapper">
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p className="step1Content">
            Make an Appointment
            <br />
            <small className="fw-semibold" style={{ color: "#A71F23" }}>
              Completed
            </small>
          </p>
        </div>

        <div className="line active"></div>

        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="step1Content">Fill Out Patient Details</p>
        </div>

        <div className="line"></div>

        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="step1Content">Fill out HMO Details.</p>
        </div>
        <div className="line"></div>
        <div className="check">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="step1Content">Fill Out Doctor’s Details</p>
        </div>
        <div className="line"></div>
        {!status ? (
          <div className="dot">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
            >
              <circle cx="28" cy="28" r="28" fill="#D9D9D9" />
            </svg>
            <p className="step1Content">Submit the request for approval.</p>
          </div>
        ) : (
          <div className="check">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M5.33203 16.8148L11.8961 23.3333L26.6654 8.66666"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="step1Content">Submit the request for approval.</p>
          </div>
        )}
      </div>

      <div className="stepData">
        <h1 className="thankyous">THANK YOU!</h1>
        {!status ? (
          <div>
            <p className="submitapproval">Submit the request for Approval</p>
            <div className="submitWrapper">
              <button onClick={submitApproval} className="finalsubmit">
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="thankYouWrapper">
            <img src={roundedRed} alt="roundedred" />
            <img className="checked" src={check} alt="check" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Step5;
