import React from "react";
import "./Wanner.css";
import dot3 from "Assests/Images/dot3.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const Wanner = ({ title, description = "", image, dot = true }) => {
  const navigate = useNavigate();

  const beforeStyle = {
    content: '""',
    backgroundImage: `url(${image})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    // backgroundPosition: "right",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.3,
    position: "absolute",
  };
  return (
    <div className="Wanner">
      <div style={beforeStyle}></div>
      <div className="overlay"></div>
      <div className="content-wrapper">
        <h1 className="wannerTitle">{title}</h1>
        {/* {dot && <img className="wanner3dot" src={dot3} alt="dot3" />} */}

        {description && <p className="wannerContent">{description}</p>}
      </div>
      {/* <div className="wannerBelowContent">
        <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
          Home
        </span>
        / <span style={{ color: "#A71F23" }}>{title}</span>
      </div> */}
    </div>
  );
};

export default Wanner;
