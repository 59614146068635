import React, { useEffect, useState } from "react";
import searchIcon from "Assests/Icons/searchIcon.svg";
import rightarrowwhite from "Assests/Icons/rightarrowwhite.svg";
import download from "Assests/Icons/download.svg";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Search from "Components/Common/Search/Search";

const History = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [historyData, setHistoryData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  let serialNumber = 1;

  const getHistory = async () => {
    setLoading(true);
    const apiStatus = await httpPathName
      .get(`users/history-orders?testName=${search}`, {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        if (response?.status === 200) {
          setHistoryData(response?.data?.History);
        } else {
          dispatch(setAlert("error", response?.response?.data?.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "History Fetched Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHistory();
  }, [search]);

  return (
    <div className="history">
      <div className="top">
        <p className="overallHistory">Overall History</p>
        <Search onChange={(e) => setSearch(e.target.value)} />
      </div>

      <table>
        <tr>
          <th style={{ padding: "1%" }}>S.No</th>
          <th>Date</th>
          <th>Name</th>
          {/* <th>Contact Number</th> */}
          <th>Package</th>
          <th>Amount</th>
          <th>Report</th>
        </tr>
        {!loading && historyData?.length > 0 ? (
          historyData.map((item, i) => (
            <React.Fragment key={i}>
              {item.map((d, t) => (
                <tr key={t}>
                  <td>{serialNumber++}</td>
                  <td>{d?.date}</td>
                  <td>{d?.patientName}</td>
                  <td className="two-line-clamp-unset" style={{ width: "14%" }}>
                    {d?.testName}
                  </td>
                  <td>
                    ₱{d?.amount}{" "}
                    {d?.paymentStatus === "Unpaid" ? (
                      <span className="unpaid">(Unpaid)</span>
                    ) : (
                      <span className="paid">(Paid)</span>
                    )}
                  </td>
                  <td>
                    <a
                      href="https://patient-in.creliohealth.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#5E5E5E",
                      }}
                    >
                      Report
                      <img
                        className="downloadsvg"
                        src={download}
                        alt="download"
                      />
                    </a>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))
        ) : loading ? (
          <div className="spin-wrapper">
            <Spinner />
          </div>
        ) : (
          <div className="no-data-found">No Data</div>
        )}
      </table>
    </div>
  );
};

export default History;
