import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { resetAlert } from "../../Redux/Actions/alert.action";

function AlertBox({ show, setShow }) {
  const dispatch = useDispatch();
  const { type, message } = useSelector((state) => state.alertReducer);

  return type ? (
    <Row
      style={{
        position: "fixed",
        bottom: "3%",
        left: "1%",
        width: "400px",
        zIndex: "1056",
      }}
    >
      <Col xs={6} style={{ width: "100%" }}>
        <Toast
          onClose={() => {
            dispatch(resetAlert());
          }}
          show={show}
          delay={4000}
          autohide
        >
          <Toast.Body
            style={{
              position: "relative",
              color: "white",
              borderRadius: "5px",
              background:
                type === "success"
                  ? "green"
                  : type === "danger"
                  ? "red"
                  : "orange",
            }}
          >
            {typeof message === "string" ? message : null}
            <span
              style={{
                position: "absolute",
                top: "5%",
                right: "2.5%",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(resetAlert());
              }}
            >
              ✘
            </span>
          </Toast.Body>
        </Toast>
      </Col>
    </Row>
  ) : null;
}

export default AlertBox;
