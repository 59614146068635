import React, { useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import FeedbackUser from "Assests/Images/Feedback1.svg";
import "./TestimonialSlider.css";
import { useState } from "react";

const TestimonialSlider = () => {
  const [screenWidth, setScreenWidth] = useState(1920);

  // window resize logic
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.screen.width);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1050, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CardItem = ({ image, userFeedback }) => {
    return (
      <div className="card-item">
        <div className="icons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="40"
            viewBox="0 0 22 40"
            fill="none"
          >
            <path d="M0 0H22V24L0 40V0Z" fill="#E3E6EC" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="40"
            viewBox="0 0 22 40"
            fill="none"
          >
            <path d="M0 0H22V24L0 40V0Z" fill="#E3E6EC" />
          </svg>
        </div>
        <p>{userFeedback}</p>

        <div className="info">
          <div style={{ alignItems: "center", margin: "unset" }}>
            <img src={image} alt="Feedback" />
            <p></p>
          </div>

          <div>
            <span className="heading">User Name</span>
            <span>Designation of user</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="testimonial-slider"
      onResize={() => {
        console.log("trigger");
        console.log(window.screen.width);
      }}
    >
      <Carousel
        responsive={responsive}
        infinite={true}
        centerMode={screenWidth < 1400 ? false : true}
        showDots={true}
      >
        <div>
          <CardItem
            image={FeedbackUser}
            userFeedback="At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice.
                At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice. At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice."
          />
        </div>

        <div>
          <CardItem
            image={FeedbackUser}
            userFeedback="At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice.
                At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice. At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice."
          />
        </div>

        <div>
          <CardItem
            image={FeedbackUser}
            userFeedback="At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice.
                At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice. At PhiSanté, we offer hassle-free health testing with two convenient options: At-Home Testing, where we come to you, or In-Lab Visits, where you visit us. Both options ensure accurate results and excellent service. Your health, your choice."
          />
        </div>
      </Carousel>
    </div>
  );
};

export default TestimonialSlider;
