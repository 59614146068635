import React, { useEffect, useState } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import blogWallpaper from "Assests/Images/man-work 1.png";
import blogPic from "Assests/Images/Rectangle 116.png";
import blogPic1 from "Assests/Images/side-view-smiley-doctor-patient 1.png";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import dot3 from "Assests/Images/dot3.svg";
import "./blog.css";
import { Card, Form, InputGroup, Nav } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "Assests/Icons/searchIcon.svg";
import { ReactComponent as CartIcon } from "Assests/Icons/cartIcon.svg";
import RightArrow from "Assests/Icons/RightArrow.svg";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import blogWanner from "Assests/Images/blogWanner.png";
import Wanner from "Components/Wnner/Wanner";
import httpPathName from "Global/Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../Redux/Actions/alert.action";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import Search from "Components/Common/Search/Search";
// import useMetaTags from "Components/Hooks/useMetaTags";

const Blog = () => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    setLoading(true);
    let apiUrl = "blog/getAllBlogs";

    if (search && category) {
      apiUrl += `?searchKeyword=${search}&category=${category}`;
    } else if (search) {
      apiUrl += `?searchKeyword=${search}`;
    } else if (category) {
      apiUrl += `?category=${category}`;
    }
    const apiStatus = await httpPathName
      .get(apiUrl)
      .then((response) => {
        console.log("response", response);
        setData(response?.data?.data);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetch Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategory = async () => {
    const apiStatus = await httpPathName
      .get(`/admin/test/fetchAllOrgans`)
      .then((response) => {
        console.log("category list", response);
        setCategoryList(response?.data?.data);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      });
  };

  useEffect(() => {
    getData();
  }, [search, category]);

  useEffect(() => {
    getCategory();
  }, []);

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Stay informed with Nederlands Diagnostics' Blog on Health Insights & Updates","Visit Nederlands Diagnostics' blog page for informative articles, blogs, health insights, and updates on diagnostic testing, healthcare trends, and wellness tips.")

  const navigate = useNavigate();
  const CardItem = ({
    img,
    id,
    alt,
    title,
    short_desc,
    include,
    parameter,
    long_desc,
    author,
    category,
    key,
  }) => {
    return (
      <div onClick={() => navigate(`/blog/${id}`)} className="card-item">
        <img src={img} alt={alt} />
        <div className="main-content">
          <h6>{title || "No Title"}</h6>
          <p>{short_desc || "No Description"}</p>
          <p>
            <strong>{`Category : ${category}`}</strong>
          </p>
        </div>

        <div className="oneLine">
          <p>Creted by {author}</p>
          {/* <p>Category 1</p>
          <p>Category 2</p>
          <p>Category 3</p> */}
        </div>
        {/* <button>View More</button>  */}
      </div>
    );
  };

  return (
    <>
      <MetaDetails
        title="Stay informed with Nederlands Diagnostics' Blog on Health Insights & Updates"
        description="Visit Nederlands Diagnostics' blog page for informative articles, blogs, health insights, and updates on diagnostic testing, healthcare trends, and wellness tips."
      />

      <Header />
      <div className="blog">
        <Wanner title={"Blog"} image={blogWanner} />
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-1 mb-5 searchSection">
          <Search
            placeholder="Search Blogs"
            onChange={(e) => setSearch(e.target.value)}
          />

          <select onChange={(e) => setCategory(e.target.value)}>
            <option value="" hidden>
              Select Categories
            </option>
            {categoryList?.length > 0 &&
              categoryList.map((item, i) => (
                <option key={i} value={item?.testName}>
                  {item?.testName}
                </option>
              ))}
          </select>
        </div>
        <div className="blogItem">
          {loading ? (
            <div className="spin-wrapper">
              <Spinner />
            </div>
          ) : !loading && !data?.length ? (
            <div>No Data Found</div>
          ) : (
            <div className="cardWrapper">
              {data?.length > 0 &&
                data?.map((item, i) => (
                  <CardItem
                    key={i}
                    img={item?.blog_image}
                    alt={item?.blog_title}
                    title={item?.blog_title}
                    short_desc={item?.description}
                    author={item?.author}
                    category={item?.category}
                    include={7}
                    parameter={76}
                    id={item?._id}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
