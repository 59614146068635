import React, { useEffect, useState } from "react";
import "./Seafarer.css";
import diagnostics from "Assests/Images/diagnostics.png";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import Wanner from "Components/Wnner/Wanner";
import { fetchServiceContent } from "API/get.api";

const Seafarer = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
    fetchContent();
  }, []);

  async function fetchContent() {
    try {
      const res = await fetchServiceContent();
      setContent(res?.data?.serviceContent?.seafarers);
    } catch (e) {
      console.log("err", e);
    }
  }

  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("Complete guidelines on the medical examinations of seafarers.","Choose Nederlands Diagnostics for getting assistance regarding medical examinations for seafarers. Visit our website for more info.")

  function section1() {
    return (
      <>
        <div className="section1-Wrapper">
          <p>{content?.description}</p>
        </div>
      </>
    );
  }

  function section2() {
    return (
      <>
        <div className="section2-Wrapper">
          <h1>Our state-of-the-art diagnostic services offers:</h1>
          <div className="custom-container-Wrapper">
            {content?.services_offered?.length > 0 &&
              content?.services_offered?.map((item, i) => (
                <div key={i} className="custom-container">
                  <div className="oneLine">
                    <div className="img-wrapper">
                    <img src={item?.image} alt={item?.heading} />
                      </div>
                   
                    <div>
                      <p>{item?.heading || ""}</p>
                      <span>{item?.sub_heading || ""}</span>
                    </div>
                  </div>
                  <p>{item?.description || ""}</p>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <MetaDetails
        title="Complete guidelines on the medical examinations of seafarers."
        description="Choose Nederlands Diagnostics for getting assistance regarding medical examinations for seafarers. Visit our website for more info."
      />

      <Header />
      <div className="seafarer">
        <Wanner title="Seafarers" image={diagnostics} dot={false} />

        <div className="section1">{section1()}</div>
        <div className="section2">{section2()}</div>
      </div>

      <Footer />
    </>
  );
};

export default Seafarer;
