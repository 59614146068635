import React, { useEffect } from "react";
import "./Faq.css";
import partnerWithUs from "Assests/Images/closeup-ampoule-needle-syringe-document-with-phonendoscope 1.png";
import dot3 from "Assests/Images/dot3.svg";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import Accordion from "react-bootstrap/Accordion";
// import useMetaTags from "Components/Hooks/useMetaTags";
import MetaDetails from "Components/MetaDetails/MetaDetails";
import clientWanner from "Assests/Images/clientWanner.png";
import Wanner from "Components/Wnner/Wanner";

const homeService = [
  {
    question:
      "How to book for a Home Service appointment? When is the earliest schedule?",
    ans: (
      <div>
        You may reach us through the following numbers XXXXXXXXX. Press “X” and
        our home service agent will assist you. You may also download our
        Nederlands App for free and click “Schedule my home service
        appointment.” However, this access is only available to patients who can
        log in to the app.
        <br />
        You can also fill our Home Collection Form available at ND Website with
        all necessary details and our Home Service agent will call you for final
        booking and confirmation.
      </div>
    ),
  },
  {
    question:
      "Can we directly call the branch nearest to our house to schedule for a home service appointment?",
    ans: "Yes. On our website, click on Find our Lab and click on the chosen branch to view their contact information and location ",
  },
  {
    question: "How many patients can you accommodate for Home Service?",
    ans: "We can accommodate a maximum of 50 patients per household/location. ",
  },
  {
    question:
      " Do you have doctors who can do house calls and perform Intravenous (IV) procedures?",
    ans: "Sorry, this service is currently not available.",
  },
];

const branchOperation = [
  {
    question: "What are ND's hours of operation?",
    ans: "Operating Hours can be found under the Branch Details for each location under Find our Lab page. ",
  },
  {
    question: "How do I get your branch information?",
    ans: "On our website, go to Find our Lab page. You may also download our ND App for free (Available on IOS/Android), then click “Nearest Lab.” ",
  },
  {
    question: "How do I know the schedule of your doctors?",
    ans: "On our website, go to the Find a Doctor page or download our ND app, then click “Doctor’s Clinic” to check the schedule of doctors at the branch nearest you. However, the doctor’s schedule may change without prior notice, so please call the branch for confirmation. ",
  },
  {
    question: "How far can you go for Home Service?",
    ans: "Currently we are serving all areas of Metro Manila and areas near our branches. Visit the Find our Lab page to determine the nearest branch for you and setup an appointment via Home Service Appointment page. ",
  },
  {
    question: "How do I schedule an RT-PCR Swab test?",
    ans: "You may visit the Find our Lab page to determine the nearest branch and call, or send a message via Viber/SMS to the assigned number of the branch for RT-PCR Swab testing. ",
  },
  {
    question: "Are your clinics accredited with PhilHealth?",
    ans: "Sorry, our facility is currently not PhilHealth accredited. ",
  },
  {
    question:
      "How to book for a Home Service appointment? When is the earliest schedule?",
    ans: "You can request for an appointment online by completing the form under the Book Home Sample Collection page on the website. ",
  },
];

const diagnosticTesting = [
  {
    question: "How do I interpret my report?",
    ans: "You are advised to visit your referring doctor with the report for the ﬁnal interpretation. ",
  },
  {
    question: "How do I access my report?",
    ans: "Once we register your sample in our system, you will receive a login ID and password through SMS. You can access your report by visiting our website www.PhiSantédiagnostics.com and login with this UPID and password. In case you find any difficulty in retrieving your report, you may reach out to us at XXXXXXXXX. ",
  },
  {
    question:
      "How long does testing typically take before results are available?",
    ans: "Many tests are completed within 4 hours. Paps, biopsies, and cultures take longer because of the manual nature of the procedures. Additionally, some specialty tests take longer and your physician can tell you if your tests fall into any of these categories. ",
  },
  {
    question:
      "My doctor ordered some blood tests. Can I eat or drink before the test?",
    ans: "This is dependent upon the type of blood tests ordered. Your physician should tell you if the test(s) require fasting (no eating, drinking, gum chewing, etc.). If you are unsure, please contact your physician or ask our laboratory personnel. ",
  },
  {
    question: "What happens to my sample once I have provided it?",
    ans: "Your sample will be labeled with your name, date of birth, and one other unique identifier. It will be transported to a laboratory via a courier. Samples are scrutinized for correct patient identification, sample type and volume, and then prepared for testing in the appropriate laboratory department by technicians and/or technologists with specific training for that test type. Results are transmitted electronically to physician and patient portals upon completion. ",
  },
  {
    question:
      "I lost my receipt and I forgot to take note of my UPID, what should I do?",
    ans: "You may contact any of the Our Branch near you or you may send us an email through our website and we will assist you accordingly. ",
  },
  {
    question: "Can I take my medications before I take my blood tests?",
    ans: "Please take your medication unless your doctor gives you specific instructions not to do so. ",
  },
  {
    question: "Can I check my imaging test images and results online? ",
    ans: "All readings of imaging results will be uploaded online, but only the image of X-Ray tests can be seen online as of the moment. The images of other imaging tests such as ECG, and Ultrasound must be picked up at the branch. ",
  },
  {
    question: "What Complete Blood Tests are available at your branches? ",
    ans: "We have a wide range of laboratory packages that you can choose from for your regular checkup.  On our website, type in the search bar required test and book as per the slot availability or incase you are not sure about the tests Upload Your Prescription at the website and our Customer Service Agent will get in touch with you for assisting the prescribed diagnostic tests by your clinician. ",
  },
  {
    question: "What Laboratory Packages are available? ",
    ans: "Visit the Explore all test under patient section on our website to view all the individual tests and packages available. Note that availability would also depend on the branches. ",
  },
  {
    question:
      "How to book for a Home Service appointment? When is the earliest schedule? ",
    ans: "You can request for an appointment online by completing the form under the Home Sample Collection Page on the website. You may also reach us through the following numbers XXXXXXXXXXX. Press “X” and our home service agent will assist you. You may also download our ND App for free and click “Schedule my home service appointment.” However, this access is only available to patients who can log in to the app. ",
  },
  {
    question:
      "Will we be billed if the participant cancels or the phlebotomist is unable to obtain blood flow? ",
    ans: "There will be no charge if an appointment has to be cancelled/reschedule. Unless the phlebotomist is able to obtain blood flow, there will be no charge. ",
  },
  {
    question: "Are there any Home Collection Charges? ",
    ans: "Home collection charges may or may not be applicable as per the running offers and depending on the areas, for best and updated information kindly call us at XXXXXXXXX ",
  },
  {
    question: "Do you offer Computerized Tomography (CT) scan services? ",
    ans: "We apologize but CT is currently not available at Nederlands Diagnostics. ",
  },
  {
    question: "Do you offer Magnetic Resonance Imaging (MRI) services? ",
    ans: "We apologize but MRI is currently not available at Nederlands Diagnostics. ",
  },
  {
    question:
      "Can we use our Health Maintenance Organization (HMO) card for the services? ",
    ans: "For walk-ins, most of our branches are HMO accredited. On our website, go to the HMO Approval page to have your request approved prior to visiting the branch. For home sample collection, please call our customer care number at XXXXXXXX, and press “X” to know which among the accredited HMOs can be served. ",
  },
  {
    question: "How Can we provide feedback to Nederlands Diagnostics? ",
    ans: (
      <div>
        You can provide feedback to Nederlands Diagnostics with any of the below
        mentioned options - <br />
        1) Reach out to our Customer Care Team at our Toll Free Number -
        XXXXXXXX <br />
        2) Each our branches are equipped with suggestion boxes and Forms. You
        can visit these branches and fill-in the feedback form and drop in the
        Suggestion box. <br />
        3) Our Website also have a dedicated link for your valuable Feedback{" "}
        <br />
        4) You can even drop us an email with your detailed feedback at our
        email id – connect@PhiSantédiagnostics.com{" "}
      </div>
    ),
  },
];
const Faq = () => {
  // Using Custom Hook for adding Meta Title and Meta Description for Page
  // useMetaTags("FAQ's","Get answers to your queries about Nederlands Diagnostics' services, appointments, billing, and more. Get detailed information you need to make informed decisions about your healthcare.")
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when component mounts
  }, []);
  
  return (
    <>
      <MetaDetails
        title="FAQ's"
        description="Get answers to your queries about Nederlands Diagnostics' services, appointments, billing, and more. Get detailed information you need to make informed decisions about your healthcare."
      />

      <Header />
      <div className="faq">
        <Wanner title="FAQs" image={clientWanner} />
        <div className="section1">
          <h1>
            Frequently{" "}
            <span style={{ fontWeight: "500" }}>Asked Questions</span>
          </h1>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="8"
            viewBox="0 0 50 8"
            fill="none"
          >
            <rect width="8" height="8" rx="4" fill="#F99D1C" />
            <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
            <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
          </svg> */}
          <p>Home Service</p>
          <Accordion defaultActiveKey="0">
            {homeService.map((item, i) => (
              <Accordion.Item key={i} eventKey={i}>
                <Accordion.Header>{item?.question}</Accordion.Header>
                <Accordion.Body>{item?.ans}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <p>Branch Operation</p>
          <Accordion defaultActiveKey="0">
            {branchOperation.map((item, i) => (
              <Accordion.Item key={i} eventKey={i}>
                <Accordion.Header>{item?.question}</Accordion.Header>
                <Accordion.Body>{item?.ans}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <p>Diagnostics & Testing</p>
          <Accordion defaultActiveKey="0">
            {diagnosticTesting.map((item, i) => (
              <Accordion.Item key={i} eventKey={i}>
                <Accordion.Header>{item?.question}</Accordion.Header>
                <Accordion.Body>{item?.ans}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
