import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import "./ReadBlogsSlider.css";
import BlogImage from "Assests/Images/BlogImage.png";
import { useDispatch } from "react-redux";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { useNavigate } from "react-router-dom";

const ReadBlogsSlider = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await httpPathName.get("blog/getAllBlogs");
        if (response.status === 200) {
          setData(response.data.data);
        } else {
          dispatch(setAlert("error", "Failed to fetch data"));
        }
      } catch (err) {
        console.error("Fetch Error:", err);
        dispatch(setAlert("error", "Fetch Error"));
      }
    };
    getData();
  }, [dispatch]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 1,
    },
    medDesktop1: {
      breakpoint: { max: 1750, min: 1550 },
      items: 1,
      partialVisibilityGutter: 700,
    },
    medDesktop2: {
      breakpoint: { max: 1550, min: 1350 },
      items: 1,
      partialVisibilityGutter: 500,
    },
    laptop: {
      breakpoint: { max: 1350, min: 1150 },
      items: 1,
      partialVisibilityGutter: 300,
    },
    tablet: {
      breakpoint: { max: 1150, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CardItem = ({ img, alt, title, short_desc }) => {
    return (
      <div className="card-item">
        <img className="img-fluid" src={img} alt={alt} />
        <div className="image-overlay">
          <p className="heading">{title}</p>
          <p className="description">{short_desc}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="carousel-container-blog">
      <Carousel containerClass="read-blogs-slider" responsive={responsive}>
        {data.length > 0 &&
          data?.map((item, i) => (
            <div onClick={() => navigate(`/blog/${item?._id}`)} key={i}>
              <CardItem
                img={item?.blog_image || BlogImage}
                alt={item?.blog_title}
                title={item?.blog_title}
                short_desc={item?.description}
              />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default ReadBlogsSlider;
