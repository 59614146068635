import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Delete.css";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import { setRelData } from "../../../Redux/Actions/set.action";

const Delete = ({ show, setShow, id }) => {
  const token = localStorage.getItem("access_token");

  const dispatch = useDispatch();

  const getRelationshipData = async () => {
    const apiStatus = await httpPathName
      .get("users/relationships", { headers: { "x-auth-token": token } })
      .then((response) => {
        dispatch(setRelData(response?.data?.relationship));
        //setData(response?.data?.relationship)
        // dispatch(setAlert("success", "Order Data Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  };

  const deleteRelation = async () => {
    const apiStatus = await httpPathName
      .delete(`users/remove-relation/${id}`, {
        headers: { "x-auth-token": token },
      })
      .then((response) => {
        dispatch(setAlert("success", response?.data?.message));
        setShow(false);
        getRelationshipData();
      })
      .catch((err) => {
        console.log(err);
        setShow(false);
        dispatch(setAlert("error", err?.response?.data?.message));
      });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      className="delete-list"
    >
      <Modal.Header closeButton>
        <Modal.Title>Do You Want To Delete ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delWrapper">
          <button onClick={() => setShow(false)} className="no">
            No
          </button>
          <button onClick={deleteRelation} className="yes">
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Delete;
