import React from "react";
import "./HomeModel.css";
import Modal from "react-bootstrap/Modal";
import { setHomePageModel } from "../../../Redux/Reducers/model";
import { setHomeModel } from "../../../Redux/Actions/model";
import { useSelector, useDispatch } from "react-redux";
import homeModelImage from "Assests/Images/homeModel.png";

const HomeModel = () => {
  const dispatch = useDispatch();
  const homeModel = useSelector((state) => state.setHomePageModel);

  return (
    <Modal
      show={homeModel}
      onHide={() => {
        sessionStorage.setItem("first_time", "2");
        dispatch(setHomeModel(false));
      }}
      centered
      className="homeModel"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Do You Want To Delete ?</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="homeModelWrapper">
          <img src={homeModelImage} alt="homemodel" />
          <h1>Welcome to</h1>
          <h1 style={{ color: "#A71F23" }}>Nederlands Diagnostics!</h1>
          <p>World Class Diagnostics at Affordable Price.</p>
          <p>Agile | Affordable | Accurate | Accessible</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HomeModel;
