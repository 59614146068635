import React, { useState, useEffect } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
// import blogWallpaper from "Assests/Images/man-work 1.png";
import dot3 from "Assests/Images/dot3.svg";
import leftArrow from "Assests/Icons/arrow-narrow-left.svg";
import { useNavigate } from "react-router-dom";
import blogDetailImage from "Assests/Images/close-up-researcher-working-with-microscope 1.png";
import rightArrow from "Assests/Icons/right-arrow-7288805.svg";
import { useParams } from "react-router";
import httpPathName from "Global/Config/Config";
import { setAlert } from "../../Redux/Actions/alert.action";
import { useDispatch } from "react-redux";
import blogWanner from "Assests/Images/blogWanner.png";
import Wanner from "Components/Wnner/Wanner";
import Spinner from "react-bootstrap/Spinner";
import { submitFeedback } from "API/post.api";
import PrevNextBtn from "Components/Common/Button/PrevNextBtn";

const BlogDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState(false);
  const [data, setData] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    description: "",
    name: "",
    emailid: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const getData = async () => {
    setLoading(true);
    const apiStatus = await httpPathName
      .get(`blog/getABlog/${id}`)
      .then((response) => {
        console.log("blog deatils", response);
        setData(response?.data?.data);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetched Error"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllBlogData = async () => {
    let apiUrl = "blog/getAllBlogs";
    const apiStatus = await httpPathName
      .get(apiUrl)
      .then((response) => {
        console.log("response", response);
        setBlogs(response?.data?.data);
        // dispatch(setAlert("success", "History Fetched Successfully"));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAlert("error", "Fetch Error"));
      });
  };

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    getAllBlogData();
  }, [blogs.length]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const api = submitFeedback(id, formData);
    api
      .then((res) => {
        console.log("resss", res);
        dispatch(setAlert("success", res?.data?.message));
        setStatus(true);
      })
      .catch((e) => {
        console.log("err", e);
        dispatch(setAlert("error", e?.response?.data?.message));
      });
  };

  const handlePrevious = () => {
    const currentIndex = blogs.findIndex((blog) => blog._id === id);
    if (currentIndex > 0) {
      const previousBlogId = blogs[currentIndex - 1]._id;
      navigate(`/blog/${previousBlogId}`);
    }
  };

  const handleNext = () => {
    const currentIndex = blogs.findIndex((blog) => blog._id === id);
    if (currentIndex < blogs.length - 1) {
      const nextBlogId = blogs[currentIndex + 1]._id;
      navigate(`/blog/${nextBlogId}`);
    }
  };

  return (
    <>
      <Header />
      <div className="blogDetails">
        <Wanner title={"Blog"} image={blogWanner} />
        <div className="blogDetailItem">
          <div className="left-section">
            <button onClick={() => navigate(-1)} className="backbtn">
              <img className="leftArrow" src={leftArrow} alt="leftarrow" />
            </button>
            <h1>{data?.blog_title}</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="8"
              viewBox="0 0 50 8"
              fill="none"
            >
              <rect width="8" height="8" rx="4" fill="#2A364E" />
              <rect x="21" width="8" height="8" rx="4" fill="#F1BA6A" />
              <rect x="42" width="8" height="8" rx="4" fill="#3AA1ED" />
            </svg>
            <img
              className="blogDetailImage"
              src={data?.blog_image || blogDetailImage}
              alt="blogDetailImage"
            />
            <div className="content">
              <h1>{data?.category}</h1>
              <p>{data?.description}</p>
            </div>
            <div className="nextbtnWrapper">
              <PrevNextBtn
                title={"Previous"}
                onClick={handlePrevious}
                disabled={blogs.findIndex((b) => b._id === id) === 0}
              />

              <PrevNextBtn
                title={"Next"}
                onClick={handleNext}
                disabled={
                  blogs.findIndex((b) => b._id === id) === blogs.length - 1
                }
              />
            </div>
            {!status ? (
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <h1>Leave a Comment</h1>
                <textarea
                  name="description"
                  required
                  onChange={(e) => handleChange(e)}
                  rows={6}
                />
                <div className="onelIne">
                  <div>
                    <label htmlFor="mobile">Name</label>

                    <div className="inputWrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                          stroke="#2A364E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z"
                          stroke="#2A364E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <input
                        name="name"
                        type="text"
                        required
                        placeholder="Enter Name"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="mobile">Email Id</label>

                    <div className="inputWrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M3.02878 8C3.02878 8 8.33702 11.3619 11 12C11.4596 12.1101 12.5404 12.1101 13 12C15.7647 11.3375 20.9712 8 20.9712 8M7.8 19H16.2C17.8802 19 18.7202 19 19.362 18.673C19.9265 18.3854 20.3854 17.9265 20.673 17.362C21 16.7202 21 15.8802 21 14.2V9.8C21 8.11984 21 7.27976 20.673 6.63803C20.3854 6.07354 19.9265 5.6146 19.362 5.32698C18.7202 5 17.8802 5 16.2 5H7.8C6.11984 5 5.27976 5 4.63803 5.32698C4.07354 5.6146 3.6146 6.07354 3.32698 6.63803C3 7.27976 3 8.11984 3 9.8V14.2C3 15.8802 3 16.7202 3.32698 17.362C3.6146 17.9265 4.07354 18.3854 4.63803 18.673C5.27976 19 6.11984 19 7.8 19Z"
                          stroke="#2A364E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <input
                        id="email"
                        name="emailid"
                        type="email"
                        required
                        placeholder="Email"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label></label>

                    <div>
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <p className="thankyou">
                  Thank you for submitting your feedback!
                </p>
              </div>
            )}
          </div>

          {/* <div className="right-section">
            <div className="card">
              <h1>Categories</h1>
              <p style={{ color: "#A71F23" }}>Diagnostics Machine Blog1</p>
              <p>Diagnostics Machine Blog1</p>
              <p>Diagnostics Machine Blog1</p>
              <p>Diagnostics Machine Blog1</p>
            </div>

            <div className="card">
              <h1>Top More Blogs</h1>
              <p>Diagnostics Machine Blog1</p>
              <p>Disclaimer of Content</p>
              <p>Right to Change Terms and Conditions</p>
              <p>Applicable Law</p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
