import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "Assests/Icons/WebsiteLogo.png";
import "./Header.css";
import AuthenticationFlows from "Components/Login/AuthenticationFlows";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCartDataAction, signout } from "../../Redux/Actions/set.action";
import {
  setAuthenticationModel,
  setContactModel,
} from "../../Redux/Actions/model";
import { toBeRequired } from "@testing-library/jest-dom/matchers";
import { setProfileActiveTab } from "../../Redux/Actions/profile";
import { useLocation } from "react-router-dom";
import ND from "Assests/Images/ND-small.webp";
import { setAlert } from "../../Redux/Actions/alert.action";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTab = "header" + window.location.pathname;
  //const [loginModal, setLoginModal] = useState(false);
  const authToken = localStorage.getItem("access_token");
  const authModel = useSelector((state) => state?.setAuthModel);
  const contactModel = useSelector((state) => state?.setContactUsModel);
  const user = useSelector((state) => state?.setUserDataReducer);

  const testList = useSelector((state) => state.setCartDataReducer?.tests);
  const home = testList?.home ? testList?.home : [];
  const lab = testList?.lab ? testList?.lab : [];

  const location = useLocation();

  const scrollToSection = () => {
    const targetSection = document.getElementById("contactSection");

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="header">
        <div>
          <div className="logo-wrapper" onClick={() => navigate("/")}>
            {/* <img src={Logo} alt="logo" /> */}
            <img src={ND} alt="logo" />
          </div>

          <div
            className="show-mobile"
            onClick={() => {
              navigate("/cart");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              style={{ cursor: "pointer" }}
            >
              <path
                d="M8 21.75C8.9665 21.75 9.75 20.9665 9.75 20C9.75 19.0335 8.9665 18.25 8 18.25C7.0335 18.25 6.25 19.0335 6.25 20C6.25 20.9665 7.0335 21.75 8 21.75Z"
                fill="#2A364E"
              />
              <path
                d="M16 21.75C16.9665 21.75 17.75 20.9665 17.75 20C17.75 19.0335 16.9665 18.25 16 18.25C15.0335 18.25 14.25 19.0335 14.25 20C14.25 20.9665 15.0335 21.75 16 21.75Z"
                fill="#2A364E"
              />
              <path
                d="M19.17 5.84999C18.84 5.46999 18.36 5.24999 17.85 5.24999H6C6 5.24999 5.95 5.25999 5.93 5.25999L5.89 4.77999C5.78 3.35999 4.58 2.23999 3.15 2.23999H3C2.59 2.23999 2.25 2.57999 2.25 2.98999C2.25 3.39999 2.59 3.73999 3 3.73999H3.15C3.8 3.73999 4.35 4.24999 4.4 4.88999L5.05 13.27C5.2 15.21 6.84 16.73 8.79 16.73H18.01C18.42 16.73 18.76 16.39 18.76 15.98C18.76 15.57 18.42 15.23 18.01 15.23H8.78C7.81 15.23 6.98 14.6 6.67 13.73H15.4C17.25 13.73 18.85 12.35 19.11 10.51L19.58 7.22999C19.65 6.72999 19.5 6.21999 19.17 5.83999V5.84999ZM17.63 10.31C17.47 11.41 16.51 12.24 15.4 12.24H6.46L6.04 6.73999H17.85C17.95 6.73999 18.01 6.78999 18.04 6.82999C18.07 6.85999 18.11 6.92999 18.1 7.02999L17.63 10.31Z"
                fill="#2A364E"
              />
            </svg>
            {(home.length > 0 || lab.length > 0) && (
              <span style={{ padding: "10px" }}>
                {`${(home?.length || 0) + (lab?.length || 0)}`}
              </span>
            )}
          </div>

          <div className="list hide-mobile">
            <ul>
              <li
                className={(selectedTab === "header/" && "active").toString()}
                onClick={() => navigate("/")}
              >
                Home
              </li>
              <li
                className={(
                  selectedTab.includes("header/about-us") && "active"
                ).toString()}
                onClick={() => navigate("/about-us")}
              >
                About Us
              </li>

              <li
                className={(
                  selectedTab.includes("header/services") && "active"
                ).toString()}
                //onClick={() => navigate("/services")}
              >
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-services">
                    Services
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/all-test-package");
                      }}
                    >
                      All Tests & packages
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/services/diagnostics")}
                    >
                      Diagnostics
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/services/consultation")}
                    >
                      Consultation
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/radiology");
                      }}
                    >
                      Radiology
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/wellness-packages");
                      }}
                    >
                      Wellness Packages
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/seafarer");
                      }}
                    >
                      Seafarers
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/labhome");
                      }}
                    >
                      Lab @ Home
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {/*
              
              <li
                className={(
                  selectedTab.includes("header/doctor") && "active"
                ).toString()}
              onClick={() => navigate("/doctor")}
              >
                Doctor
                 <Dropdown>
                  <Dropdown.Toggle id="dropdown-doctor">Doctor</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">NephroNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">TbNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">GastroNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">GynaeNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">OncoNed</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> 
              </li>
              */}
              <li
                className={(
                  selectedTab.includes("header/corporate") && "active"
                ).toString()}
                onClick={() => navigate("/corporate")}
              >
                Corporate
                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-doctor">
                    Corporate
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/corporate");
                      }}
                    >
                      Corporate
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/employment");
                      }}
                    >
                      Annual Physical Examination
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/employment");
                      }}
                    >
                      Wellness/Health Packages
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/employment");
                      }}
                    >
                      Pre-Employment Medical Examination
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </li>
              <li
                className={(
                  selectedTab.includes("header/HMOs") && "active"
                ).toString()}
                // onClick={() => navigate("/HMOs/step1")}
              >
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-doctor">HMOs</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item href="#/action-1">
                      List of Accredited HMOs
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => navigate("/HMOs/make-an-appointment")}
                    >
                      Online HMO Approval
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li
                className={(
                  selectedTab.includes("header/partner-with-us") && "active"
                ).toString()}
                onClick={() => navigate("/partner-with-us")}
              >
                Partner with us
                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-doctor">
                    Partner with us
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/partner-with-us")}>
                      Collection Center
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/partner-with-us")}>
                      Hospital Lab Management
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/partner-with-us")}>
                      Organise Camps
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </li>
              <li
                className={(
                  selectedTab.includes("header/career") && "active"
                ).toString()}
                onClick={() => navigate("/career")}
              >
                Career
              </li>
            </ul>
          </div>

          <div className="signup hide-mobile">
            {Boolean(authToken) ? (
              <>
                <div
                  onClick={() => {
                    navigate("/cart");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    style={{ cursor: "pointer" }}
                  >
                    <path
                      d="M8 21.75C8.9665 21.75 9.75 20.9665 9.75 20C9.75 19.0335 8.9665 18.25 8 18.25C7.0335 18.25 6.25 19.0335 6.25 20C6.25 20.9665 7.0335 21.75 8 21.75Z"
                      fill="#2A364E"
                    />
                    <path
                      d="M16 21.75C16.9665 21.75 17.75 20.9665 17.75 20C17.75 19.0335 16.9665 18.25 16 18.25C15.0335 18.25 14.25 19.0335 14.25 20C14.25 20.9665 15.0335 21.75 16 21.75Z"
                      fill="#2A364E"
                    />
                    <path
                      d="M19.17 5.84999C18.84 5.46999 18.36 5.24999 17.85 5.24999H6C6 5.24999 5.95 5.25999 5.93 5.25999L5.89 4.77999C5.78 3.35999 4.58 2.23999 3.15 2.23999H3C2.59 2.23999 2.25 2.57999 2.25 2.98999C2.25 3.39999 2.59 3.73999 3 3.73999H3.15C3.8 3.73999 4.35 4.24999 4.4 4.88999L5.05 13.27C5.2 15.21 6.84 16.73 8.79 16.73H18.01C18.42 16.73 18.76 16.39 18.76 15.98C18.76 15.57 18.42 15.23 18.01 15.23H8.78C7.81 15.23 6.98 14.6 6.67 13.73H15.4C17.25 13.73 18.85 12.35 19.11 10.51L19.58 7.22999C19.65 6.72999 19.5 6.21999 19.17 5.83999V5.84999ZM17.63 10.31C17.47 11.41 16.51 12.24 15.4 12.24H6.46L6.04 6.73999H17.85C17.95 6.73999 18.01 6.78999 18.04 6.82999C18.07 6.85999 18.11 6.92999 18.1 7.02999L17.63 10.31Z"
                      fill="#2A364E"
                    />
                  </svg>
                  {(home.length > 0 || lab.length > 0) && (
                    <span style={{ padding: "10px" }}>
                      {`${(home?.length || 0) + (lab?.length || 0)}`}
                    </span>
                  )}
                </div>

                <Dropdown className="profile-menu">
                  <Dropdown.Toggle id="dropdown-user">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12.0018 11.75C12.9413 11.75 13.8596 11.4714 14.6408 10.9495C15.4219 10.4275 16.0307 9.6857 16.3902 8.81775C16.7498 7.9498 16.8438 6.99473 16.6605 6.07332C16.4773 5.15191 16.0249 4.30554 15.3606 3.64124C14.6963 2.97695 13.8499 2.52455 12.9285 2.34127C12.0071 2.15799 11.052 2.25206 10.1841 2.61157C9.31612 2.97109 8.57427 3.57991 8.05233 4.36104C7.5304 5.14218 7.25181 6.06054 7.25181 7C7.25181 8.25978 7.75226 9.46796 8.64306 10.3588C9.53385 11.2496 10.742 11.75 12.0018 11.75ZM12.0018 3.75C12.6446 3.75 13.273 3.94061 13.8074 4.29773C14.3419 4.65484 14.7584 5.16242 15.0044 5.75628C15.2504 6.35014 15.3148 7.00361 15.1894 7.63405C15.064 8.26448 14.7544 8.84358 14.2999 9.2981C13.8454 9.75262 13.2663 10.0622 12.6359 10.1876C12.0054 10.313 11.352 10.2486 10.7581 10.0026C10.1642 9.75663 9.65665 9.34006 9.29954 8.8056C8.94242 8.27115 8.75181 7.64279 8.75181 7C8.75445 6.13886 9.09771 5.31374 9.70663 4.70482C10.3156 4.0959 11.1407 3.75264 12.0018 3.75ZM20.0018 14.76C14.855 12.7655 9.14867 12.7655 4.00181 14.76C3.48593 14.9592 3.04302 15.311 2.73215 15.7684C2.42128 16.2257 2.25721 16.767 2.26181 17.32V19C2.26181 19.7276 2.55017 20.4256 3.06373 20.941C3.57729 21.4565 4.2742 21.7474 5.00181 21.75H19.0018C19.7312 21.75 20.4306 21.4603 20.9464 20.9445C21.4621 20.4288 21.7518 19.7293 21.7518 19V17.32C21.7556 16.7658 21.5901 16.2237 21.2774 15.7662C20.9646 15.3087 20.5196 14.9576 20.0018 14.76ZM20.2518 19C20.2518 19.3315 20.1201 19.6495 19.8857 19.8839C19.6513 20.1183 19.3333 20.25 19.0018 20.25H5.00181C4.67029 20.25 4.35235 20.1183 4.11793 19.8839C3.88351 19.6495 3.75181 19.3315 3.75181 19V17.32C3.74949 17.0692 3.82393 16.8236 3.96513 16.6163C4.10633 16.4089 4.30756 16.2497 4.54181 16.16C9.33749 14.28 14.6661 14.28 19.4618 16.16C19.6961 16.2497 19.8973 16.4089 20.0385 16.6163C20.1797 16.8236 20.2541 17.0692 20.2518 17.32V19Z"
                        fill="white"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(signout());
                        dispatch(setAlert("success", "Sign Out Successfully!"));
                      }}
                    >
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <button
                  className="background"
                  //onClick={() => setLoginModal(true)}
                  onClick={() => dispatch(setAuthenticationModel(true))}
                >
                  Login
                </button>
              </>
            )}
          </div>

          <div className="mobile-header show-mobile">
            <Dropdown align={{ lg: "start" }}>
              <Dropdown.Toggle id="dropdown-user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="#2A364E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate("/")}>
                  Home
                </Dropdown.Item>

                <Dropdown.Item onClick={() => navigate("/about-us")}>
                  About Us
                </Dropdown.Item>

                <Dropdown align={{ lg: "start" }}>
                  <Dropdown.Toggle id="dropdown-services">
                    Services
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/all-test-package");
                      }}
                    >
                      All Tests & packages
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/services/diagnostics")}
                    >
                      Diagnostics
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate("/services/consultation")}
                    >
                      Consultation
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/radiology");
                      }}
                    >
                      Radiology
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/wellness-packages");
                      }}
                    >
                      Wellness Packages
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/seafarer");
                      }}
                    >
                      Seafarers
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate("/services/labhome");
                      }}
                    >
                      Lab @ Home
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Dropdown.Item>Doctor</Dropdown.Item> */}

                <Dropdown.Item onClick={() => navigate("/corporate")}>
                  Corporate
                </Dropdown.Item>

                {/* <Dropdown align={{ lg: "start" }}>
                  <Dropdown.Toggle id="dropdown-doctor">Doctor</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">NephroNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">TbNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">GastroNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">GynaeNed</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">OncoNed</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                {/* <Dropdown align={{ lg: "start" }}>
                  <Dropdown.Toggle id="dropdown-doctor">
                    Corporate
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Annual Physical Examination
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Wellness/Health Packages
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Pre-Employment Medical Examination
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                <Dropdown align={{ lg: "start" }}>
                  <Dropdown.Toggle id="dropdown-doctor">HMOs</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item>List of Accredited HMOs</Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => navigate("/HMOs/make-an-appointment")}
                    >
                      Online HMO Approval
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown.Item onClick={() => navigate("/partner-with-us")}>
                  Partner with us
                </Dropdown.Item>

                {/* <Dropdown align={{ lg: "start" }}>
                  <Dropdown.Toggle id="dropdown-doctor">
                    Partner with us
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      Collection Center
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Hospital Lab Management
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Organise Camps
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                <Dropdown.Item onClick={() => navigate("/career")}>
                  Career
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Profile
                </Dropdown.Item>

                {/* <Dropdown.Item onClick={() => navigate("/cart")}>
                  Cart
                </Dropdown.Item> */}
                {Boolean(authToken) ? (
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(signout());
                      dispatch(setAlert("success", "Sign Out Successfully!"));
                    }}
                  >
                    Sign Out
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => dispatch(setAuthenticationModel(true))}
                  >
                    Log In
                  </Dropdown.Item>
                )}

                {/* <Dropdown.Item
                  onClick={() => {
                    dispatch(signout());
                    dispatch(setAlert("success", "Sign Out Successfully!"));
                  }}
                >
                  Sign Out
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="banner-upper">
          <div className="float-section">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.pathname == "/") {
                  scrollToSection();
                } else {
                  dispatch(setContactModel(true));
                }
              }}
            >
              Contact Us
            </a>
            <a
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
              href="https://cms.nederlandsdiagnostics.com/"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
              >
                <path
                  d="M16 4.25009H11.85C11.4349 4.24701 11.0287 4.12967 10.6759 3.91093C10.3231 3.6922 10.0373 3.38052 9.85 3.01009L9.51 2.32009C9.20048 1.69463 8.72128 1.16884 8.12712 0.802802C7.53297 0.436762 6.84784 0.245231 6.15 0.25009H4C3.00544 0.25009 2.05161 0.645178 1.34835 1.34844C0.645088 2.0517 0.25 3.00553 0.25 4.00009V14.0001C0.25 14.9947 0.645088 15.9485 1.34835 16.6517C2.05161 17.355 3.00544 17.7501 4 17.7501H16C16.9946 17.7501 17.9484 17.355 18.6517 16.6517C19.3549 15.9485 19.75 14.9947 19.75 14.0001V8.00009C19.75 7.00553 19.3549 6.0517 18.6517 5.34844C17.9484 4.64518 16.9946 4.25009 16 4.25009ZM4 1.75009H6.15C6.56509 1.75317 6.97132 1.87051 7.32412 2.08925C7.67692 2.30798 7.96266 2.61966 8.15 2.99009L8.49 3.68009C8.61644 3.92149 8.76723 4.14934 8.94 4.36009L8.34 5.00009C8.13073 5.21947 7.87946 5.39449 7.60115 5.51475C7.32284 5.63501 7.02318 5.69804 6.72 5.70009H1.72V4.00009C1.71997 3.70207 1.77915 3.40702 1.89409 3.13207C2.00904 2.85711 2.17746 2.60773 2.38958 2.3984C2.6017 2.18907 2.85329 2.02397 3.12975 1.91267C3.4062 1.80138 3.70201 1.74612 4 1.75009ZM18.25 14.0001C18.25 14.5968 18.0129 15.1691 17.591 15.5911C17.169 16.013 16.5967 16.2501 16 16.2501H4C3.40326 16.2501 2.83097 16.013 2.40901 15.5911C1.98705 15.1691 1.75 14.5968 1.75 14.0001V7.20009H6.75C7.25398 7.18784 7.75048 7.07543 8.21059 6.86941C8.6707 6.66339 9.08522 6.36788 9.43 6.00009L10.12 5.28009C10.6537 5.5604 11.2472 5.7079 11.85 5.71009H16C16.2989 5.71004 16.5948 5.76954 16.8704 5.88511C17.146 6.00067 17.3958 6.16999 17.6053 6.38318C17.8148 6.59636 17.9797 6.84915 18.0903 7.12676C18.201 7.40438 18.2553 7.70127 18.25 8.00009V14.0001Z"
                  fill="#2A364E"
                />
                <path
                  d="M11.4698 11.47L10.7498 12.19V9C10.7498 8.80109 10.6708 8.61032 10.5302 8.46967C10.3895 8.32902 10.1988 8.25 9.99985 8.25C9.80093 8.25 9.61017 8.32902 9.46952 8.46967C9.32887 8.61032 9.24985 8.80109 9.24985 9V12.19L8.52985 11.47C8.38767 11.3375 8.19963 11.2654 8.00532 11.2688C7.81102 11.2723 7.62564 11.351 7.48823 11.4884C7.35081 11.6258 7.2721 11.8112 7.26867 12.0055C7.26524 12.1998 7.33737 12.3878 7.46985 12.53L9.46985 14.53C9.5392 14.5978 9.6206 14.6521 9.70985 14.69C9.80139 14.7296 9.90009 14.7501 9.99985 14.7501C10.0996 14.7501 10.1983 14.7296 10.2898 14.69C10.3791 14.6521 10.4605 14.5978 10.5298 14.53L12.5298 12.53C12.6623 12.3878 12.7345 12.1998 12.731 12.0055C12.7276 11.8112 12.6489 11.6258 12.5115 11.4884C12.3741 11.351 12.1887 11.2723 11.9944 11.2688C11.8001 11.2654 11.612 11.3375 11.4698 11.47Z"
                  fill="#2A364E"
                />
              </svg>
              <span style={{ marginTop: "3px" }}>Download Your Report</span>
            </a>
          </div>
        </div>
      </div>
      {/* {getModals()} */}
    </>
  );
};

export default Header;
