// Step.js
import React from "react";

const Step = ({ stepNumber, title, status }) => {
  const isCompleted = status === "completed";
  const isInProgress = status === "in-progress";
  const isPending = status === "pending";

  return (
    <div
      className={`step ${isCompleted ? "completed" : ""} ${
        isInProgress ? "in-progress" : ""
      } ${isPending ? "pending" : ""}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <circle
          cx="28"
          cy="28"
          r={isInProgress ? "27.5" : "28"}
          fill={
            isPending
              ? "#D9D9D9"
              : isInProgress
              ? "#F1BA6A"
              : isCompleted
              ? "#A71F23"
              : "none"
          }
          stroke={isInProgress ? "#D9D9D9" : "none"}
          strokeWidth="2"
        />
        {isCompleted && (
          <path
            d="M17 28l7.5 7.5L39 20"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
      <p className="stepContent">
        {title}
        <br />
        <small
          className="fw-semibold"
          style={{ color: isInProgress ? "#F1BA6A" : "#D9D9D9" }}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </small>
      </p>
    </div>
  );
};

export default Step;
